import RULES from "@/plugins/rules";
import {FieldType} from "@/forms/fieldTypes";
import {CONSTS} from "@/utils/consts";


const HIDDEN_ID_FIELD = {
  key: 'id',
  type: FieldType.HIDDEN,
}


const _uboFromDirectorMigrationFields = [
  'address', 'authority', 'birth_country', 'citizenship_country', 'dob', 'document_type',
  'reg_address_object',
  'email', 'exp_date', 'full_name', 'id_front_doc', 'id_reverse_doc',
  'passport_doc', 'person_id',
  'person_has_right', 'bank_access', 'phone', 'proofs_of_the_address', 'is_individual_usa', 'is_individual_pep'
]

function _uboFromDirector(director) {
  if (!director) return
  let res = {}
  _uboFromDirectorMigrationFields.forEach(field => {
    let v = director[field]
    res[field] = typeof v === 'object' ? JSON.parse(JSON.stringify(v)) : v
  })
  return res
}

// eslint-disable-next-line
export default function (vm, modelVar = 'input', simplePhone = false, hiddenId = false) {

  const MIN_DATE = new Date(1900, 0, 1)
  const MAX_DATE = new Date(2099, 11, 31)
  const NOW_MINUS_18 = new Date(new Date() - new Date(1988, 0, 1))

  const MINIMUM_OWN_PERCENT = 25.0

  let rules = RULES(vm)

  // let businessAddressFormDisabler = () => vm.$data[modelVar].company_info?.same_as_reg_address
  let businessAddressFormDisabler = (value, allData) => allData?.same_as_reg_address || false

  // let sameListenFunction = (listenVarValue, currentVarKey, allData) => {
  //
  // }
  return [
    {
      key: 'create_account',
    },
    {
      key: 'company_info',
      form: [
        {
          label: vm.$t('fullRegGroups.company_info'),
          key: 'company_info',
          form: [
            // {
            //   key: 'name',
            //   label: vm.$t('fullRegFields.company_name'),
            //   type: FieldType.TEXT,
            //   rules: [
            //     rules.required(),
            //     rules.counterMax(64),
            //   ]
            // },
            ...(hiddenId ? [HIDDEN_ID_FIELD] : []),
            {
              key: 'reg_number',
              label: vm.$t('fullRegFields.reg_number'),
              type: FieldType.TEXT_NUMBER,
              rules: [
                rules.required(),
                rules.engNumSpecialOnly(),
                rules.counterMax(64),
              ]
            },
            {
              key: 'reg_date',
              label: vm.$t('fullRegFields.reg_date'),
              type: FieldType.DATE,
              rules: [
                rules.required(),
                rules.dateMinEqual(MIN_DATE),
                rules.dateMaxEqual(new Date())
              ],
              extra: {
                min: MIN_DATE,
                max: new Date(),
              }
            },
            {
              key: 'vat_number',
              label: vm.$t('fullRegFields.vat_number'),
              type: 'text',
              rules: [
                rules.engNumSpecialOnly(),
                rules.counterMaxEqual(64),
              ],
            },
          ]
        },
        {
          label: vm.$t('fullRegGroups.registered_address'),
          key: 'registered_address',
          form: [
            {
              key: 'reg_address_object',
              label: vm.$t('fullRegGroups.registered_address'),
              type: FieldType.ADDRESS,
              rules: [
                rules.required(),
                rules.isAddressFilled(),
              ]
            },
            // {
            //   key: 'reg_country',
            //   label: vm.$t('fullRegFields.reg_country'),
            //   type: FieldType.TEXT,
            //   rules: [
            //     rules.required(),
            //     rules.counterMax(64),
            //   ]
            // },
            // {
            //   key: 'reg_city',
            //   label: vm.$t('fullRegFields.reg_city'),
            //   type: FieldType.TEXT,
            //   rules: [
            //     rules.required(),
            //     rules.counterMax(64),
            //   ]
            // },
            // {
            //   key: 'reg_post_code',
            //   label: vm.$t('fullRegFields.reg_post_code'),
            //   type: FieldType.TEXT,
            //   rules: [
            //     rules.required(),
            //     rules.engNumSpecialSpaceOnly(),
            //     rules.counterMax(32),
            //   ]
            // },
            // {
            //   key: 'reg_address',
            //   label: vm.$t('fullRegFields.reg_address'),
            //   type: FieldType.TEXT,
            //   rules: [
            //     rules.required(),
            //     rules.counterMax(128),
            //   ]
            // },
          ]
        },
        {
          label: vm.$t('fullRegGroups.business_address'),
          key: 'business_address',
          form: [
            {
              key: 'same_as_reg_address',
              label: vm.$t('fullRegFields.same_as_reg_address'),
              type: FieldType.BOOL,
              default: false,
              rules: []
            },
            {
              key: 'business_address_object',
              disabledFunc: businessAddressFormDisabler,
              label: vm.$t('fullRegFields.business_address'),
              type: FieldType.ADDRESS,
              rules: [
                rules.required(),
                rules.isAddressFilled(),
              ],
              listenFields: {
                same_as_reg_address: (listenVarValue, currentVarKey, allData) => {
                  allData[currentVarKey] = listenVarValue ? allData.reg_address_object : {}
                },
                reg_address_object: (listenVarValue, currentVarKey, allData) => {
                  if (allData.same_as_reg_address) {
                    allData[currentVarKey] = listenVarValue
                  }
                }
              },
            },
            // {
            //   key: 'reg_address_object',
            //   label: vm.$t('fullRegFields.registered_address'),
            //   type: FieldType.ADDRESS,
            //   rules: [
            //     rules.required(),
            //     rules.isAddressFilled(),
            //   ]
            // },
            // {
            //   key: 'business_country',
            //   label: vm.$t('fullRegFields.business_country'),
            //   type: FieldType.TEXT,
            //   disabledFunc: businessAddressFormDisabler,
            //   listenFields: {
            //     same_as_reg_address: (listenVarValue, currentVarKey, allData) => {
            //       allData[currentVarKey] = listenVarValue ? allData.reg_country : undefined
            //     },
            //     reg_country: (listenVarValue, currentVarKey, allData) => {
            //       if (allData.same_as_reg_address) {
            //         allData[currentVarKey] = listenVarValue
            //       }
            //     }
            //   },
            //   rules: [
            //     rules.required(),
            //     rules.counterMax(64),
            //   ]
            // },
            // {
            //   key: 'business_city',
            //   label: vm.$t('fullRegFields.business_city'),
            //   type: FieldType.TEXT,
            //   disabledFunc: businessAddressFormDisabler,
            //   listenFields: {
            //     same_as_reg_address: (listenVarValue, currentVarKey, allData) => {
            //       allData[currentVarKey] = listenVarValue ? allData.reg_city : undefined
            //     },
            //     reg_city: (listenVarValue, currentVarKey, allData) => {
            //       if (allData.same_as_reg_address) {
            //         allData[currentVarKey] = listenVarValue
            //       }
            //     }
            //   },
            //   rules: [
            //     rules.required(),
            //     rules.counterMax(64),
            //   ]
            // },
            // {
            //   key: 'business_post_code',
            //   label: vm.$t('fullRegFields.business_post_code'),
            //   type: FieldType.TEXT,
            //   disabledFunc: businessAddressFormDisabler,
            //   listenFields: {
            //     same_as_reg_address: (listenVarValue, currentVarKey, allData) => {
            //       allData[currentVarKey] = listenVarValue ? allData.reg_post_code : undefined
            //     },
            //     reg_post_code: (listenVarValue, currentVarKey, allData) => {
            //       if (allData.same_as_reg_address) {
            //         allData[currentVarKey] = listenVarValue
            //       }
            //     }
            //   },
            //   rules: [
            //     rules.required(),
            //     rules.engNumSpecialSpaceOnly(),
            //     rules.counterMax(32),
            //   ]
            // },
            // {
            //   key: 'business_address',
            //   label: vm.$t('fullRegFields.business_address'),
            //   type: FieldType.TEXT,
            //   disabledFunc: businessAddressFormDisabler,
            //   listenFields: {
            //     same_as_reg_address: (listenVarValue, currentVarKey, allData) => {
            //       allData[currentVarKey] = listenVarValue ? allData.reg_address : undefined
            //     },
            //     reg_address: (listenVarValue, currentVarKey, allData) => {
            //       if (allData.same_as_reg_address) {
            //         allData[currentVarKey] = listenVarValue
            //       }
            //     }
            //   },
            //   rules: [
            //     rules.required(),
            //     rules.counterMax(128),
            //   ]
            // },
          ]
        }
      ]
    },
    {
      key: 'business_profile',
      form: [
        {
          label: vm.$t('fullRegGroups.business_profile'),
          key: 'business_profile',
          form: [
            ...(hiddenId ? [HIDDEN_ID_FIELD] : []),
            {
              key: 'full_description',
              label: vm.$t('fullRegFields.full_business_description'),
              type: FieldType.LONGTEXT,
              rules: [
                rules.required(),
                rules.counterMax(512)
              ]
            },
            {
              key: 'nace_codes',
              label: vm.$t('fullRegFields.nace_codes'),
              longHint: vm.$t('fullRegHints.nace_codes'),
              type: FieldType.TEXT,
              rules: [
                rules.counterMax(512)
              ],
              hint: vm.$t('fullRegHints.nace_codes')
            },
            {
              key: 'licence_require',
              type: FieldType.SELECT_RADIO,
              rules: [
                rules.required()
              ],
              extra: {
                items: [
                  {text: vm.$t('fullRegFields.confirm_not_require'), value: false},
                  {text: vm.$t('fullRegFields.confirm_require'), value: true},
                ]
              }
            },
            // {
            //   key: 'confirm_not_require',
            //   label: vm.$t('fullRegFields.confirm_not_require'),
            //   type: FieldType.BOOL,
            //   rules: [
            //     rules.checked(),
            //   ],
            // },
            // {
            //   key: 'confirm_require',
            //   label: vm.$t('fullRegFields.confirm_require'),
            //   type: FieldType.BOOL,
            //   rules: [
            //     rules.checked(),
            //   ],
            // },
            {
              key: 'license_number',
              label: vm.$t('fullRegFields.license_number'),
              type: FieldType.TEXT,
              mountIfValue: (allData) => allData?.licence_require === true,
              rules: [
                rules.required(),
                rules.counterMax(32),
                rules.engNumSpecialSpaceOnly(),
              ],
              // extra: {
              //   mask: '#################'
              // }
            },
            {
              key: 'authority',
              label: vm.$t('fullRegFields.authority'),
              mountIfValue: (allData) => allData?.licence_require === true,
              type: FieldType.TEXT,
              rules: [
                rules.required(),
                rules.counterMax(128)
              ]
            },
            {
              key: 'is_company_listed_on_regular_market',
              label: vm.$t('fullRegFields.is_company_listed_on_regular_market'),
              type: FieldType.SELECT_RADIO,
              rules: [
                rules.required(),
              ],
              extra: {
                items: [
                  {text: vm.$t('common.yes'), value: true},
                  {text: vm.$t('common.no'), value: false},
                ]
              }
            },
            {
              key: 'declare_no_3rd',
              label: vm.$t('fullRegFields.declare_no_3rd'),
              type: FieldType.BOOL,
              default: false,
            },
            {
              key: 'purpose_of_use',
              label: vm.$t('fullRegFields.purpose_of_use'),
              type: FieldType.LONGTEXT,
              rules: [
                rules.required(),
                rules.counterMax(512)
              ]
            },
            {
              key: 'planned_transactions',
              label: vm.$t('fullRegFields.planned_transactions'),
              type: FieldType.LONGTEXT,
              rules: [
                rules.required(),
                rules.counterMax(512)
              ]
            },
          ]
        },
        // {
        //   key: 'declared_account_type',
        //   label: vm.$t('fullRegGroups.declared_account_type'),
        //   form: [
        //     {
        //       key: 'account_type_is_business',
        //       label: vm.$t('fullRegFields.account_type_is_business'),
        //       type: FieldType.BOOL,
        //       rules: [
        //         rules.required(),
        //       ],
        //     },
        //     {
        //       key: 'account_type_is_client_fund',
        //       label: vm.$t('fullRegFields.account_type_is_client_fund'),
        //       type: FieldType.BOOL,
        //       rules: [
        //         rules.required(),
        //       ],
        //     }
        //   ]
        // }
      ]
    },
    // screen
    {
      key: 'directors',
      // groups
      form: [
        {
          key: 'directors',
          label: vm.$t('fullRegGroups.directors'),
          // fields
          form: [
            {
              key: 'directors',
              type: FieldType.OBJECT_LIST,
              rules: [
                rules.counterMinEqual(1),
              ],
              extra: {
                labelField: 'full_name',
                form: [
                  //groups
                  {
                    // label: vm.$t('fullRegGroups.directors'),
                    key: 'directors',
                    form: [
                      ...(hiddenId ? [HIDDEN_ID_FIELD] : []),
                      {
                        key: 'director_type',
                        // label: vm.$t('fullRegFields.director_type'),
                        type: FieldType.SELECT_RADIO,
                        rules: [
                          rules.required(),
                        ],
                        extra: {
                          items: [
                            {text: vm.$t('directorType.director'), value: 'director'},
                            {
                              text: vm.$t('directorType.auth_signatory'),
                              value: 'auth_signatory'
                            },
                            {
                              text: vm.$t('directorType.signatory_on_behalf'),
                              value: 'signatory_on_behalf'
                            },
                          ]
                        }
                      },
                      {
                        key: 'full_name',
                        label: vm.$t('fullRegFields.full_name'),
                        type: FieldType.TEXT,
                        rules: [
                          rules.required(),
                          rules.counterMax(64)
                        ]
                      },
                      {
                        key: 'dob',
                        label: vm.$t('fullRegFields.dob'),
                        type: FieldType.DATE,
                        rules: [
                          rules.required(),
                          rules.dateMinEqual(MIN_DATE),
                          rules.dateMaxEqual(NOW_MINUS_18)
                        ],
                        extra: {
                          min: MIN_DATE,
                          max: NOW_MINUS_18,
                        }
                      },
                      {
                        key: 'birth_country',
                        label: vm.$t('fullRegFields.birth_country'),
                        type: FieldType.COUNTRY_SELECT,
                        rules: [
                          rules.required(),
                          // rules.counterMax(64)
                        ]
                      },
                      {
                        key: 'citizenship_country',
                        label: vm.$t('fullRegFields.citizenship_country'),
                        type: FieldType.COUNTRY_SELECT,
                        rules: [
                          rules.required(),
                          // rules.counterMax(64)
                        ]
                      },
                    ]
                  },
                  {
                    label: vm.$t('fullRegGroups.registered_address'),
                    key: 'registered_address',
                    form: [
                      {
                        key: 'reg_address_object',
                        label: vm.$t('fullRegGroups.registered_address'),
                        type: FieldType.ADDRESS,
                        rules: [
                          rules.required(),
                          rules.isAddressFilled(),
                        ]
                      },
                      // {
                      //   key: 'country',
                      //   label: vm.$t('fullRegFields.country'),
                      //   type: FieldType.TEXT,
                      //   rules: [
                      //     rules.required(),
                      //     rules.counterMax(64),
                      //   ]
                      // },
                      // {
                      //   key: 'city',
                      //   label: vm.$t('fullRegFields.city'),
                      //   type: FieldType.TEXT,
                      //   rules: [
                      //     rules.required(),
                      //     rules.counterMax(64),
                      //   ]
                      // },
                      // {
                      //   key: 'post_code',
                      //   label: vm.$t('fullRegFields.post_code'),
                      //   type: FieldType.TEXT,
                      //   rules: [
                      //     rules.required(),
                      //     rules.engNumSpecialSpaceOnly(),
                      //     rules.counterMax(32),
                      //   ]
                      // },
                      // {
                      //   key: 'address',
                      //   label: vm.$t('fullRegFields.address'),
                      //   type: FieldType.TEXT,
                      //   rules: [
                      //     rules.required(),
                      //     rules.counterMax(128),
                      //   ]
                      // },
                    ]
                  },
                  {
                    label: vm.$t('fullRegGroups.id'),
                    key: 'person_id',
                    form: [
                      {
                        key: 'person_id',
                        label: vm.$t('fullRegFields.passport_id_number'),
                        type: FieldType.TEXT_NUMBER,
                        rules: [
                          rules.required(),
                          rules.counterMax(32),
                        ]
                      },
                      {
                        key: 'exp_date',
                        label: vm.$t('fullRegFields.exp_date'),
                        type: FieldType.DATE,
                        rules: [
                          rules.required(),
                          rules.dateMinEqual(new Date())
                        ],
                        extra: {
                          min: new Date()
                        }
                      },
                      {
                        key: 'authority',
                        label: vm.$t('fullRegFields.authority'),
                        type: FieldType.TEXT,
                        rules: [
                          rules.required(),
                          rules.counterMax(128),
                        ]
                      },
                    ]
                  },
                  {
                    label: vm.$t('fullRegGroups.id_documents'),
                    key: 'id_documents',
                    form: [
                      {
                        key: 'document_type',
                        label: vm.$t('fullRegFields.id_document_type'),
                        type: FieldType.SELECT,
                        rules: [
                          rules.required(),
                        ],
                        extra: {
                          items: [
                            {text: vm.$t('idDocumentType.ID'), value: 'id'},
                            {text: vm.$t('idDocumentType.passport'), value: 'passport'},
                          ]
                        }
                      },
                      {
                        key: 'passport_doc',
                        label: vm.$t('fullRegFields.passport_file'),
                        type: FieldType.FILE_BASE_64,
                        mountIfValue: (allData) => allData?.document_type === 'passport',
                        rules: [
                          rules.required(),
                        ],
                        extra: {
                          accept: 'application/pdf,image/jpeg,image/png,image/jpg'
                        }
                      },
                      {
                        key: 'id_front_doc',
                        label: vm.$t('fullRegFields.id_front_doc'),
                        type: FieldType.FILE_BASE_64,
                        mountIfValue: (allData) => allData?.document_type === 'id',
                        rules: [
                          rules.required(),
                        ],
                        extra: {
                          accept: 'application/pdf,image/jpeg,image/png,image/jpg'
                        }
                      },
                      {
                        key: 'id_reverse_doc',
                        label: vm.$t('fullRegFields.id_reverse_doc'),
                        type: FieldType.FILE_BASE_64,
                        mountIfValue: (allData) => allData?.document_type === 'id',
                        rules: [
                          rules.required(),
                        ],
                        extra: {
                          accept: 'application/pdf,image/jpeg,image/png,image/jpg'
                        }
                      },
                    ],
                  },
                  {
                    label: vm.$t('fullRegGroups.proofs_of_the_address'),
                    key: 'proofs_of_the_address',
                    form: [
                      {
                        key: 'proofs_of_the_address',
                        label: vm.$t('fullRegFields.proofs_of_the_address_doc'),
                        type: FieldType.FILE_BASE_64,
                        rules: [
                          rules.required(),
                          rules.lenMinEqual(1),
                          rules.lenMaxEqual(10),
                        ],
                        extra: {
                          many: true,
                          max: 10,
                          accept: 'application/pdf,image/jpeg,image/png,image/jpg'
                        }
                      },
                    ],
                  },
                  {
                    label: vm.$t('fullRegGroups.contacts'),
                    key: 'contacts',
                    form: [
                      {
                        key: 'phone',
                        label: vm.$t('fullRegFields.phone'),
                        type: simplePhone ? FieldType.TEXT : FieldType.PHONE,
                        default: simplePhone ? undefined : {},
                        rules: [
                          rules.required(),
                          ...(simplePhone ? [rules.counterMax(20)] : [rules.phone()]),
                        ]
                      },
                      {
                        key: 'email',
                        label: vm.$t('fullRegFields.email'),
                        type: FieldType.TEXT,
                        rules: [
                          rules.required(),
                          rules.email(),
                          rules.counterMax(64),
                        ]
                      }
                    ],
                  },
                  {
                    label: '',
                    key: 'extra',
                    form: [
                      {
                        key: 'person_has_right',
                        label: vm.$t('fullRegFields.person_has'),
                        type: FieldType.SELECT_RADIO,
                        rules: [
                          rules.required(),
                        ],
                        extra: {
                          items: [
                            {text: vm.$t('personHas.ssr'), value: 'sole_signatory_right'},
                            {text: vm.$t('personHas.jsr'), value: 'joint_signatory_right'},
                          ]
                        }
                      },
                      {
                        key: 'bank_access',
                        label: vm.$t('fullRegFields.person_requires'),
                        type: FieldType.SELECT_RADIO,
                        rules: [
                          rules.required(),
                        ],
                        extra: {
                          items: [
                            {text: vm.$t('personRequires.full_mode'), value: true},
                            {
                              text: vm.$t('personRequires.no_access_required'),
                              value: false
                            },
                          ]
                        }
                      },
                      // {
                      //   key: 'person_requires',
                      //   label: vm.$t('fullRegFields.person_requires'),
                      //   type: FieldType.SELECT_RADIO,
                      //   rules: [
                      //     rules.required(),
                      //   ],
                      //   extra: {
                      //     items: [
                      //       {text: vm.$t('personRequires.full_mode'), value: 'full_mode'},
                      //       {
                      //         text: vm.$t('personRequires.no_access_required'),
                      //         value: 'no_access_required'
                      //       },
                      //     ]
                      //   }
                      // },
                      {
                        key: 'is_individual_usa',
                        label: vm.$t('fullRegFields.is_individual_usa'),
                        longHint: vm.$t('fullRegHints.is_individual_usa'),
                        type: FieldType.SELECT_RADIO,
                        rules: [
                          rules.required(),
                        ],
                        extra: {
                          items: [
                            {text: vm.$t('common.yes'), value: true},
                            {text: vm.$t('common.no'), value: false},
                          ]
                        }
                      },
                      {
                        key: 'is_individual_pep',
                        label: vm.$t('fullRegFields.is_individual_pep'),
                        longHint: vm.$t('fullRegHints.is_pep'),
                        type: FieldType.SELECT_RADIO,
                        rules: [
                          rules.required(),
                        ],
                        extra: {
                          items: [
                            {text: vm.$t('common.yes'), value: true},
                            {text: vm.$t('common.no'), value: false},
                          ]
                        }
                      },
                    ],
                  },
                  {
                    label: vm.$t('fullRegGroups.company_ownership'),
                    key: 'extra_p_own',
                    form: [
                      {
                        key: 'significant_percent',
                        label: vm.$t('fullRegFields.significant_percent'),
                        type: FieldType.BOOL,
                        rules: [],
                        // eslint-disable-next-line no-unused-vars
                        onChange: (value, allData, allFormData) => {
                          // console.log(allData)
                          // eslint-disable-next-line no-debugger
                          debugger
                          if (value) {
                            let data = _uboFromDirector(allData)
                            if (data) {
                              let uboList = allFormData?.ubo?.ubo
                              if (uboList) {
                                let index = uboList.findIndex(x => x.full_name === allData?.full_name)
                                if (index >= 0) {
                                  uboList.splice(index, 1, data)
                                } else {
                                  uboList.splice(0, 0, data)
                                }
                              }
                            }
                          } else {
                            let items = allFormData?.ubo?.ubo
                            if (!items) return
                            let index = items.findIndex(x => x.full_name === allData?.full_name)
                            if (index >= 0) {
                              items.splice(index, 1)
                            }
                          }

                        }
                      },
                      {
                        key: 'owning_percents',
                        label: vm.$t('fullRegFields.owning_percents'),
                        type: FieldType.FLOAT,
                        mountIfValue: (allData) => allData?.significant_percent === true,
                        default: MINIMUM_OWN_PERCENT,
                        rules: [
                          rules.required(),
                          rules.numMinEqual(MINIMUM_OWN_PERCENT),
                          rules.numMaxEqual(100),
                        ],
                      },
                    ],
                  }
                ]
              }
            }
          ],
        }
      ]
    },
    {
      key: 'ubo',
      // groups
      form: [
        {
          // fields
          form: [
            {
              key: 'ubo',
              type: FieldType.OBJECT_LIST,
              extra: {
                labelField: 'full_name',
                form: [
                  //groups
                  {
                    label: vm.$t('fullRegGroups.ubo'),
                    longHint: vm.$t('fullRegHints.ubo'),
                    key: 'ubo',
                    form: [
                      ...(hiddenId ? [HIDDEN_ID_FIELD] : []),
                      {
                        key: 'ubo_type',
                        // label: vm.$t('fullRegFields.ubo_type'),
                        type: FieldType.SELECT_RADIO,
                        rules: [
                          rules.required(),
                        ],
                        extra: {
                          items: [
                            {text: vm.$t('uboType.ubo'), value: 'ubo'},
                            {text: vm.$t('uboType.shareholder'), value: 'shareholder'},
                          ]
                        }
                      },
                      {
                        key: 'full_name',
                        label: vm.$t('fullRegFields.full_name'),
                        type: FieldType.TEXT,
                        rules: [
                          rules.required(),
                          rules.counterMax(64)
                        ]
                      },
                      {
                        key: 'dob',
                        label: vm.$t('fullRegFields.dob'),
                        type: FieldType.DATE,
                        rules: [
                          rules.required(),
                          rules.dateMinEqual(MIN_DATE),
                          rules.dateMaxEqual(NOW_MINUS_18)
                        ],
                        extra: {
                          min: MIN_DATE,
                          max: NOW_MINUS_18,
                        }
                      },
                      {
                        key: 'birth_country',
                        label: vm.$t('fullRegFields.birth_country'),
                        type: FieldType.COUNTRY_SELECT,
                        rules: [
                          rules.required(),
                          // rules.counterMax(64)
                        ]
                      },
                      {
                        key: 'citizenship_country',
                        label: vm.$t('fullRegFields.citizenship_country'),
                        type: FieldType.COUNTRY_SELECT,
                        rules: [
                          rules.required(),
                          // rules.counterMax(64)
                        ]
                      },
                    ]
                  },
                  {
                    key: 'registered_address',
                    label: vm.$t('fullRegGroups.registered_address'),
                    form: [
                      {
                        key: 'reg_address_object',
                        label: vm.$t('fullRegGroups.registered_address'),
                        type: FieldType.ADDRESS,
                        rules: [
                          rules.required(),
                          rules.isAddressFilled(),
                        ]
                      },
                      // {
                      //   key: 'country',
                      //   label: vm.$t('fullRegFields.country'),
                      //   type: FieldType.TEXT,
                      //   rules: [
                      //     rules.required(),
                      //     rules.counterMax(64),
                      //   ]
                      // },
                      // {
                      //   key: 'city',
                      //   label: vm.$t('fullRegFields.city'),
                      //   type: FieldType.TEXT,
                      //   rules: [
                      //     rules.required(),
                      //     rules.counterMax(64),
                      //   ]
                      // },
                      // {
                      //   key: 'post_code',
                      //   label: vm.$t('fullRegFields.post_code'),
                      //   type: FieldType.TEXT,
                      //   rules: [
                      //     rules.required(),
                      //     rules.engNumSpecialSpaceOnly(),
                      //     rules.counterMax(32),
                      //   ]
                      // },
                      // {
                      //   key: 'address',
                      //   label: vm.$t('fullRegFields.address'),
                      //   type: FieldType.TEXT,
                      //   rules: [
                      //     rules.required(),
                      //     rules.counterMax(128),
                      //   ]
                      // },
                    ]
                  },
                  {
                    label: vm.$t('fullRegGroups.id'),
                    key: 'person_id',
                    form: [
                      {
                        key: 'person_id',
                        label: vm.$t('fullRegFields.passport_id_number'),
                        type: FieldType.TEXT_NUMBER,
                        rules: [
                          rules.required(),
                          rules.counterMax(32),
                        ]
                      },
                      {
                        key: 'exp_date',
                        label: vm.$t('fullRegFields.exp_date'),
                        type: FieldType.DATE,
                        rules: [
                          rules.required(),
                          rules.dateMinEqual(new Date()),
                          rules.dateMaxEqual(MAX_DATE)
                        ],
                        extra: {
                          min: new Date(),
                          max: MAX_DATE,
                        }
                      },
                      {
                        key: 'authority',
                        label: vm.$t('fullRegFields.authority'),
                        type: FieldType.TEXT,
                        rules: [
                          rules.required(),
                          rules.counterMax(32),
                        ]
                      },
                    ]
                  },
                  {
                    label: vm.$t('fullRegGroups.id_documents'),
                    key: 'id_documents',
                    form: [
                      {
                        key: 'document_type',
                        label: vm.$t('fullRegFields.id_document_type'),
                        type: FieldType.SELECT,
                        rules: [
                          rules.required(),
                        ],
                        extra: {
                          items: [
                            {text: vm.$t('idDocumentType.ID'), value: 'id'},
                            {text: vm.$t('idDocumentType.passport'), value: 'passport'},
                          ]
                        }
                      },
                      {
                        key: 'passport_doc',
                        label: vm.$t('fullRegFields.passport_file'),
                        type: FieldType.FILE_BASE_64,
                        mountIfValue: (allData) => allData?.document_type === 'passport',
                        rules: [
                          rules.required(),
                        ],
                        extra: {
                          accept: 'application/pdf,image/jpeg,image/png,image/jpg'
                        }
                      },
                      {
                        key: 'id_front_doc',
                        label: vm.$t('fullRegFields.id_front_doc'),
                        type: FieldType.FILE_BASE_64,
                        mountIfValue: (allData) => allData?.document_type === 'id',
                        rules: [
                          rules.required(),
                        ],
                        extra: {
                          accept: 'application/pdf,image/jpeg,image/png,image/jpg'
                        }
                      },
                      {
                        key: 'id_reverse_doc',
                        label: vm.$t('fullRegFields.id_reverse_doc'),
                        type: FieldType.FILE_BASE_64,
                        mountIfValue: (allData) => allData?.document_type === 'id',
                        rules: [
                          rules.required(),
                        ],
                        extra: {
                          accept: 'application/pdf,image/jpeg,image/png,image/jpg'
                        }
                      },
                    ],
                  },
                  {
                    label: vm.$t('fullRegGroups.proofs_of_the_address'),
                    key: 'proofs_of_the_address',
                    form: [
                      {
                        key: 'proofs_of_the_address',
                        label: vm.$t('fullRegFields.proofs_of_the_address_doc'),
                        type: FieldType.FILE_BASE_64,
                        rules: [
                          rules.required(),
                          rules.lenMinEqual(1),
                          rules.lenMaxEqual(10),
                        ],
                        extra: {
                          many: true,
                          max: 10,
                          accept: 'application/pdf,image/jpeg,image/png,image/jpg'
                        }
                      },
                    ],
                  },
                  {
                    label: vm.$t('fullRegGroups.contacts'),
                    key: 'contacts',
                    form: [
                      {
                        key: 'phone',
                        label: vm.$t('fullRegFields.phone'),
                        type: simplePhone ? FieldType.TEXT : FieldType.PHONE,
                        default: simplePhone ? undefined : {},
                        rules: [
                          rules.required(),
                          ...(simplePhone ? [rules.counterMax(20)] : [rules.phone()]),
                        ]
                      },
                      {
                        key: 'email',
                        label: vm.$t('fullRegFields.email'),
                        type: FieldType.TEXT,
                        rules: [
                          rules.required(),
                          rules.email(),
                          rules.counterMax(64),
                        ]
                      }
                    ],
                  },
                  {
                    label: '',
                    key: 'extra',
                    form: [
                      {
                        key: 'person_has_right',
                        label: vm.$t('fullRegFields.person_has'),
                        type: FieldType.SELECT_RADIO,
                        rules: [
                          rules.required(),
                        ],
                        extra: {
                          items: [
                            {text: vm.$t('personHas.ssr'), value: 'sole_signatory_right'},
                            {text: vm.$t('personHas.jsr'), value: 'joint_signatory_right'},
                          ]
                        }
                      },
                      {
                        key: 'bank_access',
                        label: vm.$t('fullRegFields.person_requires'),
                        type: FieldType.SELECT_RADIO,
                        rules: [
                          rules.required(),
                        ],
                        extra: {
                          items: [
                            {text: vm.$t('personRequires.full_mode'), value: true},
                            {
                              text: vm.$t('personRequires.no_access_required'),
                              value: false
                            },
                          ]
                        }
                      },
                      {
                        key: 'is_individual_usa',
                        label: vm.$t('fullRegFields.is_individual_usa'),
                        longHint: vm.$t('fullRegHints.is_individual_usa'),
                        type: FieldType.SELECT_RADIO,
                        rules: [
                          rules.required(),
                        ],
                        extra: {
                          items: [
                            {text: vm.$t('common.yes'), value: true},
                            {text: vm.$t('common.no'), value: false},
                          ]
                        }
                      },
                      {
                        key: 'is_individual_pep',
                        label: vm.$t('fullRegFields.is_individual_pep'),
                        longHint: vm.$t('fullRegHints.is_pep'),
                        type: FieldType.SELECT_RADIO,
                        rules: [
                          rules.required(),
                        ],
                        extra: {
                          items: [
                            {text: vm.$t('common.yes'), value: true},
                            {text: vm.$t('common.no'), value: false},
                          ]
                        }
                      },
                    ],
                  }
                ]
              }
            }
          ],
        }
      ]
    },
    {
      key: 'app_info',
      form: [
        {
          label: vm.$t('fullRegGroups.app_declaration'),
          key: 'app_declaration',
          form: [
            {
              type: FieldType.VIEW,
              label: vm.$t('fullRegAppDeclItems.a1')
            },
            {
              type: FieldType.VIEW,
              label: vm.$t('fullRegAppDeclItems.a2')
            },
            {
              type: FieldType.VIEW,
              label: vm.$t('fullRegAppDeclItems.a3')
            },
            {
              type: FieldType.VIEW,
              label: vm.$t('fullRegAppDeclItems.a4')
            },
            {
              type: FieldType.VIEW,
              label: vm.$t('fullRegAppDeclItems.a5')
            },
            {
              type: FieldType.VIEW,
              label: vm.$t('fullRegAppDeclItems.a6')
            },
            {
              type: FieldType.VIEW,
              label: vm.$t('fullRegAppDeclItems.a7')
            },
            {
              type: FieldType.VIEW,
              label: vm.$t('fullRegAppDeclItems.a8')
            },
            {
              type: FieldType.VIEW,
              label: "<p></p>"
            },
            {
              type: FieldType.VIEW,
              label: vm.$t('fullRegAppDeclItems.a9')
            },
            {
              type: FieldType.VIEW,
              label: vm.$t('fullRegAppDeclItems.a10')
            },
            {
              type: FieldType.VIEW,
              label: vm.$t('fullRegAppDeclItems.a11')
            },
            {
              key: 'agree_papaya_terms',
              label: vm.$t('fullRegFields.agree_papaya_terms'),
              type: FieldType.BOOL,
              rules: [
                rules.checked(),
              ],
            },
            {
              key: 'agree_data_protection',
              label: vm.$t('fullRegFields.agree_data_protection'),
              type: FieldType.BOOL,
              rules: [
                rules.checked(),
              ],
            },
          ]
        }
      ]
    },
    {
      key: 'set_of_docs',
      form: [
        {
          label: vm.$t('fullRegGroups.set_of_docs'),
          key: 'app_declaration',
          form: [
            {
              type: FieldType.FILE_BASE_64,
              key: 'set_of_docs',
              label: vm.$t('common.doc'),
              rules: [
                rules.required(),
                rules.lenMinEqual(1),
                rules.lenMaxEqual(50),
              ],
              extra: {
                many: true,
                max: 50,
                typeTags: CONSTS.companyRegDocTypes.map(tt => ({
                  text: vm.$t(`setOfDocsTypes.${tt}`),
                  value: tt
                })),
                accept: 'application/pdf,image/jpeg,image/png,image/jpg'
              }
            },
          ]
        },
        {
          label: vm.$t('fullRegGroups.sof'),
          key: 'app_declaration',
          form: [
            {
              type: FieldType.FILE_BASE_64,
              key: 'sof',
              label: vm.$t('common.doc'),
              rules: [
                rules.required(),
                rules.lenMinEqual(1),
                rules.lenMaxEqual(10),
              ],
              extra: {
                many: true,
                max: 10,
                accept: 'application/pdf,image/jpeg,image/png,image/jpg'
              }
            },
          ]
        }
      ]
    },
    // {
    //   key: 'authorised_signatory',
    //   form: [
    //     {
    //       label: vm.$t('fullRegGroups.director'),
    //       key: 'authorised_signatory',
    //       form: [
    //         {
    //           type: FieldType.VIEW,
    //           label: vm.$t('fullRegFields.application_file_form_text')
    //         },
    //         {
    //           type: FieldType.VIEW,
    //           label: vm.$t('fullRegFields.application_file_form_download')
    //         },
    //         {
    //           key: 'application_file',
    //           label: vm.$t('fullRegFields.application_file'),
    //           type: FieldType.FILE,
    //           rules: [
    //             rules.required(),
    //           ],
    //           extra: {
    //             accept: 'application/pdf'
    //           }
    //         },
    //       ]
    //     }
    //   ]
    // }
  ]
}