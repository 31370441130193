import Vue from 'vue';
import VueRouter from 'vue-router';
import NotFound from "@/components/common/pages/NotFound";
import ResetPasswordPage from "@/components/common/pages/ResetPasswordPage";

Vue.use(VueRouter);

function loadRoutes() {
  return require(
    process.env.VUE_APP_TYPE === 'staff' ?
      "./staffRouter" :
      "./clientRouter"
  ).default
}

/**
 * Adds to location new query params without reloading or opening new page
 * @param {Vue}ctx
 * @param {Record<string, any>}newQuery
 * @param {boolean}replace
 * @returns {Promise<Route>}
 */
export function applyRouterQuery(ctx, newQuery, replace = false) {
  return ctx.$router.replace({
    path: ctx.$route.path,
    query: replace ? newQuery : {
      ...ctx.$route.query,
      ...newQuery
    }
  })
}

export default new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/service/pwd/reset',
      component: ResetPasswordPage
    },
    ...loadRoutes(),
    {
      path: '*',
      component: NotFound
    }
  ]
});