// import Vue from "vue";

// Vue.directive('delay-model',)

const emit = (vnode, name, data) => {
  let handlers = (vnode.data && vnode.data.on) ||
    (vnode.componentOptions && vnode.componentOptions.listeners);

  if (handlers && handlers[name]) {
    handlers[name].fns(data);
  }
}

export default {
  delayModel: {
    // bind: (el, binding, vnode, oldVnode) => {
    //   console.log("DELAY bind")
    //   console.log(el, binding, vnode, oldVnode)
    // },
    // inserted: (el, binding, vnode, oldVnode) => {
    //   console.log("DELAY inserted")
    //   console.log(el, binding, vnode, oldVnode)
    // },
    update: (el, binding, vnode, oldVnode) => {
      let timeout = binding?.value || 1000
      let modelValue = vnode.data?.model?.value || undefined
      if (oldVnode.timer) {
        clearTimeout(oldVnode.timer)
      }
      vnode.timer = setTimeout(() => {
        emit(vnode, 'delay-input', modelValue)
      }, timeout)
    },
    // componentUpdated: (el, binding, vnode, oldVnode) => {
    //   console.log("DELAY componentUpdated")
    //   console.log(el, binding, vnode, oldVnode)
    // },
    // unbind: (el, binding, vnode, oldVnode) => {
    //   console.log("DELAY unbind")
    //   console.log(el, binding, vnode, oldVnode)
    // },
  }
}