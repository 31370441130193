<template>
  <page-wrapper>
    <v-container style="padding-top: 5%">
      <div class="d-flex align-center justify-space-between">
        <div class="text-h3">
          {{ $t('client.receivers.list') }}
        </div>
        <app-button icon="ri-user-add-line" @click="addNewClick" mobile-icon-only>Add new receiver</app-button>
      </div>
      <div class="pt-3">
        <receivers-list-view grouped/>
      </div>
    </v-container>
  </page-wrapper>
</template>

<script>
import PageWrapper from "@/components/common/widgets/PageWrapper";
import AppButton from "@/components/common/widgets/AppButton";
import ReceiversListView from "@/components/client/widgets/receivers/ReceiversListView";

export default {
  name: "ClientReceiversPage",
  components: {ReceiversListView, AppButton, PageWrapper},
  methods: {
    addNewClick() {
      this.$router.push({name: 'add_receiver'})
    }
  }
}
</script>

<style scoped>

</style>