import Vue from 'vue'
import Vuex from 'vuex'
import endpoints from "@/utils/web/endpoints";

Vue.use(Vuex);

const tmpStore = new Vuex.Store({
  state: {
    defaultDDDialogEnabled: true,
    progressLoader: false,
    userInfo: undefined,
    metrics: {}
  },
  getters: {
    getUserInfo: (state) => {
      return state.userInfo
    },
    getProgressLoader: (state) => {
      return state.progressLoader
    },
    getMetrics: (state) => {
      return state.metrics
    }
  },
  mutations: {
    setProgressLoader(state, val) {
      state.progressLoader = val
    },
    setUserInfo: (state, val) => {
      state.userInfo = val;
    },
    setMetrics: (state, val) => {
      return state.metrics = val
    }
  },
  actions: {
    async fetchUserInfo({commit}, ctx) {
      try {
        let userData = await ctx.$http.get(endpoints.USERS().INFO, {
          changeState: true
        })
        // console.log(userData)
        commit('setUserInfo', userData)
      } catch (_) {
        commit('setUserInfo', {error: true})
      }
    }
  }
})

// eslint-disable-next-line
export function install(Vue, options = {}) {
  Object.defineProperty(Vue.prototype, "$tmpStore", {
    get: function $tmpStore() {
      return tmpStore
    }
  });
}

export default {install}