// import {RULES} from "./valid_rules";

import RULES from "@/plugins/rules";
import {appConfig} from "@/config";
import {applyRouterQuery} from "@/plugins/router";
import goTo from "vuetify/lib/services/goto";

export const mainLayoutMixin = {
  computed: {
    bindDrawerContentHolder: function () {
      return {
        // class: {
        //   "rounded ma-3 mr-0": !this.$vuetify.breakpoint.mobile
        // },
        // style: this.$vuetify.breakpoint.mobile ? {
        //   width: '100%',
        //   height: "100%"
        // } : {
        //   width: '288px',
        //   height: "calc(100% - 24px)"
        // }
      }
    },
    userInfo: function () {
      return this.$tmpStore.getters.getUserInfo
    },
  },
  data() {
    return {
      drawer: true
    }
  },
}

export const publicAccessMixin = {
  methods: {
    getPublicSrc(src) {
      return `${appConfig.publicPath}${src}`
    },
    getPublicImg(src) {
      return `${appConfig.publicPath}img/${src}`
    }
  }
}

export const loadingMixin = {
  data: () => ({
    loadingStatus$: {
      status: 'none',
      loading: false,
      loaded: false,
      error: null,
    }
  })
};

export const rulesMixin = {
  computed: {
    inputValidationRules: function () {
      return RULES(this)
    }
  }
}
/**
 *
 * @param modelVarName: String
 * @param modelVarType: Class
 * @param defaultValue: Object?
 * @returns {{data(): {}, watch: {value: watch.value}, props: {value: {default: null, type}}}}
 */
export const modelMixin = function (modelVarName, modelVarType, defaultValue = null) {
  return {
    props: {
      value: {
        type: modelVarType,
        default: defaultValue
      }
    },
    data() {
      return {
        [modelVarName]: this.value,
      }
    },
    watch: {
      [modelVarName]: function (val) {
        this.$emit('input', val);
      },
      value: function (val) {
        this[modelVarName] = val;
      }
    },
  }
};

export const syncPropMixin = function (propVarName, propAttrName, propVarType, defaultValue = null) {
  return {
    props: {
      [propAttrName]: {
        type: propVarType,
        default: defaultValue
      }
    },
    data() {
      return {
        [propVarName]: this[propAttrName],
      }
    },
    watch: {
      [propVarName]: function (val) {
        this.$emit(`update:${propAttrName}`, val);
      },
      [propAttrName]: function (val) {
        this[propVarName] = val;
      }
    },
  }
};

export const pageTitleMixin = {
  computed: {
    pageTitle: function () {
      return ''
    }
  },
  watch: {
    pageTitle: function (val) {
      this.$bus.$emit('set-page-subtitle', val)
    }
  },
  beforeDestroy() {
    this.$bus.$emit('set-page-subtitle', '')
  },
  mounted() {
    this.$bus.$emit('set-page-subtitle', this.pageTitle)
  }
}

export const paginationLoadingMixin = {
  mixins: [loadingMixin],
  computed: {
    isFirstLoading$: function () {
      return this.pagination$.allCount === undefined
    },
    paginationLen$: function () {
      let ac = this.pagination$.allCount
      if (Number.isInteger(ac)) {
        return Math.ceil(ac / this.pagination$.limit)
      }
      return 0
    },
    currentPageNumber$: function () {
      return Math.ceil(this.pagination$.offset / this.pagination$.limit) + 1
    },
  },
  watch: {
    'pagination$.offset': function (val) {
      this._onOffsetChanged(val)
      // this.pagination$.currentPage = Math.ceil(val / this.pagination$.limit)
    },
  },
  data: () => ({
    autoRequest: true,
    pagination$: {
      offset: 0,
      allCount: undefined,
      limit: 20,
    },
  }),
  methods: {
    onUiPageChanged(page) {
      if (this.currentPageNumber$ === page) return
      this.pagination$.offset = (page - 1) * this.pagination$.limit
    },
    _onOffsetChanged(offset) {
      if (this.autoRequest && this.getData?.call) {
        this.getData.call(this, offset)
      }
      this.onOffsetChanged(offset)
    },
    // eslint-disable-next-line no-unused-vars
    onOffsetChanged(offset) {
    },
    // eslint-disable-next-line no-unused-vars
    onLimitChanged(limit) {
    }
  },
};

export const listViewMixin = {
  mixins: [
    paginationLoadingMixin,
  ],
  computed: {
    orderData$: function () {
      if (!this.orderField)
        return {
          isUp: false,
          field: undefined
        }
      let minusFirst = this.orderField.startsWith("-")
      return {
        isUp: minusFirst,
        field: minusFirst ? this.orderField.substring(1) : this.orderField
      }
    }
  },
  data() {
    return {
      orderField: undefined,
      useQueryOrdering: true
    }
  },
  watch: {
    orderField: function (val) {
      this._onOrderFieldChanged(val)
    }
  },
  methods: {
    _getQueryOrder() {
      this.orderField = this.$route.query?.order
    },
    _setQueryOrder(order) {
      let queryOrder = this.$route.query?.order
      if (order !== queryOrder) {
        return applyRouterQuery(this, {
          order: order
        }, false)
      }
    },
    _onOrderFieldChanged(order) {
      // console.log(order)
      if (this.useQueryOrdering) {
        this._setQueryOrder(order)?.then(() => {
          if (this.autoRequest && this.getData?.call) {
            this.getData.call(this)
          }
        })
      }
      this.onOrderFieldChanged(order)
    },
    // eslint-disable-next-line no-unused-vars
    onOrderFieldChanged(order) {
    },
    getData() {
    },
    beforeGetData() {}
  },
  created() {
    if (this.useQueryOrdering) {
      this._getQueryOrder()
    }
    this.beforeGetData()
    this.getData()
  }
}

export const formFieldViewMixin = {
  props: {
    simple: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: undefined
    },
    value: {
      default: undefined
    },
    dataKey: {
      type: String,
      default: undefined
    },
    type: {
      type: String,
      default: undefined
    }
  }
}
export const formInputMixin = {
  mixins: [
    modelMixin('input', undefined, undefined),
    syncPropMixin('mFieldErrors', 'fieldErrors', Array, () => []),
  ],
  props: {
    mode: {
      type: String,
      default: undefined,
    },
    fieldKey: {
      type: String,
      default: undefined
    },
    fullObject: {
      type: Object,
      default: undefined
    },
    hideLabels: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: undefined
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    filled: {
      type: Boolean,
      default: true,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String | Number,
      default: undefined,
    },
    field: {
      type: Object,
      default: null,
    },
    rules: {
      type: Array,
      default: null
    },
    structKey: {
      type: String,
      default: null
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    error: {
      type: Boolean,
      default: false,
    },
    allData: {
      type: [Object, Array],
      default: undefined
    },
    allFormData: {
      default: undefined
    },
    animate: {
      type: String,
      default: 'fadeIn'
    },
    delayChildAnimation: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    animationClass: function () {
      if (!this.animate) return {}
      return {
        "animate": !!this.animate,
        "delayed-appear": this.delayChildAnimation,
        [this.animate]: true
      }
    },
    clientRules: function () {
      // return RULES(this)
      return {}
    },
    validationRules: function () {
      let fieldRules = [];
      if (this.field?.rules?.length) {
        for (let i = 0; i < this.field.rules.length; i++) {
          fieldRules.push(this.field.rules[i])
        }
      }
      return [
        ...(this.rules || []),
        ...fieldRules
      ]
    },
    _remaps: function () {
      return {
        icon: 'prependInnerIcon',
        // key: 'label',
        label: 'label',
      }
    },
    _fieldKey: function () {
      return this.field?.key || this.fieldKey
    },
    defaultFormProps: function () {
      //'label',
      return ['readonly', 'errorMessages', 'error', 'label', 'hint']
    },
    defaultFormStyleProps: function () {
      //'label',
      return ['outlined', 'filled', 'hideDetails', 'rounded', 'dense', 'fullWidth']
    },
    $bindField: function () {
      return {
        ...this.bindDefault,
        ...this.bindField,
        ...this.bindDefaultStyle,
        ...this.bindDisabled
      }
    },
    bindDisabled: function () {
      return {
        disabled: this.field?.disabled || this.disabled || this.field?.disabledFunc?.call(this, this.input, this.allData)
      }
    },
    bindDefaultStyle: function () {
      return this._bindPropsList(this.defaultFormStyleProps)
    },
    bindDefault: function () {
      return this._bindPropsList(this.defaultFormProps)
    },
    bindField: function () {
      if (!this.field) return {};
      let res = Object.keys(this._remaps).reduce((res, p) => {
        res[this._bindRemap(p)] = this.field[p];
        return res;
      }, {})
      if (this.hideLabels) {
        res['label'] = null
      }
      res.rules = this.validationRules
      if (res.hint) {
        res.persistentHint = true;
      }
      return res
    },
    simple: function () {
      return this.hideLabels
    }
  },
  data() {
    return {
      listenWatchers: {},
      changeListener: undefined,
    }
  },
  methods: {
    _bindPropsList(props) {
      return props.reduce((res, p) => {
        let pv = this.$props[p];
        // if (typeof pv !== 'undefined') res[p] = pv || (this.field ? this.field[p] : null);
        res[p] = typeof pv !== 'undefined' ?
          pv :
          (this.field ? this.field[p] : undefined);
        return res;
      }, {})
    },
    _bindRemap(prop) {
      return this._remaps[prop] || prop
    },
    /**
     *
     * @param {Record<String, Function<Object, String, Object>>}listenFields
     */
    addListenersFromField(listenFields) {
      let keys = Object.keys(listenFields)
      for (let i = 0; i < keys.length; i++) {
        let listenerKey = keys[i]
        this.listenWatchers[listenerKey] = this.$watch(
          () => this.allData[listenerKey],
          (listenVarValue) => {
            listenFields[listenerKey].call(this, listenVarValue, this._fieldKey, this.allData)
          }
        )
      }
    },
    addChangeListenerFromField() {
      let func = this.field?.onChange
      if (!func) return
      this.changeListener = this.$watch(
        'input',
        (listenVarValue) => {
          func.call(this, listenVarValue, this.allData, this.allFormData)
        }
      )
    }
  },
  created() {
    this.addChangeListenerFromField()
    if (this.field?.listenFields) {
      this.addListenersFromField(this.field.listenFields)
    }
  }
}

export const selectInputMixin = {
  mixins: [formInputMixin],
  props: {
    items: {
      type: Array,
      default: undefined
    },
  },
  computed: {
    visibleItems: function () {
      return this.items || this.field?.extra?.items || []
    }
  }
}

export const flexSizeMixin = {
  props: {
    fullWidth: {
      type: Boolean,
      default: false
    },
    sm: {
      default: 12,
    },
    md: {
      default: 6,
    },
    lg: {
      default: 4,
    },
    useColumns: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    SM: function () {
      return this.fullWidth ? 12 : this.sm
    },
    MD: function () {
      return this.fullWidth ? 12 : this.md
    },
    LG: function () {
      return this.fullWidth ? 12 : this.lg
    }
  }
}

export const refClickBindMixin = {
  props: {
    to: {
      default: undefined,
    }
  },
  computed: {
    refClickElementOn: function () {
      let on = {}
      if (this.to) {
        on.click = () => {
          this.$router.push(this.to)
        }
      } else if (this.$listeners?.click) {
        on.click = this.$listeners.click
      }
      return on
    }
  }
}

export const autoPageScrollMixin = {
  methods: {
    scrollToTop() {
      let root = this.$refs.scrollPageRoot
      if (root) {
        goTo(root, {
          easing: "easeInOutCubic"
        })
      }
    }
  },
  mounted() {
    this.scrollToTop()
  }
}