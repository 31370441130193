<template>
  <v-card light>
    <div ref="sumsubContainer"></div>
  </v-card>
</template>

<script>
import snsWebSdk from '@sumsub/websdk';
import endpoints from "@/utils/web/endpoints";

export default {
  name: "SumsubSdkComponent",
  // computed: {
  //   containerId: function () {
  //     return `sumsub-websdk-container-${randStr()}`
  //   }
  // },
  methods: {
    async getNewToken() {
      return (await this.getPersonInfo()).sumsub_token
    },
    async getPersonInfo() {
      return await this.$http.get(
          endpoints.PERSONS().SUMSUB_INFO
      )
    },
    onIdCheckError(error) {
      console.log(error)
    },
    onIdCheckApplicantSubmitted(payload) {
      console.log("SUBMITTED", payload)
    },
    onIdCheckStepCompleted(payload) {
      console.log(payload)
    },
    // launchWebSdk(accessToken, email, phone, country) {
    launchWebSdk({sumsub_token, email, phone, country}) {
      let snsWebSdkInstance = snsWebSdk.init(
          sumsub_token,
          this.getNewToken
      ).withConf({
        country: country,
        lang: this.$i18n.locale,
        email: email,
        phone: phone,
      }).withOptions(
          {addViewportTag: false, adaptIframeHeight: true}
      ).on('idCheck.stepCompleted', this.onIdCheckStepCompleted
      ).on('idCheck.onApplicantSubmitted', this.onIdCheckApplicantSubmitted
      ).on('idCheck.onError', this.onIdCheckError
      ).build()

      snsWebSdkInstance.launch(this.$refs.sumsubContainer)
    }
  },
  mounted() {
    this.getPersonInfo().then(resp => {
      console.log(resp)
      this.launchWebSdk(resp)
    })
    // this.launchWebSdk(
    //     "token",
    //     "asddsa@asd.dd",
    //     "+441623232",
    // )
  }
}
</script>

<style scoped>

</style>