const {loadEnvVar} = require("./envLoader");

const isProd = process.env.NODE_ENV === 'production'
const appType = process.env.VUE_APP_TYPE || undefined


const LOOP_BACK = `http://127.0.0.1:${loadEnvVar("DEV_PORT", appType)}/`

// if (process) {
//   console.log("PROC ::::::::::::::", process.execPath, "::::::::::::::")
console.log("PROD ::::::::::::::", isProd, "::::::::::::::")
console.log("APP_TYPE ::::::::::::::", appType, "::::::::::::::")
// }

if (!appType) {
  throw new Error("Build error: please specify app type by setting env var \"VUE_APP_TYPE\" to \"client\" or \"staff\"")
}

const commonConfig = {
  appType: appType,
  runtimeCompiler: false,
  gCaptchaSiteKey: loadEnvVar("G_CAPTCHA_SITE_KEY"),
  wsProto: loadEnvVar("WEB_SOCKET_PROTO", undefined, 'ws'),
  wsBaseHost: loadEnvVar("WEB_SOCKET_BASE_HOST", undefined, '127.0.0.1:8000'),
  // wsBaseHost: loadEnvVar("WEB_SOCKET_BASE_HOST", undefined, '192.168.50.28:8080'),
  debugCaptcha: !isProd,
  baseApiUrl: isProd ? loadEnvVar("PROD_BASE_API_URL", appType) : loadEnvVar("DEV_BASE_API_URL", appType),
  outputDir: isProd ? loadEnvVar("PROD_APP_OUTPUT_DIR", appType) : (loadEnvVar("DEV_APP_OUTPUT_DIR", appType) || "dist"),
  publicPath: isProd
    ? (process.env.PUBLIC_URL || '/')
    : LOOP_BACK,
  // publicPath: '/',
  transpileDependencies: [
    'vuetify'
  ],
  configureWebpack: {
    devServer: {
      port: loadEnvVar("DEV_PORT", appType),
      historyApiFallback: true,
    },
  }
}

const clientAppConfig = {
  ...commonConfig,
}
const staffAppConfig = {
  ...commonConfig,
}

const appConfig = appType === 'staff' ? staffAppConfig : clientAppConfig

const vueConfigFields = [
  'runtimeCompiler', 'outputDir', 'publicPath', 'transpileDependencies',
  'configureWebpack'
]

function getVueConfig() {
  let res = {}
  vueConfigFields.forEach(key => {
    res[key] = appConfig[key]
    // console.log("VUE CONFIG:", key, appConfig[key])
  })
  return res
}

module.exports = {
  appConfig,
  getVueConfig
}