import {FieldType} from "@/forms/fieldTypes";
import RULES from "@/plugins/rules";

// eslint-disable-next-line no-unused-vars
export default function (vm, modelVar = 'input') {
  let rules = RULES(vm)
  return [
    {
      label: vm.$t('staffs.prefs.beneficiary_account.form.first_name'),
      key: 'first_name',
      type: FieldType.TEXT,
      rules: [
        rules.required(),
        rules.counterMaxEqual(128),
      ]
    },
    {
      label: vm.$t('staffs.prefs.beneficiary_account.form.last_name'),
      key: 'last_name',
      type: FieldType.TEXT,
      rules: [
        rules.required(),
        rules.counterMaxEqual(128),
      ]
    },
    {
      label: vm.$t('staffs.prefs.beneficiary_account.form.account_id'),
      key: 'account_id',
      type: FieldType.TEXT,
      rules: [
        rules.required(),
        rules.counterMaxEqual(128),
      ]
    },
    {
      label: vm.$t('staffs.prefs.beneficiary_account.form.bank_id'),
      key: 'bank_id',
      type: FieldType.TEXT,
      rules: [
        rules.required(),
        rules.counterMaxEqual(128),
      ]
    },
    {
      label: vm.$t('staffs.prefs.beneficiary_account.form.country'),
      key: 'country',
      type: FieldType.COUNTRY_SELECT,
      rules: [
        rules.required(),
      ]
    },
  ]
}