<template>
  <div>
    <notifications group="info" position="bottom right" width="400px">
      <template slot="body" slot-scope="props">
        <v-alert class="mr-3 mt-3" type="info" color="info" dismissible border="left">
          <div class="subtitle-2 mt-0" v-html="props.item.title">

          </div>
          <div class="caption" v-html="props.item.text">

          </div>
          <div v-if="props.item.data && props.item.data.buttons">
            <v-divider></v-divider>
            <div class="d-flex flex-column align-end mt-1">
              <v-btn small dark text
                     v-for="(b, ind) in props.item.data.buttons" :key="ind"
                     @click="()=>{b.click(); props.close();}"
              >
                {{ b.text }}
              </v-btn>
            </div>
          </div>
        </v-alert>
      </template>
    </notifications>
    <notifications group="success" position="bottom right" width="400px">
      <template slot="body" slot-scope="props">
        <v-alert class="mr-3 mt-3" type="success"
                 color="#28c76f"
                 transition="scroll-x-transition" dismissible border="left">
          <div class="subtitle-2 mt-0">
            {{ $t('common.success') }}
          </div>
          <div class="caption" v-html="props.item.text">

          </div>
          <div v-if="props.item.data && props.item.data.buttons">
            <v-divider></v-divider>
            <div class="d-flex flex-column align-end mt-1">
              <v-btn small dark text
                     v-for="(b, ind) in props.item.data.buttons" :key="ind"
                     @click="()=>{b.click(); props.close();}"
              >
                {{ b.text }}
              </v-btn>
            </div>
          </div>
        </v-alert>
      </template>
    </notifications>
    <notifications group="success_toast" position="bottom center" width="200px">
      <template slot="body" slot-scope="props">
        <v-alert class="mt-3"
                 transition="scroll-y-transition" dismissible>
          <div class="caption" v-html="props.item.text">

          </div>
          <div v-if="props.item.data && props.item.data.buttons">
            <v-divider></v-divider>
            <div class="d-flex flex-column align-end mt-1">
              <v-btn small dark text
                     v-for="(b, ind) in props.item.data.buttons" :key="ind"
                     @click="()=>{b.click(); props.close();}"
              >
                {{ b.text }}
              </v-btn>
            </div>
          </div>
        </v-alert>
      </template>
    </notifications>
    <notifications group="errors" position="bottom right" width="400px">
      <template slot="body" slot-scope="props">
        <v-alert class="mr-3 mt-3 animated slideInRight"
                 type="error" dismissible>
          <!--<v-card class="mr-3 mt-3" color="error" dark>-->
          <div class="subtitle-2 mt-0">
            {{ props.item.title }}
          </div>
          <div class="body-2" v-if="props.item.data && props.item.data.help">
            {{ props.item.data.help }}
          </div>
          <div v-if="props.item.data && props.item.data.validation"
               :set="errs = props.item.data.validation">
            <div v-for="(e, ind) in errs" :key="`ef__${ind}`">
              <div>{{ e.name }}</div>
              <div class="ml-3 caption"
                   v-for="(description, di) in e.messages"
                   :key="`ed__${ind}_${di}`">
                - {{ description }}
              </div>
            </div>
          </div>
          <div v-if="props.item.data && props.item.data.details"
               :set="err = props.item.data.details">
            <div>{{ err }}</div>
          </div>
          <div class="caption">
            {{ props.item.text }}
          </div>
          <div v-if="props.item.data && props.item.data.buttons">
            <v-divider></v-divider>
            <div class="d-flex flex-column align-end mt-1">
              <v-btn small dark text
                     v-for="(b, ind) in props.item.data.buttons" :key="ind"
                     @click="()=>{b.click(); props.close();}"
              >
                {{ b.text }}
              </v-btn>
            </div>
          </div>
        </v-alert>
        <!--</v-card>-->
      </template>
    </notifications>
    <notifications group="new" position="bottom right" width="400px">
      <template slot="body" slot-scope="props">
        <v-alert class="mr-3" type="info" dismissible border="left">
          <div class="subtitle-2 mt-0">
            {{ props.item.title }}
          </div>
          <div class="caption">
            {{ props.item.text }}
          </div>
          <div v-if="props.item.data && props.item.data.buttons">
            <v-divider></v-divider>
            <div class="d-flex flex-column align-end mt-1">
              <v-btn small dark text
                     v-for="(b, ind) in props.item.data.buttons" :key="ind"
                     @click="()=>{b.click(); props.close();}"
              >
                {{ b.text }}
              </v-btn>
            </div>
          </div>
        </v-alert>
      </template>
    </notifications>
  </div>
</template>

<script>
export default {
  name: "NotificationsHolder"
}
</script>

<style scoped>

</style>
