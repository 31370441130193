<template>
  <v-card
      class="mx-3" max-width="400" min-width="min(90%, 400px)"
      color="transparent" flat
  >
    <v-card-title class="pb-5">
      <app-logo with-text/>
    </v-card-title>
    <v-card-subtitle class="text-h4">
      {{ $t('signIn.title') }}
    </v-card-subtitle>
    <v-card-text class="mt-3">
      <v-form @submit.prevent="submitForm" v-model="isValid">
        <button style="display: none" type="submit"></button>
        <!--      <v-slide-x-transition-->
        <!--          leave-absolute-->
        <!--          mode="out-in" group>-->
        <div key="loginInput">
          <v-scroll-y-transition mode="out-in" leave-absolute group>
            <div :key="payload.login_field" style="height: 70px">
              <form-input-element
                  :disabled="!!authType"
                  hide-details
                  :field="inputFieldBind" v-model="payload.login"/>
            </div>
          </v-scroll-y-transition>
          <v-slide-x-transition
              leave-absolute
              mode="out-in" group>
            <div class="mb-3 text-end" key="switchLoginField">
              <a href="javascript:void(0)"
                 v-if="!authType"
                 class="text--secondary"
                 @click="switchLoginField">
                <span>{{ loginSwitchBtnText }}</span>
              </a>
              <div v-else key="passInput">
                <form-input-element
                    hide-details
                    :field="passFieldBind" v-model="authData.secret"/>
                <div class="mt-3 text-end">
                  <a href="javascript:void(0)"
                     class="text--secondary"
                     @click="authType = undefined">
                    <span>{{ $t('common.cancel') }}</span>
                  </a>
                  <br>
                  <forgot-password-dialog :field="payload.login_field" :login="getStringLoginValue()"/>
                </div>
              </div>
            </div>
          </v-slide-x-transition>

        </div>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <app-button icon="ri-login-circle-line" @click="submitForm" :loading="loadingStatus$.loading">
        {{ $t('userActions.signIn') }}
      </app-button>
      <!--      <v-btn large class="rounded-pill px-3"-->
      <!--             min-width="200" light-->
      <!--             @click="submitForm"-->
      <!--             :loading="loadingStatus$.loading"-->
      <!--      >-->
      <!--        {{ $t('userActions.signIn') }}-->
      <!--      </v-btn>-->
      <v-spacer/>
    </v-card-actions>
    <div class="d-flex flex-column align-center justify-center mt-9">
      <v-btn large rounded text
             class="mt-1 px-6"
             :to="btn.to"
             v-for="(btn, ind) in regButtons" :key="`reg_b__${ind}`"
      >
        <v-icon :color="btn.color" left>{{ btn.icon }}</v-icon>
        {{ btn.label }}
      </v-btn>
    </div>
    <v-dialog v-model="userNotVerifiedDialog" max-width="400" light>
      <v-card>
        <v-card-title>
          {{ $t('signIn.accountIsNotVerifiedTitle') }}
        </v-card-title>
        <v-card-text>
          <div>{{ $t('signIn.accountIsNotVerified') }}</div>
          <form-input-element
              disabled hide-details class="mt-3"
              :field="inputFieldBind" v-model="payload.login"/>
        </v-card-text>
        <div class="d-flex flex-column pa-3">
          <v-btn block color="primary" @click="manualCheckClick('person')">
            {{ $t('signIn.personAccountIsNotVerifiedAction') }}
          </v-btn>
          <div class="py-1"></div>
          <v-btn block color="primary" @click="manualCheckClick('company')">
            {{ $t('signIn.companyAccountIsNotVerifiedAction') }}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <!--    <div class="pa-3 mt-6 text&#45;&#45;secondary text-center"-->
    <!--         ref="regText" v-html="$t('signIn.signUpMessage')"></div>-->
  </v-card>
</template>

<script>

import FormInputElement from "@/components/common/forms/FormInputElement";
import {FieldType} from "@/forms/fieldTypes";
import {loadingMixin, rulesMixin} from "@/mixins/common";
import endpoints from "@/utils/web/endpoints";
import {preparePhone} from "@/utils/forms";
import {getSignInRedirectRouter} from "@/helpers/router_utils";
import AppLogo from "@/components/common/widgets/AppLogo";
import AppButton from "@/components/common/widgets/AppButton";
import ForgotPasswordDialog from "@/components/common/widgets/ForgotPasswordDialog";

export default {
  name: "SignInView",
  components: {ForgotPasswordDialog, AppButton, AppLogo, FormInputElement},
  mixins: [rulesMixin, loadingMixin],
  computed: {
    regButtons: function () {
      return [
        // {
        //   label: this.$t('signIn.signUpPerson'),
        //   disabled: false,
        //   icon: 'ri-user-line',
        //   color: 'primary',
        //   to: {name: 'person-reg'},
        // },
        // {
        //   label: this.$t('signIn.signUpCompany'),
        //   disabled: false,
        //   icon: 'ri-group-line',
        //   color: 'warning',
        //   to: {name: 'company-reg'},
        // }
      ]
    },
    nextFieldKey: function () {
      return (this.payload.login_field === 'email') ?
          'phone' : 'email'
    },
    loginSwitchBtnText: function () {
      return this.$t(
          `signIn.loginVia`,
          {field: this.$t(`signIn.${this.nextFieldKey}`).toLowerCase()}
      )
    },
    inputFieldBind: function () {
      return this.payload.login_field === 'phone' ? {
        label: this.$t('signIn.phone'),
        key: 'login',
        type: FieldType.PHONE,
        rules: [
          this.inputValidationRules.required(),
          this.inputValidationRules.counterMaxEqual(64)
        ]
      } : {
        label: this.$t('signIn.email'),
        key: 'login',
        type: FieldType.TEXT,
        rules: [
          this.inputValidationRules.required(),
          this.inputValidationRules.email(),
          this.inputValidationRules.counterMaxEqual(64)
        ]
      }
    },
    passFieldBind: function () {
      return this.authType === 'password' ? {
        label: this.$t('signIn.password'),
        key: 'password',
        type: FieldType.TEXT,
        password: true,
        rules: [
          this.inputValidationRules.required(),
          this.inputValidationRules.counterMaxEqual(64)
        ]
      } : {
        label: this.$t('signIn.otp'),
        key: 'otp',
        type: FieldType.OTP,
        rules: [
          this.inputValidationRules.required(),
          // this.inputValidationRules.email(),
          // this.inputValidationRules.counterMaxEqual(64)
        ]
      }
    }
  },
  data() {
    return {
      payload: {
        login: '',
        login_field: 'email'
      },
      isValid: false,
      authData: undefined,
      authType: undefined,
      userNotVerifiedDialog: false
    }
  },
  methods: {
    manualCheckClick(userType) {
      this.$http.post(
          userType === 'person' ? endpoints.PERSONS().SIGNUP_VERIFY : endpoints.COMPANIES().SIGNUP_VERIFY,
          // endpoints.USERS().MANUAL_CHECK,
          this.authData,
          {changeState: true, formValidationError: true, rootProgress: true}
      ).then(respData => {
        console.log(respData)
        this.userNotVerifiedDialog = false
        this.$router.replace({name: `${userType}-reg`, query: {crvi: respData.crvi_id}})
        // console.log(resp)
      })
    },
    submitForm() {
      if (this.authType) {
        this.signInUser()
      } else {
        this.getSignInType()
      }
    },
    switchLoginField() {
      this.payload.login_field = this.nextFieldKey
    },
    getStringLoginValue() {
      return this.payload.login_field === 'phone' ? preparePhone(this.payload.login) : this.payload.login
    },
    preparePayload() {
      return {
        [this.payload.login_field]: this.getStringLoginValue(),
        // secret: ''
      }
    },
    getSignInType() {
      if (!this.payload.login) {
        return
      }
      this.authData = this.preparePayload()
      this.$http.post(
          endpoints.USERS().SIGNIN_TYPE,
          this.authData,
          {changeState: true, formValidationError: true, rootProgress: true}
      ).then(respData => {
        this.authType = respData.auth_type
        // console.log(resp)
      })
    },
    signInUser() {
      // this.authData = this.preparePayload()
      this.$http.post(
          endpoints.USERS().SIGNIN,
          this.authData,
          {
            changeState: true,
            anonymous: true,
          }
      ).then(respData => {
        this.$store.commit("setAuthData", respData)
        this.$emit('signin-success')
        window.$ws.create()
        let nextRoute = getSignInRedirectRouter(respData)
        // this.authType = respData.auth_type
        console.log(respData)
        if (nextRoute) {
          this.$router.replace(nextRoute)
        }
      }).catch(e => {
        if (e?.response?.data?.code === "check_err") {
          this.userNotVerifiedDialog = true
        }
      })
    }
  },
  // mounted() {
  //   let regLink = this.$refs.regText?.getElementsByTagName('a')[0]
  //   regLink.onclick = (e) => {
  //     e.preventDefault()
  //     this.$router.push({name: 'reg'})
  //   }
  // }
}
</script>

<style scoped>

</style>