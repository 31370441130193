<template>
  <page-wrapper>
    <v-container style="padding-top: 5%">
      <div class="d-flex align-center justify-space-between">
        <div class="text-h3">
          {{ $t('client.menu.history') }}
        </div>
        <list-export-dialog :show-filter-variants="false" @on-export="onDataExportClick"/>
      </div>
      <transaction-history-list-view ref="historyList"/>
    </v-container>
  </page-wrapper>
</template>

<script>
import PageWrapper from "@/components/common/widgets/PageWrapper.vue";
import TransactionHistoryListView from "@/components/client/widgets/history/TransactionHistoryListView.vue";
import ListExportDialog from "@/components/common/widgets/ListExportDialog.vue";

export default {
  name: "ClientTransactionsHistoryPage",
  components: {ListExportDialog, TransactionHistoryListView, PageWrapper},
  methods: {
    onDataExportClick(exportParams) {
      this.$refs?.historyList.exportData(exportParams)
    }
  }
}
</script>

<style scoped>

</style>