import {appConfig} from "@/config";

export class AppWebSocket {
  _socket
  config = undefined;
  app;
  bus;

  constructor(app, bus = undefined, config = undefined) {
    if (window.$ws) {
      console.log("Socket already created")
      return
    }
    this.app = app;
    this.bus = bus || app.$bus;
    this.config = config || appConfig

    Object.defineProperty(window, '$ws', {
      writable: false,
      value: this
    })
  }

  create() {
    let token = this.app.$store.getters.getAuthData?.access
    if (!token) {
      console.log("No token. Skipping ws connection")
      return
    }
    try {
      this._socket = new WebSocket(
        `${this.config.wsProto}://${this.config.wsBaseHost}/ws/${token}/common/`
      )
      this._socket.onmessage = (content) => {
        // console.log(content)
        this.onMessage.call(this, JSON.parse(content.data))
      }
      this._socket.onclose = this.onClose
      this._socket.onopen = this.onOpen
    } catch (e) {
      console.log(new Date(), e)
    }
  }

  close() {
    this._socket.close()
  }

  send(data) {
    // console.log("SEND DATA:", data)
    if (typeof data === 'object') {
      this._socket.send(JSON.stringify(data))
    } else {
      this._socket.send(data)
    }
  }

  onMessage(content) {
    // console.log("WS MESSAGE", content)
    // console.log("BUS", this.bus)
    this.bus?.$emit('ws-message', content)
    this.bus?.$emit(`ws-${content.type}`, content)
    // this.send(JSON.stringify({"msg": "I'm here"}))
  }

  onClose(err) {
    // console.log(new Date(), "CLOSE")
    console.log(err)
    console.log("Reconnecting...")
    setTimeout(() => {
      window.$ws.create()
      // this.create()
    }, 1000)
  }

  onOpen() {
    console.log(new Date(), "OPEN")
  }
}