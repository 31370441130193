import {FieldType} from "@/forms/fieldTypes";
import RULES from "@/plugins/rules";

export function newPasswordFieldsForm(vm, modelVar = 'input') {
  let rules = RULES(vm)
  return [
    {
      label: vm.$t('signIn.password'),
      key: 'password',
      type: FieldType.TEXT,
      password: true,
      hint: vm.$t('signIn.passwordHint'),
      rules: [
        rules.required(),
        rules.engNumSpecialOnly(),
        rules.counterMaxEqual(64),
        rules.counterMinEqual(8),
      ]
    },
    {
      label: vm.$t('signIn.passwordRepeat'),
      key: 'r_password',
      skip: true,
      type: FieldType.TEXT,
      password: true,
      rules: [
        (value) => value === vm.$data[modelVar].password || vm.$t('rules.passwordRepeatNotEqual')
      ]
    }
  ]
}

// eslint-disable-next-line no-unused-vars
export function captchaFields(vm, modelVar = 'input') {
  let rules = RULES(vm)
  return [
    {
      label: vm.$t('signIn.password'),
      key: 'captcha',
      type: FieldType.CAPTCHA,
      password: true,
      hint: vm.$t('signIn.passwordHint'),
      rules: [
        rules.required(),
      ]
    },
  ]
}

export function resetPasswordForm(vm, modelVar = 'input') {
  return [
    ...newPasswordFieldsForm(vm, modelVar),
    ...captchaFields(vm, modelVar)
  ]
}