<template>
  <div>
    <v-form v-if="form" v-model="mIsValid">
<!--          class="animated delayed-appear fadeIn text&#45;&#45;primary"-->
      <div
          class="animated delayed-appear fadeIn"
          :class="{'white--text': $vuetify.theme.dark, 'black--text':!$vuetify.theme.dark}"
          v-for="(group, groupIndex) in form.form"
          :key="`group__${groupIndex}`"
      >
        <div class="pb-6" v-if="group.label">
          <h4 class="text-h4">
            <span>{{ group.label }}</span>
            <form-field-long-hint
                btn-class="ml-1"
                v-if="group.longHint"
                :hint-content="group.longHint"
            />
          </h4>
        </div>
        <form-input-element
            :class="animationClass"
            v-for="(field, fieldIndex) in group.form" :key="`field__${groupIndex}_${fieldIndex}`"
            v-model="input[field.key]"
            :all-data="input"
            :all-form-data="allFormData"
            :field="field"
        />
      </div>
    </v-form>
    <div v-else>
      \/\/ NO FORM
    </div>
  </div>

</template>

<script>
import FormInputElement from "@/components/common/forms/FormInputElement";
import {formInputMixin, syncPropMixin} from "@/mixins/common";
import FormFieldLongHint from "@/components/common/widgets/FormFieldLongHint";

export default {
  name: "GenericForm",
  components: {FormFieldLongHint, FormInputElement},
  mixins: [
      formInputMixin,
      syncPropMixin('mIsValid', 'isValid', Boolean, false)
  ],
  props: {
    form: {
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
      // isValid: false
    }
  }
}
</script>

<style scoped>

</style>