<template>
  <div class="d-flex align-center">
    <div style="position:relative;">
      <v-icon size="16"
              v-if="staff"
              style="position: absolute; bottom: 0; left: 32px">
        ri-settings-3-line
      </v-icon>
      <v-img
          v-if="withText"
          :max-width="4 * size" :max-height="size"
          :src="getPublicImg('icons/logo.svg')">

      </v-img>
      <v-img v-else
          :max-width="size" :max-height="size"
          :src="getPublicImg('icons/logo_icon.svg')">

      </v-img>
    </div>
<!--    <v-toolbar-title v-if="withText" class="pl-3">-->
<!--      {{ appName }}-->
<!--    </v-toolbar-title>-->
    <!--    <div class="text-h5 pl-3" v-if="withText">-->
    <!--      {{ appName }}-->
    <!--    </div>-->
  </div>
</template>

<script>
import {publicAccessMixin} from "@/mixins/common";

import {loadEnvVar} from "@/envLoader"

export default {
  name: "AppLogo",
  mixins: [
    publicAccessMixin
  ],
  props: {
    staff: {
      type: Boolean,
      default: false
    },
    withText: {
      type: Boolean,
      default: false
    },
    // width: {
    //   type: [Number, String],
    //   default: "50"
    // },
    size: {
      type: [Number, String],
      default: "50"
    },
  },
  computed: {
    appName: function () {
      // return process.env.VUE_APP_
      return loadEnvVar("DOCUMENT_TITLE", undefined)
    }
  }
}
</script>

<style scoped>

</style>