<template>
  <v-container style="padding-top: 5%">
    <div class="text-h3">
      {{ $t(isEditMode ? 'client.receivers.edit' : 'client.receivers.new') }}
    </div>
    <v-sheet max-width="500" color="transparent" class="mt-6">
      <receiver-detail-form
          :receiver-id="receiverId"
          :is-edit-mode="isEditMode"
          @submit="onReceiverSubmit"
      />
    </v-sheet>
  </v-container>
</template>

<script>
import ReceiverDetailForm from "@/components/client/forms/ReceiverDetailForm";
import endpoints from "@/utils/web/endpoints";

export default {
  name: "ClientAddReceiverPage",
  components: {ReceiverDetailForm},
  computed: {
    receiverId: function () {
      return this.$route.params?.id
    },
    isEditMode: function () {
      return this.$route.name === 'edit_receiver'
    }
  },
  methods: {
    addNew(receiver) {
      this.$http.post(
          endpoints.TRANSFER().RECEIVERS().ADD,
          receiver,
          {formValidationError: true}
      ).then(resp => {
        this.$emit('on-add', resp.id, receiver)
        this.$notify({
          group: 'success',
          text: this.$t('client.receivers.successfully_created')
        })
        this.$router.push({name: "receivers"})
      })
    },
    saveReceiver(receiverId, receiver) {
      this.$http.post(
          endpoints.TRANSFER().RECEIVERS().EDIT(receiverId),
          receiver,
          {formValidationError: true}
      ).then(resp => {
        this.$emit('on-edit', resp.id, receiver)
        this.$notify({
          group: 'success',
          text: this.$t('client.receivers.successfully_saved')
        })
        this.$router.push({name: "receivers"})
      })
    },
    onReceiverSubmit(receiver) {
      // console.log(receiver)
      if (this.isEditMode) {
        this.saveReceiver(this.receiverId, receiver)
      } else {
        this.addNew(receiver)
      }
    }

  }
}
</script>

<style scoped>

</style>