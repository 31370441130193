<template>
  <div class="fh fw d-flex align-center">
    <div v-if="userInfo" class="d-flex flex-column align-center justify-center fw">
      <div class="text-h4 pb-1 animated delayed-appear fadeIn">{{ $t('signIn.registrationIncomplete') }}</div>
      <div class="text-h5 pb-6 animated delayed-appear fadeIn">
        {{ $t('signIn.registrationIncompleteStart') }}
      </div>
      <div class="fw">
        <v-stepper alt-labels color="transparent"
                   :value="currentStepIndex + 1"
                   vertical
                   style="background: transparent !important;">
          <v-stepper-header class="align-center justify-center">
            <v-stepper-step
                class="animated delayed-appear fadeIn"
                v-for="(step, ind) in registrationSteps"
                :key="`step__${ind}`"
                :step="ind + 1"
                :color="ind === currentStepIndex ? 'warning' : undefined"
                :complete="ind < currentStepIndex"
            >
              {{ getLabelByKey(step.key) }}
            </v-stepper-step>

          </v-stepper-header>
        </v-stepper>
      </div>
      <app-button
          @click="execStepAction(currentStep.action)"
          v-if="currentStep && currentStep.action"
          class="animated delayed-appear fadeInUp"
          icon="ri-arrow-right-circle-line"
      >
        {{ currentStep.btnLabel || this.$t('userActions.goTo') }}
      </app-button>

    </div>
  </div>
</template>

<script>
import AppButton from "@/components/common/widgets/AppButton";

export default {
  name: "ClientRegistrationIncompletePage",
  components: {AppButton},
  computed: {
    companySteps: function () {
      return [
        {
          key: '#initial_reg_complete',
          btnLabel: undefined,
        },
        {
          key: "full_reg_filled",
          btnLabel: undefined,
          action: 'rc_toDocGeneration'
        },
        {
          key: "full_reg_doc_generated",
          btnLabel: undefined,
          action: 'rc_toDocDownloadAndSign'
        },
        {
          key: "full_reg_doc_signed",
          btnLabel: undefined,
          action: 'rc_toDocDownloadAndSign'
        },
        {
          key: "is_staff_approved",
          btnLabel: undefined,
        }
      ]
    },
    personSteps: function () {
      return [
        {
          key: '#initial_reg_complete',
          btnLabel: undefined,
        },
        {
          key: "full_reg_filled",
          btnLabel: undefined,
          action: 'pc_toFullReg'
        },
        {
          key: "is_sumsub_approved",
          btnLabel: undefined,
          action: 'pc_VerifySumsub'
        },
      ]
    },
    registrationSteps: function () {
      switch (this.userInfo?.user_cls) {
        case 'person':
          return this.personSteps
        case 'company':
          return this.companySteps
      }
      return []
    },
    currentStep: function () {
      return this.registrationSteps[this.currentStepIndex]
    },
    currentStepIndex: function () {
      return this.getCurrentRegistrationProgress(
          this.registrationSteps,
          this.userInfo.extra
      )
    }
  },
  props: {
    userInfo: {
      type: Object
    }
  },
  methods: {
    getLabelByKey(key) {
      let rootTranslateKey = undefined
      switch (this.userInfo.user_cls) {
        case "company":
          rootTranslateKey = "staffs.company."
          break
        case "person":
          rootTranslateKey = "staffs.person."
          break
      }
      if (rootTranslateKey !== undefined)
        return this.$t(rootTranslateKey + key)
      return key
    },
    getCurrentRegistrationProgress(steps, data) {
      let progress = 0
      steps.forEach(field => {
        if (field.key.startsWith('#') || data[field.key]) {
          progress++
        }
      })
      return progress
    },
    execStepAction(action) {
      let func = this[action]
      if (func) {
        func.call(this)
      }
    },
    rc_toDocGeneration() {
      this.$router.push({name: "company-regfull"})
    },
    pc_VerifySumsub() {
      this.$router.push({name: 'person-regfull_sumsub'})
    },
    pc_toFullReg() {
      this.$router.push({name: "person-regfull"})
    },
    rc_toDocDownloadAndSign() {
      this.$router.push({name: "company-regfull_docs"})
    }
  }
}
</script>

<style scoped>

</style>