<template>
  <v-list-item class="d-flex" :disabled="!active">
    <v-list-item-content>
      <v-list-item-title class="d-flex align-center">
        <div class="mr-3">{{ label }}</div>
        <v-scale-transition mode="out-in" origin="center center 0">
          <v-icon color="primary" size="24" v-if="value">
            ri-checkbox-circle-line
          </v-icon>
        </v-scale-transition>
      </v-list-item-title>
    </v-list-item-content>
    <!--    <v-list-item-avatar>-->
    <!--      -->
    <!--    </v-list-item-avatar>-->
    <!--    <div class="mr-3" :class="{'text-inactive': !active}">-->
    <!--      {{ label }}-->
    <!--    </div>-->

  </v-list-item>
</template>

<script>
export default {
  name: "RegistrationStepProgressItemView",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String
    }
  }
}
</script>

<style scoped>

</style>