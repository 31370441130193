<template>
  <div>
    <div v-if="showTitle">{{ title }}</div>
    <slot></slot>
    <div class="d-flex align-center flex-wrap flex-row-reverse pt-6" v-if="showButtons">
      <v-btn color="warning" outlined @click="onSave" :loading="loadingStatus.loading">
        <v-icon left>ri-save-line</v-icon>
        {{ saveButtonText || $t('userActions.save') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import {prefsWindowItemMixin} from "@/mixins/staffPrefsMixins";

export default {
  name: "PreferenceWindowWrapper",
  mixins: [
    prefsWindowItemMixin
  ],
  props: {
    loadingStatus: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    onSave() {
      this.$emit('on-save')
    }
  }
}
</script>

<style scoped>

</style>