<template>
  <page-wrapper>
    <template v-if="loadingStatus$.loaded">
      <v-container v-if="hasPayments" style="padding-top: 10%">
        <div class="text-h3">
          {{ $t('home.welcome') }}
        </div>
<!--        <app-button-->
<!--            @click="$router.push({name: 'send_money'})"-->
<!--            :text="$t('home.transferAction')"-->
<!--            icon="ri-share-circle-line"/>-->
        <div class="mt-12">
          <div class="text-h4">
            {{ $t('home.lastTransfers') }}
          </div>
          <transaction-history-item-view
              class="mb-1"
              v-for="(item, ind) in homeData.last_payments"
              :key="`item__${ind}`"
              :item="item"
          />
        </div>
      </v-container>
      <v-container style="padding-top: 10%" v-else>
        <div class="text-h3">
          {{ $t('home.welcome') }}
        </div>
        <div class="pt-6 pb-12">
          <div class="text-h4">
            {{ $t('home.areYouReady') }}
          </div>
          <div>
            {{ $t('home.areYouReadyHint') }}
          </div>
        </div>
<!--        <app-button-->
<!--            @click="$router.push({name: 'send_money'})"-->
<!--            :text="$t('home.transferAction')"-->
<!--            icon="ri-share-circle-line"/>-->
      </v-container>
    </template>
  </page-wrapper>
</template>

<script>
import PageWrapper from "@/components/common/widgets/PageWrapper";
import endpoints from "@/utils/web/endpoints";
import {loadingMixin} from "@/mixins/common";
import TransactionHistoryItemView from "@/components/client/widgets/history/TransactionHistoryItemView.vue";

export default {
  name: "ClientHomePage",
  components: {TransactionHistoryItemView, PageWrapper},
  mixins: [loadingMixin],
  data() {
    return {
      btnDisabled: false,
      homeData: undefined
    }
  },
  computed: {
    hasPayments: function () {
      return (this.homeData?.last_payments || []).length > 0
    }
  },
  methods: {
    getData() {
      return this.$http.get(
          endpoints.UI().HOME().GET_DATA,
          {
            changeState: true
          }
      )
    }
  },
  async created() {
    this.homeData = await this.getData()
  }
}
</script>

<style scoped>

</style>