<template>
  <v-input v-bind="$bindField" v-model="input"
           class="v-input__block radio-group__input">
    <template v-slot:label v-if="$bindField.label">
      <div class="d-flex align-center">
        <h5 class="text-h5 pr-1">{{ $bindField.label }}</h5>
        <slot name="hint-holder"></slot>
      </div>
    </template>
    <div>
      <v-input v-model="input" class="mt-3" v-bind="bindDisabled">
        <div>
          <div v-for="(item, index) in visibleItems"
               class="mb-3"
               :key="`item__${index}`">
            <app-radio-button
                :label="item.text"
                :r-value="item.value"
                v-bind="bindDisabled"
                v-model="input"
            >
            </app-radio-button>
          </div>
        </div>
      </v-input>
    </div>
    <!--    <v-radio-group v-model="input">-->
    <!--      <v-radio-->
    <!--          v-for="(item, index) in visibleItems"-->
    <!--          :key="`item__${index}`"-->
    <!--          :label="item.text"-->
    <!--          :value="item.value"-->
    <!--      >-->

    <!--      </v-radio>-->
    <!--    </v-radio-group>-->
  </v-input>
</template>

<script>
import {selectInputMixin} from "@/mixins/common";
import AppRadioButton from "@/components/common/forms/input_override/AppRadioButton";

export default {
  name: "GenSelectRadioField",
  components: {AppRadioButton},
  mixins: [selectInputMixin],
}
</script>

<style scoped>

</style>