<template>
  <div>
    <v-app>
      <v-expand-transition>
        <v-progress-linear
            style="z-index: 100"
            height="8"
            fixed
            v-if="isProgressVisible"
            :indeterminate="true"
        />
      </v-expand-transition>
      <v-expand-transition>
        <pwa-install-view v-if="showInstallTip"/>
      </v-expand-transition>
      <v-scroll-x-transition mode="out-in">
        <router-view/>
      </v-scroll-x-transition>
      <notifications-holder/>
    </v-app>
  </div>
</template>

<script>

import NotificationsHolder from "@/components/common/widgets/NotificationHolder";
import {AppWebSocket} from "@/plugins/websocket";
import PwaInstallView from "@/components/common/widgets/PwaInstallView";
import {getFbqScriptContent, getGTagScriptContent, insertScript} from "@/utils/metricts";
import endpoints from "@/utils/web/endpoints";

export default {
  name: 'App',
  components: {PwaInstallView, NotificationsHolder},
  computed: {
    isProgressVisible: function () {
      let value = this.$tmpStore.getters.getProgressLoader
      return !!value
    },
    showInstallTip: function () {
      // return true
      return this.$store.getters.getShowInstallPwaTip &&
          !this.$bus.appUsed &&
          this.$bus.isDeviceSupportPwaInstall()
    }
  },
  data() {
    return {
      deferredPrompt: undefined
    }
  },
  methods: {},
  created() {
    new AppWebSocket(this, this.$bus).create()
    this.$http.get(
        endpoints.UI().METRICS().GET
    ).then(resp => {
      console.log("Active metrics", resp)
      this.$tmpStore.commit('setMetrics', resp)
      let fbq_code = resp.fbq_code
      if (fbq_code) insertScript(getFbqScriptContent(fbq_code))
      else console.warn('FBQ metric skipped')

      let gtag_code = resp.gtag_code
      if (gtag_code) insertScript(getGTagScriptContent(gtag_code.split('/')[0]))
      else console.warn('GTag metric skipped')

      let m = this.$tmpStore.getters.getMetrics.gtag_code
      console.log(m)
    })
  }
};
</script>
