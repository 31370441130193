<template>
  <!--  <v-input v-bind="$props" v-model="input" class="m-checkbox pb-3">-->
  <label class="m-radio__label pt-3 d-flex align-center">
    <v-avatar :color="$vuetify.theme.dark ? 'gray-dark' : 'grey lighten-2'" size="32">
      <v-scale-transition mode="out-in" origin="center center 0">
        <v-avatar size="20" color="primary darken-1" v-if="input === rValue">

        </v-avatar>
      </v-scale-transition>
    </v-avatar>
    <span class="ml-3">{{ label }}</span>
    <input style="display: none" type="radio" :value="rValue" v-model="input" :disabled="disabled">
  </label>
  <!--  </v-input>-->
</template>

<script>
import {modelMixin} from "@/mixins/common";

export default {
  name: "AppRadioButton",
  mixins: [modelMixin('input', undefined, undefined)],
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: undefined
    },
    rValue: {}
  }
}
</script>

<style scoped>

</style>