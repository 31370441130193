import {FieldType} from "@/forms/fieldTypes";

export function prepareFormDataAsync(form, data) {
  return new Promise(resolve => {
    resolve(prepareFormData(form, data))
  })
}

/**
 * wow
 * @param form{{form: any, key:string}[]}: Array of form items
 * @param data
 * @returns {{}}
 */
export function prepareFormData(form, data) {
  let resultData = {}
  // iterate groups
  for (let i = 0; i < form.length; i++) {
    let groupForm = form[i]
    for (let j = 0; j < groupForm.form.length; j++) {
      let field = groupForm.form[j]
      if (field?.skip) {
        continue
      }
      switch (field.type) {
        case FieldType.PHONE:
          resultData[field.key] = preparePhone(data[field.key])
          break
        case FieldType.OBJECT_LIST:
          resultData[field.key] = _prepareObjectList(data[field.key], field.extra?.form)
          break
        case FieldType.ADDRESS:
          resultData[field.key] = _prepareAddressObject(data[field.key])
          break
        case FieldType.COUNTRY_SELECT:
          resultData[field.key] = extractId(data[field.key])
          break
        case FieldType.CURRENCY:
          resultData[field.key] = extractId(data[field.key])
          break
        case FieldType.FILE_BASE_64:
          resultData[field.key] = _prepareFileData(data[field.key], field.extra?.form)
          break
        default:
          resultData[field.key] = data[field.key]
          break
      }

    }
  }
  return resultData
}

export function preparePhone(value) {
  if (!value) return null
  return `+${value.pc}${value.pn}`
}

function _prepareSingleFileData(value) {
  if (value.tag) {
    return {
      document: value.id,
      document_type: value.tag
    }
  }
  return extractId(value)
}

// eslint-disable-next-line no-unused-vars
function _prepareFileData(value, form) {
  // console.log(form)
  if (!value) return null
  if (Array.isArray(value)) {
    // let res = []
    // for (let i = 0; i < value.length; i++) {
    //   res.push(_prepareSingleFileData(value[i]))
    // }
    return value.map(_prepareSingleFileData)
  }
  return _prepareSingleFileData(value)
}

export function extractId(value) {
  if (!value) return null
  return value?.id
}

export function _prepareAddressObject(value) {
  if (!value) return null
  return {
    city: value.city?.id,
    post_code: value.post_code,
    address: value.address
  }
}

function _prepareObjectList(value, form) {
  if (!value || !Array.isArray(value)) return null
  let res = [];
  for (let i = 0; i < value.length; i++) {
    let item = prepareFormData(form, value[i])
    res.push(item)
  }
  return res
}