import axios from "axios";
import {appConfig} from "@/config";

const FileSaver = require('@/utils/web/fileSaver')
// import consts from "@/consts";
// import {deepCopy} from "@/utils/jsUtils";

export const axiosInstance = axios.create({
  // timeout: 5000,
});

//eslint-disable-next-line
const LIMIT_KEY = "limit"
//eslint-disable-next-line
const OFFSET_KEY = "offset"

const NDM = ['get', 'delete'];
const defaultOptions = {
  showNotification: true,
  showErrorNotification: true,
  changeState: false,
  cleanUrl: false,
  changeStateError: true,
  headers: {},
  updatePagination: false,
  formValidationError: true,
  formErrors: null,
  genForm: undefined,
  first: true,
  limit: 30,
  loginRedirect: true,
  progress: true,
  rootProgress: true,
  downloadFile: false,
  downloadFileName: undefined,
  hidePageLoader: false,
  redirectNotFound: true,
  onProgressChanged: null,
  redirectForbidden: false
  // contentType: ''
};

export class HttpProvider {
  ctx
  inst

  constructor(inst, ctx) {
    this.inst = inst;
    this.ctx = ctx;
  }

  getFileName(resp) {
    let cd = resp.headers['content-disposition']
    if (!cd) return undefined
    // return res.slice(0, res.length - 1)
    return cd.replace("attachment; filename*=UTF-8''", "")
    //"attachment; filename="asdfg.pdf""
  }

  saveFile(resp, options) {
    let content = resp.data
    let fileName = options.downloadFileName || this.getFileName(resp) || "file"
    try {
      return FileSaver.saveAs(content, fileName)
    } catch (e) {
      console.log(e)
      return undefined
    }
  }

  get authToken() {
    return this.ctx.$store.getters.getAuthData?.access || ""
  }

  get baseUrl() {
    return appConfig.baseApiUrl.replace("{lang}", this.ctx.$i18n.locale)
    // return
  }

  isAuthError(e) {
    return e?.response?.status === 401;
  }

  goToLogin() {
    this.ctx.$router.push({name: "signin"})
  }

  prepareOptions(options) {
    if (!options) options = JSON.parse(JSON.stringify(defaultOptions))
    if (!options.anonymous) {
      options.headers = {
        "Authorization": `${process.env.TOKEN_AUTH_PREPEND || 'Bearer'} ${this.authToken}`,
      }
    } else {
      delete options.anonymous
    }
    if (options.contentType) {
      options.headers["Content-Type"] = options.contentType;
      delete options.contentType
    }
    if (options.downloadFile) {
      options.responseType = 'blob'
    }
    if (!options.baseURL)
      options.baseURL = this.baseUrl

    if (options.onProgressChanged && typeof options.onProgressChanged === 'function') {
      options.onUploadProgress = (e) => {
        let percentCompleted = Math.round((e.loaded * 100) / e.total)
        options.onProgressChanged.call(this.ctx, percentCompleted)
      };
      options.onDownloadProgress = (e) => {
        let percentCompleted = Math.round((e.loaded * 100) / e.total)
        options.onProgressChanged.call(this.ctx, percentCompleted)
      }
    }

    // if (Number.isNaN(Number.parseInt(options.limit))) {
    //   options.limit = consts.defaultListLimit
    // }

    return {...defaultOptions, ...options}
  }

  // eslint-disable-next-line no-unused-vars
  processFormErrors(e, postNotification, formData) {
    // console.log(errors, postNotification)
    if (postNotification)
      this.ctx.$notifications.formValidationErrorNotification(this.ctx, e, formData)
    // this.ctx.$notify()
  }

  $setState(key, val) {
    this.ctx.$set(this.ctx.loadingStatus$, key, val)
  }

  $setPagination(key, val) {
    this.ctx.$set(this.ctx.pagination$, key, val)
  }

  async makeRequest(method, url, data, options) {
    options = this.prepareOptions(options);
    url = new URL(url, options.baseURL)
    if (options.rootProgress) {
      this.ctx.$tmpStore.commit('setProgressLoader', options.rootProgress)
    }
    if (options.changeState) {
      if (!this.ctx.loadingStatus$) throw Error(`Component ${this.ctx.name} must implement "paginationLoadingMixin" or "loadingMixin"`)

      this.ctx.error = null;
      if (options.updatePagination && !options.first) {
        this.$setState('loading_page', true);
        this.$setState('state', 'loading_page');
      } else {
        this.$setState('loading', true);
        this.$setState('state', 'loading');
      }
    }

    // if (options.limit || options.updatePagination) {
    //   url.searchParams.set(LIMIT_KEY, options.limit)
    // }
    // debugger;
    let args = NDM.includes(method) ? [url, options] : [url, data, options];
    return new Promise(
      (resolve, reject) =>
        this.inst[method].apply(null, args)
          .then((resp) => {
            if (options.updatePagination) this.$setPagination("allCount", resp.data.count);
            if (options.changeState) {
              this.$setState('loaded', true);
              this.$setState('status', 'done');
            }
            // console.log(resp.data)
            if (options.downloadFile) {
              this.saveFile(resp, options)
              resolve()
            } else {
              resolve(resp.data)
            }
          })
          .catch((e) => {
            console.log(e?.response?.data);
            // don't forget try again in notification
            if (options.changeState && options.changeStateError) {
              this.$setState('error', e);
              this.$setState('status', 'error');
            }

            if (this.isAuthError(e)) {
              this.ctx.$notifications.authError(this.ctx, e)
              if (options.loginRedirect) {
                this.goToLogin()
              }
              return
            }

            if (options.formValidationError && e.response.status === 400) {
              this.processFormErrors(e, options.showErrorNotification, options.genForm)
            } else if (e.response.status === 403) {
              if (options.showErrorNotification) {
                console.log('PERMISSION ERROR')
                this.ctx.$notifications.permissionError(this.ctx, e)
              }
            } else if (options.showErrorNotification) {
              this.ctx.$notifications.commonError(this.ctx, e)
            }
            reject(e)
          })
          .finally(() => {
            if (options.changeState) {
              this.$setState('loading', false);
              this.$setState('loading_page', false);
            }
            if (options.rootProgress) {
              this.ctx.$tmpStore.commit('setProgressLoader', false)
            }
          })
    );
  }

  async post(url, data = null, options = null) {
    return await this.makeRequest('post', url, data, options)
  }

  async put(url, data = null, options = null) {
    return await this.makeRequest('put', url, data, options)
  }

  async get(url, options = null) {
    return await this.makeRequest('get', url, null, options)
  }

  async download(url, options = null) {
    if (!options) {
      options = {}
    }
    options.downloadFile = true
    return await this.makeRequest('get', url, null, options)
  }

  async putAsForm(url, data, options = null) {
    return await this.makeRequest('put', url, data?.toFormData(), options)
  }
}

// eslint-disable-next-line
export function install(Vue, options = {}) {
  // console.log('INSTALL')
  Object.defineProperty(Vue.prototype, "$http", {
    get: function $http() {
      return new HttpProvider(axiosInstance, this)
    }
  });
}

export default {install}