import {objectToQueryString} from "@/helpers/js_utils";

export default Object.freeze({

  withQuery(url, queryData) {
    return url + '?' + objectToQueryString(queryData)
  },

  OPEN_GEO_DATA: (ogdRoot = `ogd/`) => ({
    COUNTRY: (offset, limit, filter) => `${ogdRoot}country/?offset=${offset || 0}&limit=${limit || 0}&filter=${filter || ''}`,
    CURRENCY: (offset, limit, filter, out = false) => `${ogdRoot}currency/?offset=${offset || 0}&limit=${limit || 0}&filter=${filter || ''}&out=${out}`,
    // STATE: (offset, limit, country, filter) => `${ogdRoot}state/?country=${country}&offset=${offset || 0}&limit=${limit || 0}&filter=${filter || ''}`,
    // CITY: (offset, limit, state, filter) => `${ogdRoot}city/?state=${state}&offset=${offset || 0}&limit=${limit || 0}&filter=${filter || ''}`,
    CITY: (offset, limit, country, filter) => `${ogdRoot}city/?country=${country}&offset=${offset || 0}&limit=${limit || 0}&filter=${filter || ''}`,
  }),
  COMPANIES: (companiesRoot = 'companies/') => ({
    SIGNUP_INITIAL: `${companiesRoot}signup/initial/`,
    SIGNUP_INITIAL_SUBMIT: `${companiesRoot}signup/initial_submit/`,
    SIGNUP_FULL: `${companiesRoot}signup/full/`,
    SIGNUP_VERIFY: `${companiesRoot}signup/verify/`,
    SIGNUP_NEW_CRVI: `${companiesRoot}signup/update_rvi/`,
    SIGNUP_DOC_DOWNLOAD: `${companiesRoot}signup/full/download/`,
    SIGNUP_SIGNED_DOC_SET: `${companiesRoot}signup/full/set_signed/`,
    STAFF_LIST: `${companiesRoot}s_list/`,
    STAFF_STATUS_SET: (companyId) => `${companiesRoot}s_set/${companyId}/`,
    STAFF_INFO: (companyId) => `${companiesRoot}s_get/${companyId}/`,
    STAFF_INFO_FOR_EDIT: (companyId) => `${companiesRoot}s_get_edit/${companyId}/`,
    STAFF_INFO_FOR_EDIT_ACCEPT: (companyId) => `${companiesRoot}s_get_edit_a/${companyId}/`,
    STAFF_DOCS: (companyId) => `${companiesRoot}s_get/${companyId}/docs/`,
    STAFF_HISTORY: (companyId) => `${companiesRoot}s_get/${companyId}/history/`,
    STAFF_FILES: (companyId) => `${companiesRoot}s_get/${companyId}/files/`,
    LIST: `${companiesRoot}list/`,
  }),
  PERSONS: (personsRoot = 'persons/') => ({
    SIGNUP_INITIAL: `${personsRoot}signup/initial/`,
    SIGNUP_INITIAL_SUBMIT: `${personsRoot}signup/initial_submit/`,
    SIGNUP_FULL: `${personsRoot}signup/full/`,
    SIGNUP_VERIFY: `${personsRoot}signup/verify/`,
    SIGNUP_NEW_CRVI: `${personsRoot}signup/update_rvi/`,
    SUMSUB_INFO: `${personsRoot}sumsub/info/`,
    SUMSUB_STATUS: `${personsRoot}sumsub/status/`,
    STAFF_LIST: `${personsRoot}s_list/`,
    STAFF_STATUS_SET: (personId) => `${personsRoot}s_set/${personId}/`,
    STAFF_INFO: (personId) => `${personsRoot}s_get/${personId}/`,
    STAFF_INFO_FOR_EDIT: (personId) => `${personsRoot}s_get_edit/${personId}/`,
    STAFF_INFO_FOR_EDIT_ACCEPT: (personId) => `${personsRoot}s_get_edit_a/${personId}/`,
    LIST: `${personsRoot}list/`,
  }),
  USERS: (usersRoot = 'users/') => ({
    SIGNIN_TYPE: `${usersRoot}signin_t/`,
    SIGNIN: `${usersRoot}signin/`,
    // MANUAL_CHECK: `${usersRoot}verify/`,
    INFO: `${usersRoot}info/`,
    CREATE_RESET_PASSWORD_REQUEST: `${usersRoot}password/prr/`,
    PASSWORD_SET_REQUEST: `${usersRoot}password/set/`,
  }),
  TRANSFER: (transferRoot = 'transfer/') => ({
    STAFF: (staffRoot = transferRoot + 'staff/') => ({
      TRANSACTION_LIST: `${staffRoot}transactions/list/`,
      TRANSACTION_APPLY: (id) => `${staffRoot}transactions/apply/${id}/`,
      GET_FEE: `${staffRoot}fee/get/`,
      GET_BA: `${staffRoot}ba/get/`,
      UPDATE_FEE: `${staffRoot}fee/update/`,
      UPDATE_BA: `${staffRoot}ba/update/`,
      ACCEPT_INCOME_PAYMENTS_FILE: (file_id) => `${staffRoot}income/new/${file_id}/`,
      INCOME_FILE_LIST: `${staffRoot}income/list/`,
      INCOME_DATA_INFO: (id) => `${staffRoot}income/get/${id}/`,
      INCOME_COMPARE_TASK: (command, data_id) => `${staffRoot}income/task/${command}/${data_id}/`,
      APPLY_ALL: (id) => `${staffRoot}income/apply_all/${id}/`,
      // APPLY_ONE: (id) => `${staffRoot}income/apply_transaction/${id}/`,
      // APPLY_ONE: (id) => `${staffRoot}income/apply_transaction/${id}/`,
    }),
    RECEIVERS: (receiversRoot = transferRoot + 'receivers/') => ({
      // LIST: `${receiversRoot}list/`,
      LIST: (offset, limit, filter) => `${receiversRoot}list/?offset=${offset || 0}&limit=${limit || 0}&filter=${filter || ''}`,
      ADD: `${receiversRoot}add/`,
      GET: (id) => `${receiversRoot}get/${id}/`,
      REMOVE: (id) => `${receiversRoot}remove/${id}/`,
      EDIT: (id) => `${receiversRoot}edit/${id}/`,
    }),
    CREATE: `${transferRoot}create/`,
    REPEAT: (transferId) => `${transferRoot}repeat/${transferId}/`,
    APP_BENEFICIARY: `${transferRoot}app_beneficiary/`,
    INFO: (transferId) => `${transferRoot}info/${transferId}/`,
    HISTORY: (offset, limit, order) => `${transferRoot}history/?offset=${offset || 0}&limit=${limit || 0}&order=${order || ''}`,
    // ACCEPT_CURRENCIES: (transferId) => `${transferRoot}accept_currencies/${transferId}/`,
    ACCEPT_AMOUNT: (transferId) => `${transferRoot}accept_amount/${transferId}/`,
    SUBMIT: (transferId) => `${transferRoot}submit/${transferId}/`,
  }),
  STORAGE: (storageRoot = 'storage/') => ({
    UPLOAD: `${storageRoot}upload/`,
    DOWNLOAD: (fileId) => `${storageRoot}download/${fileId}/`,
  }),
  DASH: (dashRoot = 'dash/') => ({
    GENERAL_COUNTS: `${dashRoot}gc/`,
    COUNTRY_COUNTS: `${dashRoot}cc/`,
  }),
  UI: (uiRoot = 'ui/') => ({
    METRICS: (metricsRoot = `${uiRoot}metrics/`) => ({
      GET: `${metricsRoot}get/`,
      UPDATE: `${metricsRoot}update/`,
    }),
    HOME: (homeRoot = `${uiRoot}home/`) => ({
      GET_DATA: `${homeRoot}data/`,
    })
  })
});
