<template>
  <v-select v-bind="$bindField"
            v-model="input"
            :items="visibleItems"
            item-text="text"
            item-value="value"
  >
<!--    <template v-slot:append>-->
<!--      <slot name="hint-holder"></slot>-->
<!--    </template>-->
  </v-select>
</template>

<script>
import {selectInputMixin} from "@/mixins/common";

export default {
  name: "GenSelectField",
  mixins: [selectInputMixin],
}
</script>

<style scoped>
:deep(.v-input__append-inner) {

}
</style>