import {FieldType} from "@/forms/fieldTypes";

export function initModelByForm(formGroups) {
  let model = {}
  for (let i = 0; i < formGroups.length; i++) {
    let group = formGroups[i]
    for (let j = 0; j < group.form.length; j++) {
      let field = group.form[j]
      switch (field.type) {
        case FieldType.OBJECT_LIST: {
          model[field.key] = [initModelByForm(field.extra.form)]
          break
        }
        case FieldType.VIEW: {
          break
        }
        default: {
          model[field.key] = field.default;
        }
      }
    }
  }
  return model
}

export function initModelSetByFormList(forms, formGroupKey = 'form') {
  let model = {}
  for (let i = 0; i < forms.length; i++) {
    let form = forms[i]
    let innerForm = form[formGroupKey]
    if (innerForm)
      model[form.key] = initModelByForm(form[formGroupKey])
  }
  return model
}

export function initValidSetByFormList(forms, formIdKey = 'key') {
  let valid = {}
  for (let i = 0; i < forms.length; i++) {
    let form = forms[i]
    valid[form[formIdKey]] = false
  }
  return valid
}