import RULES from "@/plugins/rules";
import {FieldType} from "@/forms/fieldTypes";

// eslint-disable-next-line no-unused-vars
export default function (vm, modelVar = 'input') {

  const MIN_DATE = new Date(1900, 0, 1)
  // eslint-disable-next-line no-unused-vars
  const MAX_DATE = new Date(2099, 11, 31)
  // eslint-disable-next-line no-unused-vars
  const NOW_MINUS_18 = new Date(new Date() - new Date(1988, 0, 1))

  let rules = RULES(vm)

  // let sameListenFunction = (listenVarValue, currentVarKey, allData) => {
  //
  // }
  return [
    {
      key: 'create_account',
    },
    {
      key: 'person_info',
      form: [
        {
          label: vm.$t('fullRegGroups.person_info'),
          key: 'person_info',
          form: [
            {
              key: 'dob',
              label: vm.$t('fullRegFields.dob'),
              type: FieldType.DATE,
              rules: [
                rules.required(),
                rules.dateMinEqual(MIN_DATE),
                rules.dateMaxEqual(NOW_MINUS_18)
              ],
              extra: {
                min: MIN_DATE,
                max: NOW_MINUS_18,
              }
            },
          ]
        },
        {
          label: vm.$t('fullRegGroups.registered_address'),
          key: 'registered_address',
          form: [
            {
              key: 'reg_address_object',
              label: vm.$t('fullRegGroups.registered_address'),
              type: FieldType.ADDRESS,
              rules: [
                rules.required(),
                rules.isAddressFilled(),
              ]
            },
          ]
        },
      ]
    },
    {
      key: 'sumsub_approve',
    },
  ]
}