import LogoutPage from "@/components/common/pages/LogoutPage";
import SignInPage from "@/components/client/pages/SignInPage";
import CompanyFullRegistrationPage from "@/components/client/pages/c_registration/CompanyFullRegistrationPage";
import PersonFullRegistrationPage from "@/components/client/pages/p_registration/PersonFullRegistrationPage";
import CompanyFullRegistrationDocsPage from "@/components/client/pages/c_registration/CompanyFullRegistrationDocsPage";
import MainLayout from "@/components/client/layouts/MainLayout";
import NotFound from "@/components/common/pages/NotFound";
import ClientHomePage from "@/components/client/pages/ClientHomePage";
import PersonSumsubVerificationPage from "@/components/client/pages/p_registration/PersonSumsubVerificationPage";
import ClientReceiversPage from "@/components/client/pages/ClientReceiversPage";
import ClientAddReceiverPage from "@/components/client/pages/ClientAddReceiverPage";
import ClientTransactionsHistoryPage from "@/components/client/pages/ClientTransactionsHistoryPage.vue";
import TransactionInfoPage from "@/components/client/pages/TransactionInfoPage.vue";

export default [
  {
    path: '/login',
    name: 'login',
    redirect: {name: 'signin'}
  },
  {
    path: '/logout',
    name: 'logout',
    component: LogoutPage
  },
  {
    path: '/signin',
    name: 'signin',
    component: SignInPage,
  },
  // {
  //   path: '/cr/reg',
  //   name: 'company-reg',
  //   component: CompanyInitialRegistrationPage,
  // },
  // {
  //   path: '/pr/reg',
  //   name: 'person-reg',
  //   component: PersonInitialRegistrationPage,
  // },
  {
    path: '/cr/regfull',
    name: 'company-regfull',
    component: CompanyFullRegistrationPage,
  },
  {
    path: '/pr/regfull',
    name: 'person-regfull',
    component: PersonFullRegistrationPage,
  },
  {
    path: '/pr/regfull/sumsub',
    name: 'person-regfull_sumsub',
    component: PersonSumsubVerificationPage,
  },
  {
    path: '/cr/regfull/docs',
    name: 'company-regfull_docs',
    component: CompanyFullRegistrationDocsPage,
  },
  {
    path: '/',
    component: MainLayout,
    name: "main-layout",
    children: [
      {
        path: 'home',
        name: "home",
        component: ClientHomePage,
      },
      // {
      //   path: 'send',
      //   name: "send_money",
      //   component: ClientSendMoneyPage,
      // },
      {
        path: 'receivers',
        name: "receivers",
        component: ClientReceiversPage,
      },
      {
        path: 'history',
        name: "history",
        component: ClientTransactionsHistoryPage,
      },
      {
        path: 'order/:t_id',
        name: "transaction_order",
        component: TransactionInfoPage,
      },
      {
        path: 'receivers/add',
        name: "add_receiver",
        component: ClientAddReceiverPage,
      },
      {
        path: 'receivers/edit/:id',
        name: "edit_receiver",
        component: ClientAddReceiverPage,
      },
      {
        path: '*',
        component: NotFound,
        props: {toolbar: true}
      }
    ]
  },
]