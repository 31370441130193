import {FieldType} from "@/forms/fieldTypes";
import RULES from "@/plugins/rules";

// eslint-disable-next-line no-unused-vars
export default function (vm) {
  let rules = RULES(vm)
  return [
    {
      label: vm.$t('staffs.prefs.metrics.form.fbq_code'),
      key: 'fbq_code',
      type: FieldType.TEXT,
      rules: [
        rules.required(),
      ],
    },
    {
      label: vm.$t('staffs.prefs.metrics.form.gtag_code'),
      key: 'gtag_code',
      type: FieldType.TEXT,
      rules: [
        rules.required(),
      ],
    },
  ]
}