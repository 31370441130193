import Vue from 'vue'
import VueI18n from 'vue-i18n'

// avoid es6 default importing
const {translations} = require("@/../package.json")

Vue.use(VueI18n);

function buildI18n() {
  let messages = {}
  // console.log(translations)
  let locale = translations[0]
  if (!locale) {
    throw Error("No translations presented in package.json")
  }
  translations.forEach(lang => {
    messages[lang] = require(`../assets/translation/${lang}`)
  })
  return new VueI18n({
    locale: locale,
    fallbackLocale: locale,
    messages
  })
}

export const i18n = buildI18n();