<template>
  <div>
    <div v-if="grouped">
      <div v-for="(group, gi) in visibleReceivers" :key="`group__${gi}`">
        <div>
          {{ group.letter }}
        </div>
        <v-list color="transparent" :key="`items__${group.items.length}`">
          <receiver-item-view
              @click="onReceiverClick($event, index, gi)"
              v-for="(item, index) in group.items"
              @remove="onRemoveReceiver($event, index, gi)"
              :key="`item__${index}`"
              :value="item"
          >
          </receiver-item-view>
        </v-list>
      </div>
    </div>
    <v-list color="transparent" v-else>
      <receiver-item-view
          @click="onReceiverClick($event, index)"
          v-for="(item, index) in visibleReceivers"
          @remove="onRemoveReceiver($event, index)"
          :key="`item__${index}`"
          :value="item"
      >
      </receiver-item-view>
    </v-list>
  </div>
</template>

<script>
import {modelMixin} from "@/mixins/common";
import ReceiverItemView from "@/components/client/widgets/receivers/ReceiverItemView";
import endpoints from "@/utils/web/endpoints";

export default {
  name: "ReceiversListView",
  components: {ReceiverItemView},
  mixins: [
    modelMixin('showDialog', Boolean, false)
  ],
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    grouped: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    visibleReceivers: function () {
      return this.grouped ? this.groupedReceivers : this.receivers
    },
    /**
     * assert receivers ordered by name
     */
    groupedReceivers: function () {
      if (!this.receivers.length) {
        return []
      }
      let groups = []
      for (let i = 0; i < this.receivers.length; i++) {
        let letter = (this.receivers[i].display_name[0] || '?').toUpperCase()
        let lastGroup = groups.length ? groups[groups.length - 1] : undefined
        if (lastGroup?.letter === letter) {
          lastGroup.items.push(this.receivers[i])
        } else {
          groups.push({
            letter: letter,
            items: [this.receivers[i]]
          })
        }
      }
      return groups
    }
  },
  data() {
    return {
      receivers: []
    }
  },
  methods: {
    getData() {
      this.$http.post(
          endpoints.TRANSFER().RECEIVERS().LIST(),
          undefined
      ).then(resp => {
        this.receivers = resp.results
      })
    },
    // eslint-disable-next-line no-unused-vars
    onRemoveReceiver(receiver, ind, gi = undefined) {
      this.$http.post(
          endpoints.TRANSFER().RECEIVERS().REMOVE(receiver.id)
      ).then(() => {
        this.getData()
      })
    },
    onReceiverClick(receiver, index, groupIndex) {
      console.log(receiver, index, groupIndex)
      this.$router.push({name: 'edit_receiver', params: {id: receiver.id}})
    }
  },
  created() {
    this.getData()
  }
}
</script>

<style scoped>

</style>