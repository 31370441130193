const CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
const CL = CHARS.length

export function randStr(length = 8) {
  let result = ''
  for (let i = 0; i < length; i++) {
    result += CHARS.charAt(Math.floor(Math.random() * CL));
  }
  return result;
}

/**
 *
 * @param obj{Record<string, any>}
 * @param skipNullValues{boolean}
 * @returns string
 */
export function objectToQueryString(obj, skipNullValues = true) {
  let res = []
  for (const [key, value] of Object.entries(obj)) {
    if ((skipNullValues && value !== null && value !== undefined) || !skipNullValues)
      res.push(`${key}=${encodeURI(value)}`)
  }
  return res.join('&')
}

/**
 *
 * @param ctx{Vue}
 * @param headersObj{Record<string, any>}
 * @param keyFieldName{string} Using for place object iterable keys
 */
export function tableHeadersToList(ctx, headersObj, keyFieldName = 'key') {
  return Object.entries(headersObj).map(([key, value]) => {
    return {
      ...value,
      [keyFieldName]: key,
      label: getObjectLabel(key, value, ctx)
    }
  })
}

/**
 * @param key{string}
 * @param obj{Record<string, any>}
 * @param ctx{Vue}
 */
function getObjectLabel(key, obj, ctx) {
  if (obj.label) return obj.label
  if (obj.localeLabel) return ctx.$t(obj.localeLabel)
  return key
}

/**
 * Copy string to clipboard
 * @param text: String
 * @param vm: Vue context
 */
export function copyToClipboard(text, vm = null) {
  let textArea = document.createElement("textarea");
  textArea.value = text;
  textArea.textContent = text;
  textArea.style.top = "-999px";
  textArea.style.left = "-999px";
  textArea.style.position = "fixed";
  document.body.appendChild(textArea);
  // console.log(`copying ${text}...`);
  let selection = window.getSelection();
  let range = document.createRange();
  range.selectNode(textArea);
  selection.removeAllRanges();
  selection.addRange(range);
  //add to clipboard.
  // document.execCommand('copy');
  let successful = document.execCommand('copy');
  if (successful && vm) {
    vm.$notify({
      group: 'success_toast',
      text: vm.$t('common.copiedToClipboard')
    })
  }
  document.body.removeChild(textArea);
}

/**
 *
 * @param obj{Object}
 * @param key{string}
 * @param _default{any}
 * @returns {undefined|*}
 */
export function getDotted(obj, key, _default = undefined) {
  let keys = key.split('.')
  let result = obj
  for (let i = 0; i < keys.length; i++) {
    let k = keys[i]
    if (result)
      result = result[k]
    else return _default
  }
  return result
}

/**
 *
 * @param obj{Object}
 * @param sep{string}
 * @returns {string}
 */
export function encodeObjToUrl(obj, sep = '&') {
  if (!obj) return ''
  return encodeURIComponent(Object.keys(obj).filter(k=>![null, undefined, NaN].includes(obj[k])).map(k => `${k}=${obj[k]}`).join(sep))
}

/**
 *
 * @param data{string}
 * @param sep{string}
 * @returns {Object|*}
 */
export function decodeObjFromUrl(data, sep = '&') {
  if (!data) return undefined
  return decodeURIComponent(data).split(sep).reduce((previousValue, currentValue) => {
    const [key, value] = currentValue.split('=')
    previousValue[key] = value
    return previousValue
  }, {})
}
