import Vue from "vue";
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';
import {loadEnvVar} from "@/envLoader";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    locale: undefined,
    dark: false,
    token: null,
    authData: null,
    paginationLimit: 15,
    registrationData: undefined,
    showInstallPwaTip: true
  },
  getters: {
    getLocale: (state) => {
      return state.locale
    },
    getShowInstallPwaTip: (state) => {
      return state.showInstallPwaTip
    },
    getDark: (state) => {
      return state.dark
    },
    getToken: (state) => {
      return state.token
    },
    getAuthData: (state) => {
      return state.authData
    },
    getRegistrationData: (state) => {
      return state.registrationData
    },
    getPaginationLimit: (state) => {
      return state.paginationLimit
    },
  },
  mutations: {
    setDark(state, data) {
      state.dark = data
    },
    setLocale(state, data) {
      state.locale = data
    },
    setToken(state, data) {
      state.token = data
    },
    setAuthData(state, data) {
      state.authData = data
    },
    setRegistrationData(state, data) {
      state.registrationData = data
    },
    setPaginationLimit: (state, data) => {
      state.paginationLimit = data
    },
    setShowInstallPwaTip: (state, data) => {
      state.showInstallPwaTip = data
    },
  },
  actions: {},
  plugins: [createPersistedState({
    // key: process.env.VUE_APP_LOCAL_
    key: loadEnvVar("STORAGE_PERSISTENT_KEY")
  })]
})