import Vue from 'vue'
import vuetify from './plugins/vuetify'
import * as http from './plugins/axios'
import * as bus from './plugins/bus'
import * as notifications from './plugins/notifications'
import store from './plugins/store';
import * as tmpStore from './plugins/tmpStore';
import {i18n} from './plugins/locale';
import router from "@/plugins/router";
import App from './App.vue'
import '@/plugins/mask'
import '@/plugins/filters'
import './registerServiceWorker'
import VueNotification from "vue-notification";
import {loadEnvVar} from "./envLoader";
import directives from "@/utils/directives";

import "./scss/override.scss"

Vue.config.productionTip = false
Vue.directive('delay-model', directives.delayModel)
Vue.use(bus)
Vue.use(http)
Vue.use(tmpStore)
Vue.use(notifications)
Vue.use(VueNotification)


function hidePreloader() {
  let preloader = document.getElementById("app-preloader")
  preloader.classList.add('Thanos');
  setTimeout(() => {
    document.body.removeChild(preloader);
  }, 300)
}


// console.log("VUE_APP_LABEL", process.env.VUE_APP_LABEL)

new Vue({
  vuetify,
  i18n,
  router,
  store,
  render: h => h(App),
  mounted() {
    document.title = loadEnvVar("DOCUMENT_TITLE")
    // document.title = "kek"
    hidePreloader()
  }
}).$mount('#app')
