import masks from './masks.json'
import codes from './codes.json'


const {appConfig} = require('@/config')

const NUMBER_REGEXP = /^\d+$/
const PHONE_NUMBER_DEFAULT_LEN = 11

export function getFlagSrc(c) {
  return `${appConfig.publicPath}img/flags/${c}.svg`
}

export function getCodes() {
  return codes
}

export function getMask(c) {
  let m = masks[c]
  return m ? m[0] : undefined
}

function createMask(numCount) {
  let res = ''
  for (let i = 0; i < numCount; i++) {
    res += '#'
  }
  return res
}

/**
 *
 * @param {string}maskedNumber
 * @returns {string|undefined}cleanNumber
 */
export function asCleanNumber(maskedNumber) {
  return verifyChars(maskedNumber, c => NUMBER_REGEXP.test(c))
}

export function maskLen(maskedNumber) {
  return verifyChars(maskedNumber, c => c === '#')?.length || 0
}

function verifyChars(maskedNumber, vrf) {
  if (!maskedNumber) return undefined;
  let res = ''
  for (let i = 0; i < maskedNumber.length; i++) {
    let c = maskedNumber[i]
    if (vrf(c)) res += c
  }
  return res
}

function getCodesAndMasks() {
  let res = [];
  for (let i = 0; i < codes.length; i++) {
    let c = codes[i]
    c.m = masks[c.cc] || [createMask(PHONE_NUMBER_DEFAULT_LEN - c.cc.toString().length)]
    res.push(c)
  }
  
  return res
}

export const codesWithMasks = getCodesAndMasks()