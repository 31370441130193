<template>
  <div>
    <div>
      Order ID
    </div>
  </div>
</template>

<script>
export default {
  name: "TransactionInfoView",
  methods: {
    getData() {

    }
  }
}
</script>

<style scoped>

</style>