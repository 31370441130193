import {FieldType} from "@/forms/fieldTypes";
import RULES from "@/plugins/rules";

// eslint-disable-next-line no-unused-vars
export default function (vm, modelVar = 'input', fixed = false) {
  let rules = RULES(vm)
  return [
    {
      label: vm.$t('staffs.prefs.transaction_fee.form.fee_type'),
      key: 'fee_type',
      type: FieldType.SELECT,
      rules: [
        rules.required(),
      ],
      default: 'fixed',
      extra: {
        items: [
          {
            text: vm.$t('staffs.prefs.transaction_fee.types.fixed'),
            value: 'fixed'
          },
          {
            text: vm.$t('staffs.prefs.transaction_fee.types.percent_from_buy'),
            value: 'percent_from_buy'
          },
          {
            text: vm.$t('staffs.prefs.transaction_fee.types.percent_from_sell'),
            value: 'percent_from_sell'
          },
        ]
      }
    },
    {
      label: fixed ? vm.$t('staffs.prefs.transaction_fee.form.amount') : vm.$t('staffs.prefs.transaction_fee.form.percent'),
      key: 'amount',
      type: FieldType.FLOAT,
      rules: [
        rules.required(),
        rules.numMinEqual(0),
        ...(fixed ? [] : [rules.numMaxEqual(100)])
      ]
    },
    ...(fixed ? [{
      label: vm.$t('staffs.prefs.transaction_fee.form.currency'),
      key: 'currency',
      type: FieldType.CURRENCY,
      rules: [
        rules.required(),
      ]
    }] : [])
  ]
}