import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import 'remixicon/fonts/remixicon.css'

Vue.use(Vuetify);

// function missingMaterialIcons(ids) {
//   const icons = {}
//   for (const id of ids) {
//     for (const suffix of ['fill', 'outline', 'two-tone', 'round', 'sharp']) {
//       const name = `${id}_${suffix}`;
//       icons[name] = {
//         // component: MaterialIcon,
//         props: {
//           name
//         }
//       }
//     }
//   }
//   return icons
// }


const MY_ICONS = {
  complete: 'ri-check-double-line',
  cancel: 'ri-close-line',
  close: 'ri-close-line',
  delete: 'ri-close-line', // delete (e.g. v-chip close)
  clear: 'ri-close-line',
  success: 'ri-check-double-line',
  info: 'ri-information-line',
  warning: 'ri-alarm-warning-line',
  error: 'ri-error-warning-line',
  prev: 'ri-arrow-left-s-line',
  next: 'ri-arrow-right-s-line',
  checkboxOn: 'ri-checkbox-line',
  checkboxOff: 'ri-checkbox-blank-line',
  checkboxIndeterminate: 'ri-checkbox-indeterminate-line',
  delimiter: 'ri-checkbox-blank-circle-fill', // for carousel
  sort: 'ri-list-ordered',
  expand: 'ri-arrow-down-s-line',
  menu: 'ri-menu-line',
  subgroup: 'ri-subtract-line',
  dropdown: 'ri-arrow-down-s-line',
  radioOn: 'ri-radio-button-line',
  radioOff: 'ri-checkbox-blank-circle-line',
  edit: 'ri-edit-circle-line',
  ratingEmpty: 'ri-star-line',
  ratingFull: 'ri-star-fill',
  ratingHalf: 'ri-star-half-line',
  loading: 'ri-loader-3-line',
  first: '...',
  last: '...',
  unfold: 'ri-menu-unfold-line',
  file: 'ri-file-line',
}


export default new Vuetify({
  breakpoint: {
    thresholds: {
      xs: 540,
      sm: 740,
      md: 1000,
      lg: 1280,
    },
    // scrollBarWidth: 24,
  },
  theme: {
    dark: true,
    themes: {
      light: {
        'blue': '#007bff',
        'indigo': '#6610f2',
        'purple': '#6f42c1',
        'pink': '#e83e8c',
        'red': '#dc3545',
        'orange': '#fd7e14',
        'yellow': '#ffc107',
        'green': '#28a745',
        'teal': '#20c997',
        'cyan': '#17a2b8',
        'white': '#fff',
        'gray': '#6c757d',
        'gray-dark': '#343a40',
        // 'primary': '#007bff',
        // primary: '#ce4e4e',
        // 'primary': '#1EC624',
        'primary': '#8FDAD7',
        'secondary': '#6c757d',
        'success': '#28a745',
        'info': '#17a2b8',
        'warning': '#ffc107',
        'danger': '#dc3545',
        'error': '#dc3545',
        'light': '#f8f9fa',
        'dark': '#343a40'
      },
      dark: {
        'blue': '#007bff',
        'indigo': '#6610f2',
        'purple': '#6f42c1',
        'pink': '#e83e8c',
        'red': '#dc3545',
        'orange': '#fd7e14',
        'yellow': '#ffc107',
        'green': '#28a745',
        'teal': '#20c997',
        'cyan': '#17a2b8',
        'white': '#fff',
        'gray': '#6c757d',
        'gray-dark': '#222222',
        // primary: '#007bff',
        // primary: '#f8abab',
        // primary: '#6f42c1',
        // 'primary': '#1EC624',
        'primary': '#8FDAD7',
        // 'primary': '#9650FB 20.47%, #6823CC 97.48%)',
        'secondary': '#6c757d',
        // 'success': '#28a745',
        'success': '#1EC624',
        // 'info': '#17a2b8',
        'info': '#C7D9FC',
        'warning': '#cc9d11',
        'danger': '#EF492E',
        'error': '#d13f23',
        'light': '#f8f9fa',
      },
    },
  },
  icons: {
    // iconfont: 're',
    // iconfont: 'md',
    // iconfont: 'fa',
    values: {
      ...MY_ICONS
      // ...missingMaterialIcons([
      //   'visibility',
      //   'visibility_off',
      //   'description'
      // ])
    },
  },
});
