<template>
  <div class="d-flex align-center justify-center flex-column fw">
    <gen-select-field class="fw mb-2" v-if="useTagSelect"
                      hide-details
                      v-model="selectedTypeTag"
                      :label="$t('common.docTypeTag')"
                      style="max-width: 400px" :items="typeTags"/>
    <!--      @click="onHolderClick"
                  @drop.prevent="onFileDrop"-->
    <div class="fw" style="position: relative; max-width: 400px">
      <v-fab-transition mode="out-in">
        <v-btn color="error" v-if="file"
               style="position: absolute; top: -8px; left: -8px; z-index: 6"
               @click="$emit('clear', file)"
               fab x-small elevation="0">
          <v-icon size="18">ri-close-circle-line</v-icon>
        </v-btn>
      </v-fab-transition>
    </div>
    <v-card :color="dragOverState ? 'primary' : '#00000000'"
            v-if="!useTagSelect || selectedTypeTag"
            :dark="dragOverState || $vuetify.theme.dark"
            class="d-flex align-center justify-center fw file-input__holder"
            height="112" max-width="400">
      <div v-if="loading">
        {{ $t('common.loading') }}
      </div>
      <div class="fw text-center primary--text"
           style="max-width: 80%" v-else-if="file && !dragOverState" :key="file.id">
        <v-icon>ri-attachment-line</v-icon>
        {{ file.name }}
        ({{ file.size|prettyBytes }})
      </div>
      <div class="fw text-center" style="max-width: 80%" v-else>
        <v-icon>ri-attachment-line</v-icon>
        {{ label }}
      </div>
      <v-overlay absolute color="transparent"
                 @drop.prevent="onFileDrop"
                 @dragover.prevent="onFileDragover"
                 @dragleave.prevent="onFileDragend"
                 :value="true">
        <v-card class="fw fh"
                @click="onHolderClick"
                color="transparent">
        </v-card>
      </v-overlay>
    </v-card>
    <input style="display: none" :accept="accept"
           @input.prevent="onFileInputChanged"
           type="file" ref="fileInput"/>
  </div>
</template>

<script>
import {readFileAsBase64Async} from "@/helpers/files";

const MIME_TYPE_ANY = '*/*'
import {randStr} from "@/helpers/js_utils";
import GenSelectField from "@/components/common/forms/fields/GenSelectField";
import endpoints from "@/utils/web/endpoints";

export default {
  name: "FileHolder",
  components: {GenSelectField},
  computed: {
    anyFile: function () {
      return !this.accept || this.accept === MIME_TYPE_ANY
    },
    useTagSelect: function () {
      return !!this.typeTags?.length
    }
  },
  props: {
    upload: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: undefined
    },
    accept: {
      type: String,
      default: undefined
    },
    many: {
      type: Boolean,
      default: false
    },
    file: {
      type: Object,
      default: undefined
    },
    typeTags: {
      type: Array,
      default: undefined
    }
  },
  data() {
    return {
      selectedTypeTag: this.file?.tag,
      dragOverState: false,
      loading: false
    }
  },
  // watch: {
  //   file: {
  //     deep: true,
  //     handler: function (value) {
  //       console.log("WATCH FILE")
  //       this.selectedTypeTag = value?.tag || undefined
  //     }
  //   }
  // },
  methods: {
    onFileInputChanged(event) {
      this.processFiles(event.target.files)
    },
    onHolderClick() {
      this.$refs.fileInput.click()
    },
    onFileDragend() {
      this.dragOverState = false
    },
    onFileDragover() {
      if (!this.dragOverState) this.dragOverState = true
    },
    onFileDrop(event) {
      this.dragOverState = false
      this.processFiles(event.dataTransfer.files)
    },
    /**
     * Process files from each input type
     * @param {FileList}files
     */
    processFiles(files) {
      // console.log(files)
      if (this.many) {
        for (let i = 0; i < files.length; i++) {
          this.processSingleFile(files[i])
        }
      } else {
        this.processSingleFile(files[0])
      }
    },
    uploadFile(file) {
      console.log(file)
      this.loading = true
      let form = new FormData()
      form.append("file", file)
      this.$http.put(
          endpoints.STORAGE().UPLOAD,
          form
      ).then(res => {
        this.$emit('input', {
          id: res.file_id,
          name: file.name,
          size: file.size,
          type: file.type,
          tag: this.selectedTypeTag,
        })
        this.selectedTypeTag = undefined
      }).catch(e => {
        alert(e.toLocaleString())
      }).finally(() => {
        this.loading = false
      })
    },
    readFile(file) {
      this.loading = true
      readFileAsBase64Async(file, true).then(fileData => {
        this.$emit('input', {
          id: randStr(),
          name: file.name,
          size: file.size,
          type: file.type,
          tag: this.selectedTypeTag,
          data: fileData
        })
        this.selectedTypeTag = undefined
        // this.input =
      }).catch(e => {
        alert(e.toLocaleString())
      }).finally(() => {
        this.loading = false
      })
    },
    /**
     *
     * @param {File}file
     */
    processSingleFile(file) {
      if (!file) return
      if (!this.anyFile && !this.accept?.split(',').includes(file.type)) {
        console.error(`File must be "${this.accept}"`)
        return;
      }
      if (this.upload) {
        this.uploadFile(file)
      } else {
        this.readFile(file)
      }
      // this.inputFileData = file
    }
  }
}
</script>

<style scoped>

</style>