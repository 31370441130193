<template>
  <div>
    <component
        v-if="fieldComponent && needMount"
        :is="fieldComponent"
        :field="field"
        :disabled="disabled"
        :hide-labels="hideLabels"
        :readonly="readonly"
        :hide-details="hideDetails"
        :dense="dense"
        :all-data="allData"
        :all-form-data="allFormData"
        v-model="input"
    >
      <template v-slot:hint-holder v-if="field.longHint">
        <form-field-long-hint :hint-content="field.longHint"/>
      </template>
    </component>
    <div v-else-if="needMount">
      /FIELD CANNOT BE MOUNTED/
    </div>
    <div v-else>

    </div>
  </div>
</template>

<script>
import {FieldType} from "@/forms/fieldTypes";
import {modelMixin} from "@/mixins/common";
import FormFieldLongHint from "@/components/common/widgets/FormFieldLongHint";

const GenBoolField = () => import("@/components/common/forms/fields/GenBoolField")
const GenTextField = () => import("@/components/common/forms/fields/GenTextField")
const GenFloatField = () => import("@/components/common/forms/fields/GenFloatField")
const GenTextNumberField = () => import("@/components/common/forms/fields/GenTextNumberField")
const GenLongtextField = () => import("@/components/common/forms/fields/GenLongtextField")
const GenDateField = () => import("@/components/common/forms/fields/GenDateField")
const GenObjectListField = () => import("@/components/common/forms/fields/GenObjectListField")
const GenPhoneNumberField = () => import("@/components/common/forms/fields/GenPhoneNumberField")
const GenSelectRadioField = () => import("@/components/common/forms/fields/GenSelectRadioField")
const GenSelectField = () => import("@/components/common/forms/fields/GenSelectField")
// const GenBase64FileField = () => import("@/components/forms/fields/GenBase64FileField")
const GenUploadFileField = () => import("@/components/common/forms/fields/GenUploadFileField")
const GenFileField = () => import("@/components/common/forms/fields/GenFileField")
const GenViewField = () => import("@/components/common/forms/fields/GenViewField")
const GenAddressField = () => import("@/components/common/forms/fields/GenAddressField")
const GenCountrySelectField = () => import("@/components/common/forms/fields/GenCountrySelectField")
const GenCurrencySelectField = () => import("@/components/common/forms/fields/GenCurrencySelectField")
const GenOTPField = () => import("@/components/common/forms/fields/GenOTPField")
const GenCaptchaField = () => import("@/components/common/forms/fields/GenCaptchaField")

const COMPONENT_MAPPING = {
  [FieldType.BOOL]: GenBoolField,
  [FieldType.TEXT]: GenTextField,
  [FieldType.FLOAT]: GenFloatField,
  [FieldType.TEXT_NUMBER]: GenTextNumberField,
  [FieldType.LONGTEXT]: GenLongtextField,
  [FieldType.DATE]: GenDateField,
  [FieldType.DATETIME]: GenDateField,
  [FieldType.PHONE]: GenPhoneNumberField,
  [FieldType.SELECT]: GenSelectField,
  [FieldType.SELECT_RADIO]: GenSelectRadioField,
  [FieldType.OBJECT_LIST]: GenObjectListField,
  [FieldType.VIEW]: GenViewField,
  [FieldType.FILE]: GenFileField,
  [FieldType.FILE_BASE_64]: GenUploadFileField,
  [FieldType.ADDRESS]: GenAddressField,
  [FieldType.COUNTRY_SELECT]: GenCountrySelectField,
  [FieldType.OTP]: GenOTPField,
  [FieldType.CAPTCHA]: GenCaptchaField,
  [FieldType.CURRENCY]: GenCurrencySelectField,
}

export default {
  name: "FormInputElement",
  mixins: [modelMixin('input', undefined, undefined)],
  components: {
    FormFieldLongHint,
    // eslint-disable-next-line
    // GenTextField,
    // GenBoolField,
    // GenDateField,
    // GenObjectListField,
    // GenPhoneNumberField,
    // GenSelectRadioField,
    // GenTextNumberField
  },
  props: {
    hideLabels: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    field: {
      type: Object,
    },
    allData: {
      type: [Object, Array],
      default: undefined
    },
    allFormData: {
      default: undefined
    },
  },
  computed: {
    needMount: function () {
      let mf = this.field?.mountIfValue
      if (this.field.type === FieldType.HIDDEN) return false
      // eslint-disable-next-line no-debugger
      // debugger;
      if (mf && typeof mf === 'function') {
        return mf(this.allData)
      }
      return true
    },
    fieldType: function () {
      return this.field.type
    },
    fieldComponent: function () {
      return this.getComponentByType(this.fieldType)
    }
  },
  data() {
    return {
      longHintVisible: false
    }
  },
  methods: {
    getComponentByType(fieldType) {
      return COMPONENT_MAPPING[fieldType]
    }
  }
}
</script>

<style scoped>

</style>