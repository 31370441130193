import {getMask, maskLen} from "@/forms/fieldHelpers/phoneNumberFieldUtils";
import moment from "moment-timezone";

export const REGEX = Object.freeze({
  // EMAIL: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/,
  // EMAIL: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
  EMAIL: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  PASSWORD: /[^A-Za-z\d]+/,
  PASSWORD_NEW: /^[A-Za-z\d]+$/,
  SPECIAL: /\W|_/g,
  FLOAT: /\d+(?:\.\d{1,2})?$/,
  INT: /^\d+$/,
  NUMBERS_WITH_SPECIALS: /^[0-9*#.\-_+]+$/,
  ENG_NUMBERS_SPECIALS: /^[A-Za-z\d*#.\-_+]+$/,
  ENG_NUMBERS_SPECIALS_SPACE: /^[A-Za-z\d*#.\- _+]+$/,
});

function RULES(vm) {
  return {
    required: () => value => !([undefined, null, ''].includes(value)) || vm.$t('rules.required'),

    email: () => value => REGEX.EMAIL.test(value) || vm.$t('rules.email'),

    engNumOnly: () => value => !value || REGEX.PASSWORD_NEW.test(value) || vm.$t('rules.engNumOnly'),
    engNumSpecialOnly: () => value => !value || REGEX.ENG_NUMBERS_SPECIALS.test(value) || vm.$t('rules.engNumSpecialOnly'),
    engNumSpecialSpaceOnly: () => value => !value || REGEX.ENG_NUMBERS_SPECIALS_SPACE.test(value) || vm.$t('rules.engNumSpecialSpaceOnly'),
    numSpecialOnly: () => value => !value || REGEX.NUMBERS_WITH_SPECIALS.test(value) || vm.$t('rules.numSpecialOnly'),

    notEqualsTo: (inp) => value => value !== inp || vm.$t('rules.notEqualsTo', {v: inp}),

    dateMaxEqual: (date) => value => !value || (typeof value === 'string' ? moment(value.split("/").reverse().join("-")).toDate() : value) <= date || vm.$t('rules.dateMaxEqual', {date: vm.$options.filters.formatDate(date)}),
    dateMinEqual: (date) => value => !value || (typeof value === 'string' ? moment(value.split("/").reverse().join("-")).toDate() : value) >= date || vm.$t('rules.dateMinEqual', {date: vm.$options.filters.formatDate(date)}),

    counterMax: (count) => value => !value || value?.length < count || vm.$t('rules.counterMax', {n: count}),
    counterMin: (count) => value => !value || value?.length > count || vm.$t('rules.counterMin', {n: count}),
    counterEqual: (count) => value => !value || value?.length === count || vm.$t('rules.counterEqual', {n: count}),
    counterMaxEqual: (count) => value => !value || value?.length <= count || vm.$t('rules.counterMaxEqual', {n: count}),
    counterMinEqual: (count) => value => !value || value?.length >= count || vm.$t('rules.counterMinEqual', {n: count}),

    lenMaxEqual: (count) => value => value?.length <= count || vm.$t('rules.lenMaxEqual', {n: count}),
    lenMinEqual: (count) => value => value?.length >= count || vm.$t('rules.lenMinEqual', {n: count}),

    numMaxEqual: (num) => value => !value || value <= num || vm.$t('rules.numMaxEqual', {num: num}),
    numMinEqual: (num) => value => !value || value >= num || vm.$t('rules.numMinEqual', {num: num}),

    int: () => value => REGEX.INT.test(value) || vm.$t('rules.int'),
    float: () => value => REGEX.FLOAT.test(value) || vm.$t('rules.float'),
    checked: () => value => value === true || vm.$t('rules.checked'),
    phone: () => validatePhoneGenerator(vm),

    isAddressFilled: () => addressFilledGenerator(vm),
    // int: value => REGEX.INT.test(value) || vm.$t('rules.int'),
    // nr_int: value => value === '' || REGEX.INT.test(value) || vm.$t('rules.int'),
  }
}

/**
 * Generate validator function for mobile phone number
 * @param vm
 * @returns {(function(object): (boolean|string))|*}
 */
function validatePhoneGenerator(vm) {
  return (value) => {
    if (!value) return true
    if (typeof value !== 'object') return vm.$t('rules.int')
    if (value.cc && value.pc && value.pn) {
      let ml = maskLen(getMask(value.cc))
      // console.log(ml)
      if (ml === value.pn.length) return true
      return vm.$t('rules.counterEqual', {n: ml})
    }
    return vm.$t('rules.int')
  }
}

/**
 * Generate validator function for mobile phone number
 * @param vm
 * @returns {(function(object): (boolean|string))|*}
 */
function addressFilledGenerator(vm) {
  const requiredKeys = Object.keys(vm.$t('address'))
  // console.log('RK', requiredKeys)
  return (value) => {
    if (!value) return true
    for (let i = 0; i < requiredKeys.length; i++) {
      if (!value[requiredKeys[i]]) return vm.$t('rules.isAddressFilled')
    }
    // if (value.post_code.length > 63) {
    //   return vm.$t('rules.lenMaxEqual', {n: 63})
    // }
    // if (value.address.length > 63) {
    //   return vm.$t('rules.lenMaxEqual', {n: 63})
    // }
    return true
  }
}

export default RULES