<template>
  <div>
    <app-bar :show-drawer-control="false" show-logo/>

    <v-main>
      <v-container fluid>
        <v-row>
          <v-col sm="12" md="12" lg="3">
            <div class="lg-fixed">
              <div class="d-sm-none d-lg-block" :style="{'min-height': headingHeight}"></div>
              <registration-step-progress-item-view
                  v-for="(item, ind) in stepItems" :key="item.key"
                  :value="ind < activeWindow"
                  :active="ind === activeWindow"
                  :label="$t(`fullRegSteps.${item.key}`)"
              />
            </div>
          </v-col>
          <v-col sm="12" md="12" lg="6">
            <div :style="{'min-height': headingHeight}">
              <h3 class="text-h3 pt-12">{{ $t('registration.title') }}</h3>
            </div>
            <div>
              <generic-form :form="personRegForm"
                            :is-valid.sync="isFormValid"
                            v-model="input"/>
            </div>
            <div class="d-flex align-center justify-center flex-column pt-9">
              <app-button
                  @click="submitFullForm"
                  :disabled="!isFormValid"
                  icon="ri-arrow-right-circle-line"
                  :text="$t('userActions.continue')"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import RegistrationStepProgressItemView from "@/components/common/widgets/RegistrationStepProgressItemView";
import {rulesMixin} from "@/mixins/common";
import person_full_reg_form from "@/forms/complete/person_full_reg_form";
import {prepareFormData} from "@/utils/forms";
import endpoints from "@/utils/web/endpoints";
import AppButton from "@/components/common/widgets/AppButton";
import AppBar from "@/components/common/widgets/AppBar";

const GenericForm = () => import("@/components/common/forms/GenericForm");

export default {
  name: "PersonFullRegistrationPage",
  components: {AppBar, AppButton, GenericForm, RegistrationStepProgressItemView},
  mixins: [rulesMixin],
  props: {
    protectUnload: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    stepItems: function () {
      return person_full_reg_form(this)
    },
    personRegForm: function () {
      return this.stepItems[1]
    },
    stepItemKeys: function () {
      return this.stepItems.map(item => item.key)
    },
  },
  data() {
    return {
      input: {},
      activeWindow: 1,
      headingHeight: "70px",
      isFormValid: false
    }
  },
  methods: {
    beforeUnloadListener(event) {
      event.preventDefault()
      return event.returnValue = this.$t('registration.sureLeaveQuestion')
    },
    prepareFullForm() {
      return prepareFormData(this.personRegForm.form, this.input)
    },
    submitFullForm() {
      let data = this.prepareFullForm()
      this.$http.post(
          endpoints.PERSONS().SIGNUP_FULL,
          data,
          {
            showErrorNotification: true,
            formValidationError: true,
          }
      ).then(() => {
        this.$router.replace({name: "person-regfull_sumsub"})
        // console.log(res)
      })
    }
  },
  watch: {
    input: function (val) {
      if (!this.protectUnload) return
      if (val) {
        addEventListener("beforeunload", this.beforeUnloadListener, {capture: true});
      } else {
        removeEventListener("beforeunload", this.beforeUnloadListener, {capture: true});
      }
    }
  },
  created() {

  },
  beforeDestroy() {

  },
}
</script>

<style scoped>

</style>