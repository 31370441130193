<template>
  <v-main>
    <v-progress-linear style="position: fixed; z-index: 7"
                       :value="100"></v-progress-linear>
    <v-container fluid>
      <v-row>
        <v-col offset-sm="0" offset-md="0" offset-lg="3" sm="12" md="12" lg="6">
          <div :style="{'min-height': headingHeight}">
            <h3 class="text-h3 pt-12">{{ $t('registration.title') }}</h3>
            <h5 class="text-h5 pt-0">{{ $t('registration.finalStep') }}</h5>
          </div>
          <div v-html="$t('registration.finalStepText')">

          </div>
          <div class="d-flex align-center justify-center mt-12">
            <v-btn class="rounded-pill black--text"
                   x-large color="white"
                   :loading="loadingStatus$.loading"
                   :outlined="!$vuetify.theme.dark"
                   @click="downloadRegDoc">
                <span class="px-9">
                  {{ $t('registration.downloadDoc') }}
                </span>
            </v-btn>
          </div>
          <div class="d-flex flex-column align-center justify-center mt-12">
            <GenUploadFileField :label="$t('registration.attachDoc')"
                                v-model="signedFileData"
                                accept="application/pdf"
                                class="fw"/>
            <v-expand-transition mode="out-in">
              <v-btn class="rounded-pill"
                     v-if="showSendBtn"
                     x-large color="primary"
                     :loading="loadingStatus$.loading"
                     :outlined="!$vuetify.theme.dark"
                     @click="sendRegDoc">
                <span class="px-9">
                  {{ $t('registration.uploadDoc') }}
                </span>
              </v-btn>
            </v-expand-transition>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import {loadingMixin, rulesMixin} from "@/mixins/common";
import endpoints from "@/utils/web/endpoints";
import GenUploadFileField from "@/components/common/forms/fields/GenUploadFileField";

export default {
  name: "FullRegistrationDocsPage",
  components: {GenUploadFileField},
  mixins: [rulesMixin, loadingMixin],
  props: {
    protectUnload: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    showSendBtn: function () {
      return !!this.signedFileData?.id
    }
  },
  data() {
    return {
      headingHeight: "150px",
      signedFileData: undefined,
    }
  },
  methods: {
    sendRegDoc() {
      this.$http.post(
          endpoints.COMPANIES().SIGNUP_SIGNED_DOC_SET,
          {
            signed_reg_document: this.signedFileData?.id
          },
          {
            changeState: true,
            rootPreloader: true
          }
      ).then(()=>{
        this.$router.replace({name: "main-layout"})
      })
    },
    downloadRegDoc() {
      this.$http.download(
          endpoints.COMPANIES().SIGNUP_DOC_DOWNLOAD,
          {
            changeState: true,
          }
      ).then(() => {

      })
    }
  },
  watch: {},
  created() {

  },
}
</script>

<style scoped>

</style>