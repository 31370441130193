<template>
  <main-layout-template
      :drawer-menu="clientMenu"
      :show-drawer="fullInterface"
  >
    <template v-slot:main>
      <router-view v-if="fullInterface"></router-view>
      <!--      <registration-incomplete-page v-else :user-info="userInfo"/>-->
      <client-registration-incomplete-page v-else :user-info="userInfo"/>
    </template>
  </main-layout-template>
</template>

<script>
import ClientRegistrationIncompletePage from "@/components/client/pages/ClientRegistrationIncompletePage";
import {mainLayoutMixin} from "@/mixins/common";
import MainLayoutTemplate from "@/components/common/layouts/MainLayoutTemplate";
import {CONSTS} from "@/utils/consts";

export default {
  name: "MainLayout",
  components: {MainLayoutTemplate, ClientRegistrationIncompletePage},
  mixins: [
    mainLayoutMixin
  ],
  computed: {
    clientMenu: function () {
      return CONSTS.clientMenu.map(item => ({
        key: item.data_key,
        label: this.$t(`client.menu.${item.data_key}`),
        color: item.color,
        icon: item.icon,
        to: item.to
      }))
    },
    fullInterface: function () {
      return !!this.userInfo && this.userInfo.error !== true && this.isRegistrationComplete
    },
    isRegistrationComplete: function () {
      if (!this.userInfo) return false
      if (this.userInfo.user_cls === 'company') {
        return this.userInfo.extra.is_staff_approved
      } else if (this.userInfo.user_cls === 'person') {
        return this.userInfo.extra.is_sumsub_approved
      }
      return false
    }
  },
}
</script>

<style scoped>
</style>