// const path = require("path");
// const dotenv = require("dotenv");
// const COMMON = 'COMMON'
// function loadDebugEnv() {
//   const debugEnvPath = path.resolve(process.cwd(), 'debug.env.example')
//   dotenv.config({
//     path: debugEnvPath,
//     debug: true,
//     encoding: 'utf-8'
//   })
// }

const COMMON = 'COMMON'

function loadEnvVar(name, mode = COMMON, _default = undefined) {
  if (!mode) {
    mode = process.env.VUE_APP_TYPE
  }
  // console.log("GETTING", `VUE_APP_${mode.toUpperCase()}_${name}`, process.env[`VUE_APP_${mode.toUpperCase()}_${name}`])
  return process.env[`VUE_APP_${mode.toUpperCase()}_${name}`] || process.env[`VUE_APP_${COMMON}_${name}`] || _default
}

// module.exports = {loadEnvVar, loadDebugEnv}
module.exports = {loadEnvVar}