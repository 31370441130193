/**
 *
 * @param {string}code
 */
export function getFbqScriptContent(code) {
  return `!function (f, b, e, v, n, t, s) {if (f.fbq) return;n = f.fbq = function () {n.callMethod ?n.callMethod.apply(n, arguments) : n.queue.push(arguments)};if (!f._fbq) f._fbq = n;n.push = n;n.loaded = !0;n.version = '2.0';n.queue = [];t = b.createElement(e);t.async = !0;t.src = v;s = b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t, s)}(window, document, 'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '${code}');fbq('track', 'PageView');`
}

/**
 *
 * @param {string}code
 */
export function getGTagScriptContent(code) {
  return `window.dataLayer = window.dataLayer || [];function gtag() {dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${code}');`
}

/**
 *
 * @param {string}script
 */
export function insertScript(script) {
  const el = document.createElement('script')
  el.setAttribute("type", "text/javascript")
  el.setAttribute("data-target", "metrics")
  el.innerHTML = script
  document.head.appendChild(el)
}