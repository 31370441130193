<template>
  <div>
    <app-bar :show-drawer-control="false" show-logo/>

    <v-main>
      <v-container fluid>
        <sumsub-sdk-component/>
        <v-row class="mt-3">
          <v-col sm="12" md="12" offset-lg="4" lg="4">
            <v-alert type="info">
              <div>
                {{ $t('staffs.person.sumsub_check_manually_text') }}
              </div>
              <div class="d-flex align-center">
                <v-spacer/>
                <v-btn outlined @click="checkApproveStatus">
                  {{ $t('staffs.person.sumsub_check_manually_action') }}
                </v-btn>
              </div>
            </v-alert>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import AppBar from "@/components/common/widgets/AppBar";
import SumsubSdkComponent from "@/components/client/widgets/SumsubSdkComponent";
// eslint-disable-next-line no-unused-vars
import endpoints from "@/utils/web/endpoints";

export default {
  name: "PersonSumsubVerificationPage",
  components: {SumsubSdkComponent, AppBar},
  methods: {
    checkApproveStatus() {
      this.$http.get(
          endpoints.PERSONS().SUMSUB_STATUS,
          {progress: true}
      ).then(resp => {
        if (resp?.status === 'GREEN') {
          this.completeVerifying()
        }
        // console.log(resp)
      })
    },
    completeVerifying() {
      this.$router.replace({name: 'home'})
    }
  },
  created() {
    this.$bus.$on('ws-message', (content) => {
      if (content.type === 'sumsub_approve' && content.value) {
        this.completeVerifying()
      }
      // console.log('SS page', content)
    })
  }
}
</script>

<style scoped>

</style>