import {FieldType} from "@/forms/fieldTypes";
import RULES from "@/plugins/rules";

// export default function (vm, modelVar = 'input') {
export default function (vm, corporate = false, eng = false, edit = false) {
  let rules = RULES(vm)
  return {
    form: [
      {
        key: '_',
        form: [
          {
            label: vm.$t('common.fields.beneficiary_type'),
            key: 'beneficiary_type',
            type: FieldType.SELECT_RADIO,
            rules: [
              rules.required(),
            ],
            default: 'individual',
            disabledFunc: () => edit,
            // disabled: () => edit,
            extra: {
              items: [
                {
                  text: vm.$t('common.beneficiary_type.individual'),
                  value: 'individual'
                },
                {
                  text: vm.$t('common.beneficiary_type.corporate'),
                  value: 'corporate'
                },
              ]
            }
          },
          ...(corporate ? [
              {
                label: vm.$t('fullRegFields.company_name'),
                key: 'name',
                type: FieldType.TEXT,
                rules: [
                  rules.required(),
                  rules.counterMaxEqual(128)
                ]
              },
            ] :
            [
              {
                label: vm.$t('fullRegFields.first_name'),
                key: 'first_name',
                type: FieldType.TEXT,
                rules: [
                  rules.required(),
                  rules.counterMaxEqual(64)
                ]
              },
              {
                label: vm.$t('fullRegFields.last_name'),
                key: 'last_name',
                type: FieldType.TEXT,
                rules: [
                  rules.required(),
                  rules.counterMaxEqual(64)
                ]
              },
            ]),
          {
            label: vm.$t('common.fields.credentials_type'),
            key: 'credentials_type',
            type: FieldType.SELECT,
            rules: [
              rules.required(),
            ],
            default: 'iban_bic',
            extra: {
              items: [
                {
                  text: vm.$t('common.credentials_type.iban_bic'),
                  value: 'iban_bic'
                },
                {
                  text: vm.$t('common.credentials_type.an_sc'),
                  value: 'an_sc'
                },
              ]
            }
          },
          ...(eng ? [
            {
              label: vm.$t('client.fields.account_number'),
              key: 'account_number',
              type: FieldType.TEXT_NUMBER,
              rules: [
                rules.required(),
                rules.counterMaxEqual(64)
              ],
            },
            {
              label: vm.$t('client.fields.sort_code'),
              key: 'sort_code',
              type: FieldType.TEXT_NUMBER,
              rules: [
                rules.required(),
                rules.counterMaxEqual(64)
              ],
            },
          ] : [
            {
              label: vm.$t('client.fields.iban'),
              key: 'iban',
              type: FieldType.TEXT,
              rules: [
                rules.required(),
                rules.counterMaxEqual(64)
              ],
            }
          ]),
          {
            label: vm.$t('client.fields.bic'),
            key: 'bic',
            type: FieldType.TEXT,
            rules: [
              rules.required(),
              rules.counterMaxEqual(64)
            ],
          },
          {
            label: vm.$t('fullRegFields.email'),
            key: 'email',
            type: FieldType.TEXT,
            rules: [
              rules.email(),
              rules.required(),
            ],
          },
          ...(edit ? [{
            label: vm.$t('fullRegFields.phone'),
            key: 'phone',
            type: FieldType.TEXT,
            rules: [
              rules.required(),
            ],
          }] : [{
            label: vm.$t('fullRegFields.phone'),
            key: 'phone',
            type: FieldType.PHONE,
            rules: [
              rules.phone(),
              rules.required(),
            ],
          }]),
          // {
          //   label: vm.$t('fullRegFields.phone'),
          //   key: 'phone',
          //   type: edit ? FieldType.TEXT : FieldType.PHONE,
          //   rules: [
          //     rules.phone(),
          //     rules.required(),
          //   ],
          // },
          {
            label: vm.$t('address.country'),
            key: 'country',
            type: FieldType.COUNTRY_SELECT,
            rules: [
              rules.required(),
            ],
          }
        ]
      }
    ]
  }
}