<template>
  <!--  <v-card outlined color="transparent"-->
  <!--          style="box-shadow: #666666 0 0 0 2px !important"-->
  <!--  >-->
  <v-card>
    <!--    {{ item }}-->
    <v-card-text class="pb-0">
      <div class="d-flex align-center">
        <v-avatar style="text-transform: uppercase; color: black"
                  color="primary" class="text-h5 mr-3">
          {{ avatarText }}
        </v-avatar>
        <div class="pb-2">
          <div class="d-flex align-center text-h5 white--text" v-if="item.quote">
            <span>{{ item.currency.symbol }}{{ item.amount }} {{ item.currency.id }}</span>
            <v-icon size="20" class="px-2">ri-arrow-right-circle-line</v-icon>
            <span>{{ item.quote.buy_currency.symbol }}{{ item.quote.buy_amount }} {{
                item.quote.buy_currency.id
              }}</span>
          </div>
          <div style="line-height: 10px">To <b>{{ receiverName }}</b> · {{ item.updated_at | formatDateTime }}
          </div>
        </div>
        <v-spacer/>
        <v-btn small icon v-for="action in allowedActions" :key="action.key"
               @click="action.handler" class="ml-1">
          <v-icon small>{{ action.icon }}</v-icon>
        </v-btn>
      </div>
      <div class="d-flex align-stretch" v-if="!isSuccess">
        <div style="padding: 12px 23px 6px !important;">
          <div
              style="border-left: #666666 3px dashed; height: 100%; min-height: 20px">
          </div>
        </div>
        <div class="pl-4">
          <div class="white--text">{{ $t(`client.payment_status.${item.local_status}`) }}</div>
          <div>{{ localStatusHelpText }}</div>
        </div>
      </div>
      <v-alert
          v-if="item.local_status === 'awaiting_payment'"
          v-html="hintContent" type="info"
          class="black--text mt-3"
      />
      <div class="d-flex align-center" v-if="isSuccess">
        <div style="width: 58px"/>
        <v-icon left color="success">ri-checkbox-circle-fill</v-icon>
        <div>{{ $t('client.payment_status.success') }}</div>
      </div>
      <div class="d-flex">
        <div class="text-secondary" style="line-height: 28px"><b>{{ $t('client.send_money.orderId') }}</b>
          {{ item.reference }}
        </div>
        <clipboard-copy-button icon small :value="item.reference"/>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn @click="actionInfo" color="primary" text>
        {{ $t(isSuccess ? 'userActions.showInfo' : 'userActions.howToPay') }}
        <v-icon right>ri-information-line</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ClipboardCopyButton from "@/components/common/widgets/ClipboardCopyButton.vue";
import endpoints from "@/utils/web/endpoints";

export default {
  name: "TransactionHistoryItemView",
  components: {ClipboardCopyButton},
  computed: {
    localStatusHelpText: function () {
      return this.$t(`client.payment_status.${this.item.local_status}__msg`, {
        amount: `${this.item.amount} ${this.item.currency?.id}`,
        date: this.$options.filters.formatDateTime(this.item.quote?.expires_at)
      })
    },
    isSuccess: function () {
      return this.item.local_status === 'success'
    },
    allowedActions: function () {
      return this.actions.filter(a => a.cond)
    },
    actions: function () {
      return [
        // {
        //   key: 'share',
        //   icon: 'ri-share-line',
        //   handler: this.actionShare
        // },
        {
          key: 'copy',
          icon: 'ri-restart-line',
          cond: this.isSuccess,
          handler: this.actionCopy
        },
        // {
        //   key: 'info',
        //   icon: 'ri-information-line',
        //   cond: true,
        //   handler: this.actionInfo
        // },
      ]
    },
    hintContent: function () {
      return this.$t("client.send_money.already_paid_in")
    },
    receiverName: function () {
      let r = this.item.receiver
      return r.name || `${r.first_name} ${r.last_name}`
    },
    avatarText: function () {
      let r = this.item.receiver
      if (r.name) {
        return r.name[0]
      }
      return `${r.first_name[0]}${r.last_name[0]}`
    }
  },
  props: {
    item: {
      type: Object
    }
  },
  methods: {
    actionCopy() {
      this.$http.post(
          endpoints.TRANSFER().REPEAT(this.item.id)
      ).then(resp => {
        console.log(resp)
        // this.$router.push({name: "send_money", query: {p: resp.id}})
      })
    },
    actionInfo() {
      if (this.item.status in ['awaiting_payment', 'done', 'error'])
        this.$router.push({name: "transaction_order", params: {t_id: this.item.id}})
      // else this.$router.push({name: "send_money", query: {p: this.item.id}})
    },
    itemClick() {
      console.log('clicked')
    }
  },
  created() {
    // console.log("Filters",)
  }
}
</script>

<style scoped>

</style>