<template>
  <v-dialog max-width="400" v-model="dialog">
    <template v-slot:activator="{on, attrs}">
      <v-btn v-on="on" v-bind="attrs" text color="primary">
        {{ $t('export.exportTitle') }}
      </v-btn>
    </template>
    <v-card color="black">
      <v-card-title>
        {{ $t('export.exportTitle') }}
      </v-card-title>
      <v-card-text class="pb-0">
        <v-select
            flat full-width
            v-model="exportParams.type"
            hide-details filled solo
            :label="$t('export.exportAs')"
            :items="exportVariants"
            item-value="key"
            item-text="label"
        >
          <template v-slot:item="{item, on, attrs}">
            <v-list-item dense v-on="on" v-bind="attrs" :disabled="item.disabled">
              <v-list-item-avatar>
                <v-icon :color="item.color" size="24">
                  {{ item.icon }}
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.label }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-slot:selection="{item}">
            <v-list-item dense class="pa-0">
              <v-list-item-avatar>
                <v-icon :color="item.color" size="24">
                  {{ item.icon }}
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.label }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

        </v-select>
        <gen-select-radio-field
            v-if="showFilterVariants"
            hide-details
            hide-labels
            v-model="exportParams.useFilter"
            :field="useFilterField"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn block color="primary" @click="onExportClick">
          {{ $t('export.exportAction') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import GenSelectRadioField from "@/components/common/forms/fields/GenSelectRadioField.vue";
import {FieldType} from "@/forms/fieldTypes";

export default {
  name: "ListExportDialog",
  components: {GenSelectRadioField},
  props: {
    showFilterVariants: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    exportVariants: function () {
      return [
        {
          key: 'csv',
          label: this.$t("export.type.csv"),
          color: 'warning',
          icon: 'ri-file-excel-2-line'
        },
        {
          key: 'html',
          label: this.$t("export.type.html"),
          color: 'blue',
          icon: 'ri-html5-line'
        },
        {
          key: 'pdf',
          label: this.$t("export.type.pdf"),
          color: 'red',
          icon: 'ri-file-pdf-line',
          disabled: true
        },
      ]
    },
    useFilterField: function () {
      return {
        key: 'use_filter',
        type: FieldType.SELECT_RADIO,
        extra: {
          items: [
            {text: this.$t('export.filter.allData'), value: false},
            {text: this.$t('export.filter.filteredData'), value: true},
          ]
        }
      }
    }
  },
  data() {
    return {
      dialog: false,
      exportParams: {
        useFilter: false,
        type: 'csv'
      }
    }
  },
  methods: {
    onExportClick() {
      this.dialog = false
      this.$emit('on-export', this.exportParams)
    }
  }
}
</script>

<style scoped>

</style>