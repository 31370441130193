<template>
  <div
      class="d-flex fh fw"
      :class="rootClassBind"
  >
    <div class="text-h4 pb-2" v-if="title">
      {{ title }}
    </div>
    <!--    <v-container :fluid="fluid">-->
    <slot/>
    <!--    </v-container>-->
  </div>
</template>

<script>
export default {
  name: "PageWrapper",
  props: {
    fluid: {
      type: Boolean,
      default: true
    },
    horCenter: {
      type: Boolean,
      default: false,
    },
    verCenter: {
      type: Boolean,
      default: false,
    },
    vertical: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: undefined
    }
  },
  computed: {
    rootClassBind: function () {
      return {
        'justify-center': this.horCenter,
        'align-center': this.verCenter,
        'flex-column': this.vertical,
        'flex-row': !this.vertical,
      }
    }
  }
}
</script>

<style scoped>

</style>