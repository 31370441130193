<template>
  <div ref="gCaptchaContainer" :data-sitekey="gCaptchaKey">
    <div
        style="background-color: #C35656; padding: 4px; color: white" v-if="debug">
      CAPTCHA IS IN DEBUG MODE
    </div>
  </div>
</template>

<script>
import {appConfig} from "@/config";
import {modelMixin} from "@/mixins/common";

let debug = appConfig.debugCaptcha
// let debug = false

export default {
  name: "ReCaptchaComponent",
  mixins: [
    modelMixin('input', String, undefined)
  ],
  props: {
    checkInterval: {
      type: Number,
      default: 500
    },
    theme: {
      type: String,
      default: 'light'
    }
  },
  computed: {
    gCaptchaKey: function () {
      return appConfig.gCaptchaSiteKey
    }
  },
  data() {
    return {
      debug: debug,
      rcWidgetId: undefined,
      timer: undefined,
      loadedTimer: undefined,
    }
  },
  methods: {
    resetCaptcha() {
      console.log("RESET CAPTCHA");
      // eslint-disable-next-line no-undef
      grecaptcha?.reset()
    },
    renderCaptcha() {
      return new Promise(resolve => {
        let el = this.$refs.gCaptchaContainer
        console.log(el)
        this.loadedTimer = setInterval(() => {
          // eslint-disable-next-line no-undef
          if (grecaptcha) {
            clearInterval(this.loadedTimer)
            this.loadedTimer = undefined
            // eslint-disable-next-line no-undef
            this.rcWidgetId = grecaptcha.render(el, {
              theme: this.theme
            })
            resolve()
          }
        }, 100)
        // eslint-disable-next-line no-undef
      })
    },
    getRecResp() {
      try {
        // eslint-disable-next-line no-undef
        return grecaptcha.getResponse(this.rcWidgetId)
      } catch (e) {
        this.renderCaptcha()
        return undefined
      }
    },
    startCheckingCaptcha() {
      this.timer = setInterval(() => {
        let res = this.getRecResp()
        console.log(res)
        this.input = res
      }, this.checkInterval)
    },
    stopCheckingCaptcha() {
      clearInterval(this.timer)
      this.timer = undefined
      try {
        // eslint-disable-next-line no-undef
        grecaptcha.reset(this.rcWidgetId)
        this.rcWidgetId = undefined
      } catch (e) {
        console.log("E", e)
      }
    },

  },
  mounted() {
    if (debug) {
      this.input = "true_captcha_value"
    } else {
      this.renderCaptcha().then(this.startCheckingCaptcha)
      this.$bus.activeCaptchaComponent = this
      // this.startCheckingCapture()
    }
  },
  beforeDestroy() {
    this.$bus.activeCaptchaComponent = undefined
    if (!debug) {
      this.stopCheckingCaptcha()
    }
  },
  beforeUpdate() {
    if (!debug) {
      this.stopCheckingCaptcha()
    }
  }
}
</script>

<style scoped>

</style>