<template>
  <v-btn class="ml-3" v-bind="$props"
         :disabled="!value"
         @click.stop="clipCopy">
    <slot>
      <v-icon size="16">ri-file-copy-line</v-icon>
    </slot>
  </v-btn>
</template>

<script>
import {VBtn} from "vuetify/lib/components";
import {copyToClipboard} from "@/helpers/js_utils";

export default VBtn.extend({
  name: "ClipboardCopyButton",
  props: {
    value: {
      type: String,
      default: undefined
    }
  },
  methods: {
    clipCopy() {
      return copyToClipboard(this.value, this)
    }
  }
})
</script>

<style scoped>

</style>