<template>
  <center-animated-layout>
    <not-found v-if="!userCls || !requestId"/>
    <v-card v-else class="fw" max-width="400" color="transparent">
      <v-card-title>{{ $t('passwordRecovery.accountPasswordRecovery') }}</v-card-title>
      <v-card-text>
        <generic-form
            :is-valid.sync="isInputValid"
            :form="resetPasswordForm" v-model="input"/>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <app-button :disabled="!isInputValid" @click="resetPassword">
          {{ $t('passwordRecovery.resetPasswordAction') }}
        </app-button>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </center-animated-layout>
</template>

<script>
import CenterAnimatedLayout from "@/components/common/layouts/CenterAnimatedLayout";
import NotFound from "@/components/common/pages/NotFound";
import GenericForm from "@/components/common/forms/GenericForm";
import {resetPasswordForm} from "@/forms/complete/p_reset_reg_form";
import AppButton from "@/components/common/widgets/AppButton";
// eslint-disable-next-line no-unused-vars
import endpoints from "@/utils/web/endpoints";

export default {
  name: "ResetPasswordPage",
  components: {AppButton, GenericForm, NotFound, CenterAnimatedLayout},
  computed: {
    userCls: function () {
      return this.$route.query.cls
    },
    requestId: function () {
      return this.$route.query.rid
    },
    resetPasswordForm: function () {
      return {
        form: [
          {
            key: '_',
            form: resetPasswordForm(this)
          }
        ]
      }
    }
  },
  data() {
    return {
      isInputValid: false,
      input: {
        password: '',
        r_password: '',
        captcha: ''
      }
    }
  },
  methods: {
    resetPassword() {
      this.$http.post(
          endpoints.USERS().PASSWORD_SET_REQUEST,
          {
            ...this.input,
            request: this.requestId,
            user_cls: this.userCls
          },
      ).then(() => {
        this.$notify({
          title: this.$t('common.success'),
          text: this.$t('passwordRecovery.passwordChangeSuccess'),
          group: 'success'
        })
        this.$router.replace({name: 'signin'})
      }).catch(()=>{
        this.$bus.activeCaptchaComponent?.resetCaptcha()
      })
    }
  }
}
</script>

<style scoped>

</style>