<template>
  <v-app-bar
      height="62" absolute color="black"
      class="block-bar"
      app style="max-width: 100% !important;">
    <div class="d-flex align-center fw fh px-3">
      <v-app-bar-nav-icon
          v-if="showDrawerControl"
          @click.prevent="mDrawer = !mDrawer"
      >
        <!--      <div v-if="!mDrawer"></div>-->
        <app-logo v-if="!mDrawer" size="24"/>
      </v-app-bar-nav-icon>
      <v-card color="transparent"
              v-if="showLogo && !showDrawerControl"
              class="fh d-flex align-center px-2" flat tile :to="{name: 'home'}">
        <app-logo with-text/>
      </v-card>

      <!--    <v-toolbar-title>{{ appLabel }}</v-toolbar-title>-->
      <!--      <v-scroll-y-transition mode="out-in" group>-->
      <!--        <v-toolbar-title :key="this.$bus.pageTitle || '_empty_'">{{ this.$bus.pageTitle }}</v-toolbar-title>-->
      <!--      </v-scroll-y-transition>-->

      <v-spacer></v-spacer>
      <v-menu offset-y light min-width="240"
              v-if="hasUserInfo" v-model="userMenuOpened"
              content-class="mt-3">
        <template v-slot:activator="{on, attrs}">
          <v-card v-if="hasUserInfo" class="fh d-flex align-center px-3"
                  v-on="on" v-bind="attrs" flat tile color="transparent">
            <v-avatar style="text-transform: uppercase" size="44"
                      color="gray-dark">
              <span v-if="$vuetify.breakpoint.mobile">{{ avatarName }}</span>
              <v-icon v-else>ri-user-line</v-icon>
            </v-avatar>
            <div class="mx-3" v-if="!$vuetify.breakpoint.mobile">
              {{ userInfo.display_name }}
            </div>
            <v-icon
                v-if="!$vuetify.breakpoint.mobile"
                class="rotate-icon" :class="{'rotate-icon-1': !userMenuOpened}">
              ri-arrow-down-s-line
            </v-icon>
          </v-card>
        </template>
        <v-card v-if="hasUserInfo">
          <user-verification-status-view
              v-if="['company', 'person'].includes(userInfo.user_cls)"
              :verification-status="userInfo.verification_status"
              :show-unblock-button="!userInfo.extra?.full_reg_doc_signed"
              :is-company="userInfo.user_cls === 'company'"/>
          <v-card-text>
            <div class="caption">{{ $t(`appUserClass.${userInfo.user_cls}`) }}</div>
            <div class="body-2 font-weight-light black--text">{{ userInfo.display_name }}</div>
          </v-card-text>
          <v-list dense nav>
            <v-list-item
                @click="onUserMenuItemClick(item)"
                v-for="item in userMenuActions" :key="item.key">
              <v-list-item-title>
                {{ $t(`defaultUserMenu.${item.key}`) }}
              </v-list-item-title>
              <v-list-item-icon>
                <v-icon left :color="item.color">{{ item.icon }}</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </div>
    <v-divider/>
  </v-app-bar>
</template>

<script>
import {loadingMixin, syncPropMixin} from "@/mixins/common";
import {appConfig} from "@/config";
import AppLogo from "@/components/common/widgets/AppLogo";
import UserVerificationStatusView from "@/components/common/widgets/UserVerificationStatusView.vue";

export default {
  name: "AppBar",
  components: {UserVerificationStatusView, AppLogo},
  mixins: [
    syncPropMixin('mDrawer', "drawer", Boolean, true),
    loadingMixin
  ],
  props: {
    showDrawerControl: {
      type: Boolean,
      default: true
    },
    showLogo: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    appLabel: function () {
      return appConfig.appType
    },
    userInfo: function () {
      return this.$tmpStore.getters.getUserInfo
    },
    hasUserInfo: function () {
      return !!this.userInfo
    },
    avatarName: function () {
      if (this.userInfo) {
        let nameSpl = this.userInfo.display_name.split(' ')
        if (nameSpl.length > 1) {
          return nameSpl[0][0] + nameSpl[1][0]
        }
        return nameSpl[0][0] + nameSpl[0][1]
      } else {
        return '_'
      }
    }
  },
  data() {
    return {
      userMenuOpened: false,
      userMenuActions: [
        {
          key: 'logout',
          color: 'error',
          icon: 'ri-logout-circle-line',
          click: 'logoutClick'
        }
      ]
    }
  },
  methods: {
    onUserMenuItemClick(item) {
      this[item.click].call(this)
    },
    logoutClick() {
      this.$router.replace({name: "logout"})
    }
  },
  created() {
    this.$tmpStore.dispatch('fetchUserInfo', this)
  }
}
</script>

<style scoped>
</style>