<template>
  <v-dialog v-model="dialog" max-width="400">
    <template v-slot:activator="{on, attrs}">
      <a href="javascript:void(0)" class="text--secondary"
         v-on.prevent="on" v-bind="attrs">{{ $t('passwordRecovery.forgotPasswordQuestion') }}</a>
    </template>
    <v-card light>
      <v-card-title>
        {{ $t('passwordRecovery.accountPasswordRecovery') }}
      </v-card-title>
      <v-card-text>
        <div>
          {{ $t('passwordRecovery.confirmRecover') }}
        </div>
        <v-text-field
            class="mt-3"
            :label="$t(`signIn.${field}`)"
            readonly hide-details filled
            :value="login"
        />
        <div class="d-flex align-center justify-center pt-6">
          <re-captcha-component v-if="dialog" v-model="captchaToken"/>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn text
               :loading="loadingStatus$.loading"
               :disabled="!captchaToken" @click="sendResetRequest">
          {{ $t('passwordRecovery.resetPasswordAction') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ReCaptchaComponent from "@/components/common/widgets/ReCaptchaComponent";
import endpoints from "@/utils/web/endpoints";
import {loadingMixin} from "@/mixins/common";

export default {
  name: "ForgotPasswordDialog",
  components: {ReCaptchaComponent},
  mixins: [
    loadingMixin
  ],
  props: {
    field: {
      type: String,
    },
    login: {
      type: String,
    },
  },
  data() {
    return {
      dialog: false,
      captchaToken: undefined
    }
  },
  watch: {
    // dialog: function (val) {
    //
    // }
  },
  methods: {
    sendResetRequest() {
      let data = {
        captcha: this.captchaToken,
        [this.field]: this.login
      }
      this.$http.post(
          endpoints.USERS().CREATE_RESET_PASSWORD_REQUEST,
          data,
          {
            changeState: true
          }
      ).then(() => {
        this.dialog = false
        this.$notify({
          text: this.$t('passwordRecovery.resetPasswordRequestCreated'),
          duration: 15000,
          title: this.$t('common.success'),
          group: 'success'
        })
      })
    }
  }
}
</script>

<style scoped>

</style>