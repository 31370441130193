<template>
  <page-wrapper>
    <transaction-info-view/>
  </page-wrapper>
</template>

<script>
import TransactionInfoView from "@/components/client/widgets/TransactionInfoView.vue";
import PageWrapper from "@/components/common/widgets/PageWrapper.vue";

export default {
  name: "TransactionInfoPage",
  components: {PageWrapper, TransactionInfoView}
}
</script>

<style scoped>

</style>