<template>
  <div></div>
</template>

<script>

export default {
  name: "LogoutPage",
  methods: {
    logout() {
      window.$ws.close()
      this.$store.commit('setAuthData', null)
      this.$router.replace({name: 'signin'})
    }
  },
  created() {
    this.logout()
  }
}

</script>

<style scoped>

</style>