<template>
  <v-main>
    <v-progress-linear style="position: fixed; z-index: 7"
                       :value="regProgressValue"></v-progress-linear>
    <v-container fluid>
      <v-row>
        <v-col sm="12" md="12" lg="3">
          <div class="lg-fixed">
            <div class="d-sm-none d-lg-block" :style="{'min-height': headingHeight}"></div>
            <registration-step-progress-item-view
                v-for="(item, ind) in stepItems" :key="item.key"
                :value="ind < activeWindow"
                :active="ind === activeWindow"
                :label="$t(`fullRegSteps.${item.key}`)"
            />
            <!--            <div>-->
            <!--              <v-checkbox v-model="skipValidation" label="Allow continue if form invalid"></v-checkbox>-->
            <!--            </div>-->
            <div>
              <v-btn @click="resetForm"
                     class="fw mt-3" text>
                <v-icon left>ri-refresh-line</v-icon>
                {{ $t('userActions.resetForm') }}
              </v-btn>
              <v-btn class="fw mt-3" text :to="{name: 'logout'}">
                <v-icon left>ri-logout-circle-line</v-icon>
                {{ $t('userActions.logout') }}
              </v-btn>
            </div>
          </div>
        </v-col>
        <v-col sm="12" md="12" lg="6">
          <div :style="{'min-height': headingHeight}">
            <h3 class="text-h3 pt-12">{{ $t('registration.title') }}</h3>
          </div>
          <v-window v-model="activeWindow">
            <v-window-item
                v-for="item in stepItems" :key="item.key"
            >
              <div>
                <!--                <div>{{ item }}</div>-->
                <!--                <hr>-->
                <!--                <div>{{ input[item.key] }}</div>-->
                <v-container fluid class="py-0">
                  <generic-form v-if="item.form" :form="item"
                                :all-form-data="input"
                                :is-valid.sync="validSet[item.key]"
                                v-model="input[item.key]"/>
                </v-container>
              </div>
            </v-window-item>
            <v-window-item key="acceptAndSend">
              <v-container fluid class="py-0">
                <div>
                  {{ $t('company.formFilledText') }}
                </div>
                <div class="d-flex align-center justify-center mt-12">
                  <v-btn class="rounded-pill black--text"
                         x-large color="white"
                         :outlined="!$vuetify.theme.dark"
                         @click="submitFullForm">
                <span class="px-9">
                  {{ $t('userActions.submitForm') }}
                </span>
                  </v-btn>
                </div>
              </v-container>
            </v-window-item>
          </v-window>
          <div class="d-flex align-center justify-center flex-column pt-9">
            <v-expand-transition mode="out-in">
              <v-btn class="rounded-pill black--text"
                     :disabled="!skipValidation && !validSet[activeItemKey]"
                     v-if="activeWindow < stepItems.length"
                     x-large color="white"
                     :outlined="!$vuetify.theme.dark"
                     @click="continueClick">
                <span class="px-9">
                  {{ $t('userActions.continue') }}
                </span>
              </v-btn>
            </v-expand-transition>
            <div class="py-2"></div>
            <v-expand-transition mode="out-in">
              <v-btn text small
                     v-if="activeWindow > 1"
                     class="mt-3"
                     @click="activateItem(activeWindow - 1)">
                {{ $t('userActions.back') }}
              </v-btn>
            </v-expand-transition>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import RegistrationStepProgressItemView from "@/components/common/widgets/RegistrationStepProgressItemView";
import {rulesMixin} from "@/mixins/common";
import full_reg_form from "@/forms/complete/full_reg_form";
import {initModelSetByFormList, initValidSetByFormList} from "@/forms/formUtils";
import {CONSTS} from "@/utils/consts";
import {prepareFormData} from "@/utils/forms";
import endpoints from "@/utils/web/endpoints";

const GenericForm = () => import("@/components/common/forms/GenericForm");
// const beforeUnloadListener = (event) => {
//   event.preventDefault();
//   return event.returnValue = "Are you sure you want to exit?";
// };
export default {
  name: "FullRegistrationPage",
  components: {GenericForm, RegistrationStepProgressItemView},
  mixins: [rulesMixin],
  props: {
    protectUnload: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    userLocalFormKey: function () {
      return `${CONSTS.companyInfoLSKey}_${(this.$store.getters.getAuthData?.user_id || 'unset')}`
    },
    regProgressValue: function () {
      return this.activeWindow / this.stepItems.length * 100
    },
    stepItems: function () {
      return full_reg_form(this)
    },
    activeItemKey: function () {
      return this.stepItems[this.activeWindow].key
    },
    stepItemKeys: function () {
      return this.stepItems.map(item => item.key)
    },
  },
  data() {
    return {
      activeWindow: 1,
      headingHeight: "150px",
      input: {},
      validSet: {},
      skipValidation: false,
    }
  },
  methods: {
    resetForm() {
      localStorage.removeItem(this.userLocalFormKey)
      location.reload()
      // this.input = initModelSetByFormList(this.stepItems)
      // this.validSet = initValidSetByFormList(this.stepItems)
      // this.activeWindow = 1
    },
    beforeUnloadListener(event) {
      event.preventDefault()
      return event.returnValue = this.$t('registration.sureLeaveQuestion')
    },
    activateItem(index) {
      if (index >= 0 && index < this.stepItemKeys.length + 1)
        this.activeWindow = index
    },
    saveForm() {
      localStorage.setItem(this.userLocalFormKey, JSON.stringify({
        lastWindow: this.activeWindow + 1,
        data: this.input
      }))
    },
    loadForm() {
      try {
        let saved = localStorage.getItem(this.userLocalFormKey)
        if (!saved) return undefined
        return JSON.parse(saved)
      } catch (_) {
        return undefined
      }
    },
    continueClick() {
      // this.$store.commit('setRegistrationData', this.input)
      this.saveForm()
      this.activateItem(this.activeWindow + 1)
    },
    prepareFullForm() {
      let res = {}
      for (let i = 0; i < this.stepItems.length; i++) {
        let formItem = this.stepItems[i]
        if (formItem.form) {
          let prepared = prepareFormData(formItem.form, this.input[formItem.key])
          let _keys = Object.keys(prepared)
          res[formItem.key] = (_keys.length === 1 && _keys[0] === formItem.key) ?
              prepared[formItem.key] : prepared
        }
      }
      return res
    },
    submitFullForm() {
      let data = this.prepareFullForm()
      // console.log(data)
      this.$http.post(
          endpoints.COMPANIES().SIGNUP_FULL,
          data,
          {
            // changeState: true,
            showErrorNotification: true,
            formValidationError: true,
            // genForm: this.signUpForm.form
          }
      ).then(res => {
        this.$router.replace({name: "company-regfull_docs"})
        console.log(res)
      })
    }
  },
  watch: {
    input: function (val) {
      if (!this.protectUnload) return
      if (val) {
        addEventListener("beforeunload", this.beforeUnloadListener, {capture: true});
      } else {
        removeEventListener("beforeunload", this.beforeUnloadListener, {capture: true});
      }
    }
  },
  created() {
    if (this.$route.query.clear) {
      localStorage.removeItem(this.userLocalFormKey)
      this.$router.replace({path: this.$route.path, query: {}})
    }
    let fd = this.loadForm()
    if (fd) {
      const {lastWindow, data} = fd
      this.input = data
      this.activeWindow = lastWindow
      this.validSet = initValidSetByFormList(this.stepItems)
    } else {
      this.input = initModelSetByFormList(this.stepItems)
      this.validSet = initValidSetByFormList(this.stepItems)
    }

  },
  beforeDestroy() {

  },
}
</script>

<style scoped>

</style>