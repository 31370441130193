import Vue from "vue";
import {loadEnvVar} from "@/envLoader";
import {browserName, isIos, supportsAutoPwaInstall, supportsManualPwaInstall} from "@/utils/detectDevice";

const tabTitleTemplate = loadEnvVar("DOCUMENT_TITLE_TEMPLATE")

const bus = new Vue({
  computed: {
    pageTabTitle: function () {
      return tabTitleTemplate ? tabTitleTemplate.replace("%s", this.pageTitle) : this.pageTitle
    }
  },
  data() {
    return {
      pageTitle: '',
      appUsed: false,
      deferredPrompt: undefined,
      activeCaptchaComponent: undefined
    }
  },
  methods: {
    isDeviceSupportPwaInstall() {
      console.log("SUPPORT", browserName)
      console.log("IOS", isIos())
      return supportsAutoPwaInstall() || supportsManualPwaInstall()
      // return this.deferredPrompt.
    },
    setPageTitle(title) {
      this.pageTitle = title
    },
    acceptTitle() {
      document.title = this.pageTabTitle
    },
    initPwa() {
      window.addEventListener('beforeinstallprompt', (e) => {
        this.deferredPrompt = e;
        console.log("DP", e)
        console.log("DP", e.platforms)
        // console.log(deferredPrompt.prompt())
      });
    },
    async installPwa() {
      // alert(this.deferredPrompt)
      // if (!this.deferredPrompt && supportsAutoPwaInstall()) return 'accepted'
      if (!this.deferredPrompt) return 'show_help'
      let p = await this.deferredPrompt.prompt()
      console.log("PROMPT", p)
      // dismissed
      // accepted
      return p?.outcome
    }
  },
  watch: {
    pageTitle: function (val) {
      this.acceptTitle(val)
    }
  },
  created() {
    this.initPwa()
    this.appUsed = window.matchMedia('(display-mode: standalone)').matches
    this.$on('set-page-subtitle', this.setPageTitle)
  }
})

// eslint-disable-next-line no-unused-vars
export function install(Vue, options = {}) {
  Object.defineProperty(Vue.prototype, "$bus", {
    get: function $bus() {
      return bus
    }
  });
}

export default {install}

