<template>
  <div style="background-color: #1E1E1E" class="pa-3 d-flex align-center">
    <app-logo size="24"/>
    <div class="px-2">{{ $t('pwa.installMessage') }}</div>
    <v-spacer></v-spacer>
    <v-btn color="primary" small @click="installClick">{{ $t('userActions.install') }}
      <v-icon right>ri-install-line</v-icon>
    </v-btn>
    <div style="width: 8px"></div>
    <v-btn color="darkgrey" small icon @click="closeClick">
      <v-icon>ri-close-line</v-icon>
    </v-btn>
    <v-dialog max-width="400" v-model="helpDialog" light>
      <v-card>
        <v-card-title>
          {{ $t('pwa.instructionTitle') }}
        </v-card-title>
        <v-card-text>
          <div>
            <div class="caption pb-2">iOS/Safari</div>
            <div>
              <v-avatar size="24" color="primary" class="mr-1">
                <v-img :src="getPublicImg('ui_pic/share-ios-svgrepo-com.svg')" max-width="16" max-height="16"></v-img>
              </v-avatar>
              {{ $t('pwa.iosInstruction') }}
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn block color="primary" @click="helpDialog = false">
            {{ $t('common.ok') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog max-width="400" v-model="comingSoonDialog" light>
      <v-card>
        <v-card-text class="pt-3">
          {{ $t('pwa.comingSoon') }}
        </v-card-text>
        <v-card-actions>
          <v-btn block color="primary" @click="comingSoonDialog = false">
            {{ $t('common.ok') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AppLogo from "@/components/common/widgets/AppLogo";
import {isIos} from "@/utils/detectDevice";
import {publicAccessMixin} from "@/mixins/common";

export default {
  name: "PwaInstallView",
  components: {AppLogo},
  mixins: [
    publicAccessMixin
  ],
  data() {
    return {
      helpDialog: false,
      comingSoonDialog: false,
    }
  },
  methods: {
    installClick() {
      if (isIos()) {
        this.showIosInstruction()
      } else {
        this.runInstall()
      }
    },
    runInstall() {
      this.$bus.installPwa().then(res => {
        if (res === 'accepted') {
          this.$store.commit('setShowInstallPwaTip', false)
        } else if (res === 'show_help') {
          this.showComingSoonDialog()
        }
        // console.log("INSTALL RES", res)
      })
    },
    showComingSoonDialog() {
      this.comingSoonDialog = true
    },
    showIosInstruction() {
      this.helpDialog = true
    },
    closeClick() {
      this.$store.commit('setShowInstallPwaTip', false)
    }
  }
}
</script>

<style scoped>

</style>