/**
 *
 * @param {File}file
 * @param {boolean}cropBase64Heading
 * @returns {Promise<string>}
 */
export async function readFileAsBase64Async(file, cropBase64Heading = false) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      let reader = new FileReader()
      reader.onload = function () {
        resolve(cropBase64Heading ? reader.result.split(';base64,')[1] : reader.result)
      }
      reader.onerror = reject
      reader.readAsDataURL(file)
    }, 1)
  })
}