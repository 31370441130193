export function getSignInRedirectRouter(userData = undefined) {
  if (!userData) return undefined
  switch (userData.user_cls) {
    case "staffuser":
      return {name: "dashboard"}
    case "company":
      if (userData.extra?.full_reg_doc_generated) {
        if (userData.extra?.full_reg_doc_signed) {
          return {name: "home"}
        }
        return {name: "company-regfull_docs"}
      }
      return {name: "company-regfull"}
    case "person":
      if (userData.extra?.full_reg_filled) {
        if (userData.extra?.is_sumsub_approved) {
          return {name: "home"}
        }
        return {name: "person-regfull_sumsub"}
      }
      return {name: "person-regfull"}

  }
  return undefined
}