<template>
  <v-list-item @click.prevent.stop="$emit('click', value)">
    <v-list-item-avatar>
      <v-avatar style="text-transform: uppercase;" color="grey darken-3">
        {{ value.initials }}
      </v-avatar>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title style="font-weight: 400">{{ value.display_name }}</v-list-item-title>
      <v-list-item-subtitle style="font-size: 0.8rem">
        {{value.display_account}} / {{ value.phone }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-btn icon @click.stop="onRemoveReceiver">
        <v-icon>ri-delete-bin-line</v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
  name: "ReceiverItemView",
  props: {
    value: {
      type: Object
    }
  },
  // computed: {
  //   fullName: function () {
  //     return `${this.value.first_name} ${this.value.last_name}`
  //   },
  //   avatarText: function () {
  //     return this.value.first_name[0] + this.value.last_name[0]
  //   }
  // },
  methods: {
    onRemoveReceiver() {
      this.$emit('remove', this.value)
    }
  }
}
</script>

<style scoped>

</style>