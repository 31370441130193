const notifications = Object.freeze({
  /**
   * Search attr by key
   * @param attrs: Array
   * @param key: String
   * @private
   */
  _getAttrName(attrs, key) {
    if (!attrs) return key
    for (let i = 0; i < attrs.length; i++) {
      let group = attrs[i].form;
      for (let j = 0; j < group.length; j++) {
        let a = group[j]
        if (a.key === key) {
          return a.label || group.label || key
        }
      }
    }
    return key
  },
  // permissionErrorNotification(vm, e, reqUrl, reqData = null) {
  //   vm.$notify({
  //     group: 'errors',
  //     title: vm.$t(`errors.codes.403.title`),
  //     duration: 5000,
  //   });
  // },
  formValidationErrorNotification(vm, e, genFormData) {
    let data = e.response.data;
    if (!data['_validation_']) {
      vm.$notify({
        group: 'errors',
        title: vm.$t(`errors.codes.400.title`),
        duration: 10000,
        data: {
          details: data?.details || data
        },
      });
      return
    }
    delete data['_validation_'];
    let commonDetails = data.details
    if (data.error_data) {
      data = data.error_data
    }
    let keys = Object.keys(data);
    let errors = keys.map(key => ({
      key: key,
      name: this._getAttrName(genFormData, key),
      messages: data[key]
    }));

    vm.$notify({
      group: 'errors',
      title: vm.$t(`errors.codes.400.title`),
      duration: 10000,
      data: {
        validation: errors,
        details: commonDetails
      },
    });
  },

  authError(vm, e) {
    let data = e.response.data;
    let commonDetails = data.details || data.detail
    if (data.error_data) {
      data = data.error_data
    }

    vm.$notify({
      group: 'errors',
      title: vm.$t(`errors.codes.401.title`),
      duration: 5000,
      data: {
        details: commonDetails
      },
    });
  },
  notifyPaymentApproveError(vm, payment, message) {
    vm.$notify({
      group: 'errors',
      title: vm.$t(`client.payment_status.error`),
      duration: 5000,
      data: {
        details: `${payment.amount} ${payment.currency}: ${message}`
      },
    });
  },
  notifyPaymentApproveSuccess(vm, payment) {
    vm.$notify({
      group: 'success',
      title: vm.$t(`client.payment_status.success`),
      duration: 5000,
      data: {
        details: `${payment.amount} ${payment.currency} - success!`
      },
    });
  },
  notifyPaymentProcessDone(vm) {
    vm.$notify({
      group: 'success',
      title: "Parsing complete",
      duration: 5000,
      // data: {
      //   details: ""
      // },
    });
  },
  permissionError(vm, e) {
    let data = e.response.data;
    let commonDetails = data.details
    if (data.error_data) {
      data = data.error_data
    }

    vm.$notify({
      group: 'errors',
      title: vm.$t(`errors.permissionError`),
      duration: 4000,
      data: {
        details: commonDetails
      },
    });
  },
  commonError(vm, e) {
    let data = e.response.data;
    let commonDetails = data.details
    if (data.error_data) {
      data = data.error_data
    }

    vm.$notify({
      group: 'errors',
      title: vm.$t(`errors.commonTitle`),
      duration: 4000,
      data: {
        details: commonDetails
      },
    });
  },
  // errorNotification(vm, e, reqUrl, reqData = null) {
  //   let td = {};
  //   let resp = e.response;
  //   if (resp) {
  //     let status = resp.status;
  //     if (status) {
  //       if (status >= 500)
  //         td = {
  //           title: vm.$t(`errors.codes.500.title`),
  //           data: {
  //             help: vm.$t(`errors.codes.500.help`)
  //           },
  //         };
  //       else {
  //         status = String(status);
  //         let detail = '';
  //         if (resp.data && resp.data.detail) {
  //           detail = resp.data.detail;
  //         }
  //         td = {
  //           title: vm.$t(`errors.codes.${status}.title`),
  //           data: {
  //             help: detail || vm.$t(`errors.codes.${status}.help`)
  //           },
  //         }
  //       }
  //     }
  //   } else {
  //     td = {
  //       title: vm.$t('errors.network.title'),
  //       text: vm.$t('errors.network.help'),
  //     }
  //   }
  //   vm.$notify({
  //     group: 'errors',
  //     duration: 3000,
  //     ...td,
  //   });
  // }
})

// eslint-disable-next-line no-unused-vars
export function install(Vue, options = {}) {
  Object.defineProperty(Vue.prototype, "$notifications", {
    get: function $notifications() {
      return notifications
    }
  });
}

export default {install}