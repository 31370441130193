<template>
  <v-btn :height="size" x-large
         @click="$emit('click', $event)"
         max-width="fit-content"
         :class="{'px-12': !isIconButton}"
         class="app-button"
         :disabled="disabled"
         :color="color"
         :style="buttonStyle"
  >
    <div v-if="icon" :class="{'pr-1': !isIconButton}">
      <v-fade-transition mode="out-in">
        <v-progress-circular
            size="18"
            width="2"
            indeterminate
            v-if="loading"
            color="black">

        </v-progress-circular>
        <v-icon :left="!isIconButton" size="22" v-else>
          {{ icon }}
        </v-icon>
      </v-fade-transition>
    </div>
    <slot v-if="!isIconButton">
      <span style="font-weight: 600">{{ text }}</span>
    </slot>
  </v-btn>
</template>

<script>
export default {
  name: "AppButton",
  props: {
    size: {
      type: Number,
      default: 60
    },
    text: {
      type: String,
      default: undefined
    },
    icon: {
      type: String,
      default: undefined
    },
    iconBtn: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: "primary"
    },
    disabledColor: {
      type: String,
      default: "rgba(47,47,47,0.7)"
    },
    mobileIconOnly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isIconButton: function () {
      return this.iconBtn || (this.mobileIconOnly && this.$vuetify.breakpoint.mobile)
    },
    buttonStyle: function () {
      return {
        // background: this.disabled ? this.disabledColor : undefined,
        "font-weight": 600,
        "width": this.isIconButton ? `${this.size}px` : undefined,
        "min-width": `${this.size}px !important`,
        "border-radius": this.isIconButton ? "50%" : "100px"
      }
    }
  }
}
</script>

<style scoped>

</style>