<template>
  <v-card-text class="d-flex flex-column align-center"
               :class="`${altColor ? '' : statusData.color} ${dense ? 'py-0' : 'py-2'}`">
    <div class="d-flex align-center justify-end fw" :class="reverse ? 'flex-row-reverse' : ''">
      <div>
        <div class="caption pt-2"
             v-if="!dense"
             style="line-height: 8px"
             :class="`${statusData.color}--text text--lighten-3`">Status
        </div>
        <b class="white--text text-no-wrap">{{ statusData.title }}</b>
        <div v-if="showHint" class="white--text">
          {{ statusData.hint }}
        </div>
      </div>
      <v-spacer style="min-width: 4px" :style="{'max-width': reverse ? '12px' : undefined}"/>
      <v-avatar rounded :color="altColor ? 'transparent' : `${statusData.color} darken-3`" size="32px">
        <v-icon :color="altColor ? `${statusData.color}` : 'white'">{{ statusData.icon }}</v-icon>
      </v-avatar>
    </div>
    <div v-if="showUnblockAction && !dense" class="mt-1 fw">
      <v-btn block small
             :color="`${statusData.color} darken-3`" dark
             @click.prevent="onRequestVerificationClick"
      >
        {{ $t('userActions.verifyAccount') }}
      </v-btn>
    </div>
  </v-card-text>
</template>

<script>
export default {
  name: "UserVerificationStatusView",
  props: {
    reverse: {
      type: Boolean,
      default: false
    },
    showHint: {
      type: Boolean,
      default: false
    },
    showUnblockButton: {
      type: Boolean,
      default: false
    },
    altColor: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    verificationStatus: {
      type: String,
      default: undefined
    },
    isCompany: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    showUnblockAction: function () {
      return this.verificationStatus === 'blocked' && this.isCompany && this.showUnblockButton
    },
    statusData: function () {
      return this.statusDataMap[this.verificationStatus] || {
        color: 'blue',
        icon: 'ri-question-line',
        title: this.$t('verificationStatus.unset'),
        hint: this.$t('verificationStatusHint.unset'),
      }
    },
    statusDataMap: function () {
      return {
        verified: {
          color: 'green',
          icon: 'ri-check-line',
          title: this.$t('verificationStatus.verified'),
          hint: this.$t('verificationStatusHint.verified')
        },
        blocked: {
          color: 'orange',
          icon: 'ri-compass-4-line',
          title: this.$t('verificationStatus.blocked'),
          hint: this.$t('verificationStatusHint.blocked'),
        },
        full_blocked: {
          color: 'red',
          icon: 'ri-close-line',
          title: this.$t('verificationStatus.full_blocked'),
          hint: this.$t('verificationStatusHint.full_blocked'),
        },
      }
    }
  },
  methods: {
    onRequestVerificationClick() {
      this.$router.push({name: 'company-regfull_docs'})
    }
  }
}
</script>

<style scoped>

</style>