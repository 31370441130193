<template>
  <div class="fw fh">
    <div style="position: absolute" class="fw fh">
      <div class="bubbles fw fh">
        <div class="bubble" v-for="i in bubbleCount" :key="`b_bg__${i}`"></div>
      </div>
    </div>
    <div class="d-flex fh fw align-center justify-center">
      <slot></slot>
    </div>
  </div>
</template>

<script>

export default {
  name: "CenterAnimatedLayout",
  props: {
    bubbleCount: {
      type: Number,
      default: 30
    }
  }
}
</script>

<style scoped lang="scss">
$bubble-count: 30;
$sway-type: sway-left-to-right, sway-right-to-left;
$bubble-border-radius: [50%, 4px];

@keyframes float-up {
  to {
    transform: translateY(-175vh);
  }
}

@keyframes sway-left-to-right {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(100%);
  }
}

@keyframes sway-right-to-left {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(-100%);
  }
}

@function random_range($min, $max) {
  $rand: random();
  $random_range: $min + floor($rand * (($max - $min) + 1));
  @return $random_range;
}

@function sample($list) {
  @return nth($list, random(length($list)));
}

.bubbles {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.bubble {
  position: absolute;
  left: var(--bubble-left-offset);
  bottom: -75%;
  display: block;
  width: var(--bubble-radius);
  height: var(--bubble-radius);
  //border-radius: 4px;
  animation: float-up var(--bubble-float-duration) var(--bubble-float-delay) ease-in infinite;

  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(143, 218, 215, 0.1);
    border-radius: inherit;
    //animation: sway-left-to-right 2s 0s ease-in-out alternate infinite;
  }

  &:nth-child(even)::before {
    animation: sway-left-to-right var(--bubble-sway-duration) var(--bubble-sway-delay) ease-in-out alternate infinite;
    //--bubble-sway-type: sway-left-to-right;
  }

  &:nth-child(odd)::before {
    animation: sway-right-to-left var(--bubble-sway-duration) var(--bubble-sway-delay) ease-in-out alternate infinite;
    //--bubble-sway-type: sway-right-to-left;
  }

  @for $i from 0 through $bubble-count {
    &:nth-child(#{$i}) {
      --bubble-left-offset: #{random_range(0vw, 100vw)};
      --bubble-radius: #{random_range(1vw, 8vw)};
      --bubble-float-duration: #{random_range(6s, 12s)};
      --bubble-sway-duration: #{random_range(4s, 6s)};
      --bubble-float-delay: #{random_range(0s, 4s)};
      --bubble-sway-delay: #{random_range(0s, 4s)};
      border-radius: #{sample($bubble-border-radius)};
    }
  }
}
</style>