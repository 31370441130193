<template>
  <div>
    <generic-form :form="form"
                  v-model="receiverData"
    />
    <!--                  :is-valid.sync="isValid"-->
    <app-button icon="ri-user-received-line"
                v-if="isEditMode"
                :disabled="!this.isValid"
                @click.prevent="submitForm">{{ $t('client.receivers.save_action') }}
    </app-button>
    <app-button icon="ri-user-add-line"
                v-else
                :disabled="!this.isValid"
                @click.prevent="submitForm">{{ $t('client.receivers.add_action') }}
    </app-button>
  </div>
</template>

<script>
import GenericForm from "@/components/common/forms/GenericForm";
import receiver_detail from "@/forms/complete/receiver_detail";
import AppButton from "@/components/common/widgets/AppButton";
import {prepareFormData} from "@/utils/forms";
import endpoints from "@/utils/web/endpoints";

export default {
  name: "ReceiverDetailForm",
  components: {AppButton, GenericForm},
  props: {
    receiverId: {
      type: String,
      default: undefined
    },
    isEditMode: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    form: function () {
      return receiver_detail(
          this,
          this.receiverData?.beneficiary_type === 'corporate',
          this.receiverData?.credentials_type === 'an_sc',
          this.isEditMode
      )
    }
  },
  data() {
    return {
      ready: false,
      receiverData: {
        beneficiary_type: 'individual',
        credentials_type: 'iban_bic',
      },
      isValid: true
    }
  },
  methods: {
    submitForm() {
      // if (!this.isValid) return
      this.$emit('submit', prepareFormData(this.form.form, this.receiverData))
    },
    getReceiverInfo() {
      this.$http.get(
          endpoints.TRANSFER().RECEIVERS().GET(this.receiverId)
      ).then(resp => {
        this.receiverData = resp
        // console.log(resp)
        this.ready = true
      })
    }
  },
  created() {
    if (this.isEditMode) {
      this.getReceiverInfo()
    } else {
      this.ready = true
    }
  }
}
</script>

<style scoped>

</style>