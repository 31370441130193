<template>
  <div class="mt-6">
    <transaction-history-item-view
        class="mb-6 animated delayed-appear fadeIn"
        v-for="(item, ind) in items"
        :key="`item__${ind}`"
        :item="item"
    />
    <!--    <list-pagination-control-component v-model="currentPageNumber$"/>-->
    <div class="d-flex align-center justify-center">
      <v-btn @click="loadMore" text
             :loading="loadingStatus$.loading"
             v-if="loadMoreVisible">
        {{ $t('userActions.loadMore') }}
        <v-icon right>ri-more-line</v-icon>
      </v-btn>
    </div>

  </div>
</template>

<script>
import {listViewMixin} from "@/mixins/common";
import TransactionHistoryItemView from "@/components/client/widgets/history/TransactionHistoryItemView.vue";
import endpoints from "@/utils/web/endpoints";

export default {
  name: "TransactionHistoryListView",
  components: {TransactionHistoryItemView},
  mixins: [listViewMixin],
  data() {
    return {
      items: [],
    }
  },
  computed: {
    loadMoreVisible: function () {
      return this.items.length < this.pagination$.allCount
    }
  },
  methods: {
    loadMore() {
      this.pagination$.offset += this.pagination$.limit
      // this.getData()
    },
    getDataUrl(limit, offset, order) {
      return endpoints.TRANSFER().HISTORY(
          offset, limit, order
      )
    },
    exportData(exportParams) {
      this.$http.post(
          this.getDataUrl(
              this.pagination$.limit,
              this.pagination$.offset,
              this.orderField || null
          ),
          {
            export: exportParams?.type || 'csv'
          },
          {
            // changeState: true,
            // updatePagination: true,
            rootProgress: true,
            downloadFile: true
          }
      ).then(() => {
        // this.items.push(...resp.results)
      })
    },
    getData() {
      this.$http.get(
          this.getDataUrl(
              this.pagination$.limit,
              this.pagination$.offset,
              this.orderField || null
          ),
          {
            changeState: true,
            updatePagination: true,
            rootProgress: true,
          }
      ).then(resp => {
        this.items.push(...resp.results)
      })
    }
  },
  // created() {
  //   this.getData()
  // }
}
</script>

<style scoped>

</style>