<template>
  <v-dialog
      light max-width="520" v-model="longHintVisible">
    <template v-slot:activator="{on, attrs}">
      <v-btn v-on="on" v-bind="attrs"
             :class="btnClass"
             icon style="opacity: 48%">
        <v-icon>ri-question-line</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="longHintVisible = false">
          <v-icon>ri-close-line</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text v-html="hintContent">

      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "FormFieldLongHint",
  props: {
    dark: {
      type: Boolean,
      default: undefined
    },
    btnClass: {
      default: undefined
    },
    light: {
      type: Boolean,
      default: true
    },
    hintContent: {}
  },
  data() {
    return {
      longHintVisible: false
    }
  }
}
</script>

<style scoped>

</style>