<template>
  <v-app>
    <v-navigation-drawer
        v-if="showDrawer"
        color="black"
        width="300"
        app v-model="drawer">
      <div v-bind="bindDrawerContentHolder">
        <div>
          <app-logo
              with-text
              class="pt-5 px-5 pb-0"
              size="44"/>
          <slot name="logo-down" class="px-5"></slot>
        </div>

        <div class="d-block" :style="{height: '72px'}">

        </div>

        <v-list class="pa-0">
          <div v-for="item in drawerMenu"
               :key="item.key">
            <v-divider/>
            <v-list-item
                color="primary"
                :to="item.to" class="rounded-0" link>
              <v-list-item-avatar class="mr-2 ml-0">
                <v-icon size="24">{{ item.icon }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content class="pl-1">
                <v-list-item-title class="font-weight-regular">
                  {{ item.label }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider/>
          </div>
        </v-list>
      </div>
      <template v-slot:append>
        <div class="d-flex justify-center py-6">
          <a href="mailto:support@velurepay.com" class="white--text">
            support@velurepay.com
          </a>
        </div>
      </template>
    </v-navigation-drawer>

    <app-bar :drawer.sync="drawer"
             :show-logo="showAppBarLogo"
             :show-drawer-control="showDrawer"/>
    <v-main>
      <v-container fluid class="fh">
        <v-scroll-x-transition mode="out-in">
          <!--          <router-view></router-view>-->
          <slot name="main"></slot>
        </v-scroll-x-transition>
      </v-container>
    </v-main>


  </v-app>
</template>

<script>
import AppBar from "@/components/common/widgets/AppBar";
import AppLogo from "@/components/common/widgets/AppLogo";
import {mainLayoutMixin} from "@/mixins/common";

export default {
  name: "MainLayoutTemplate",
  components: {AppLogo, AppBar},
  mixins: [
    mainLayoutMixin
  ],
  props: {
    drawerMenu: {
      type: Array,
      default: () => []
    },
    showDrawer: {
      type: Boolean,
      default: true
    },
    showAppBarLogo: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      drawer: true
    }
  },
}
</script>

<style scoped>
</style>