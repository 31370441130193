<template>
  <v-input v-bind="$bindField"
           :label="''"
           v-model="input" class="v-input__block">
    <div v-if="many$">
      <div v-for="(item, index) in input" :key="`file__${index}_${item.id}`"
           class="mb-3"
      >
        <file-holder
            :file="item" :accept="accept$"
            :type-tags="typeTags$"
            :label="$bindField.label"
            @input="onFileInput(index, $event)"
            @clear="onFileClear(index, $event)"
        />
      </div>
    </div>
    <div v-if="showAdd">
      <file-holder :file="many$ ? undefined : input"
                   :many="many$"
                   :type-tags="typeTags$"
                   @input="onFileInput(-1, $event)"
                   @clear="onFileClear(-1, $event)"
                   :accept="accept$" :label="$bindField.label"/>
    </div>

  </v-input>
</template>

<script>
import {flexSizeMixin, formInputMixin} from "@/mixins/common";
import FileHolder from "@/components/common/forms/helpers/FileHolder";

const MIME_TYPE_ANY = '*/*'

export default {
  name: "GenUploadFileField",
  components: {FileHolder},
  mixins: [formInputMixin, flexSizeMixin],
  props: {
    many: {
      type: Boolean,
      default: false
    },
    accept: {
      type: String,
      default: undefined
    },
    typeTags: {
      type: Array,
      default: undefined
    }
  },
  computed: {
    maxCount: function () {
      return this.field?.extra?.max || 0
    },
    showAdd: function () {
      return !this.many$ || !(this.many$ && this.input?.length >= this.maxCount)
    },
    accept$: function () {
      return this.accept || this.field?.extra?.accept || MIME_TYPE_ANY
    },
    many$: function () {
      return this.many || this.field?.extra?.many || false
    },
    typeTags$: function () {
      return this.typeTags || this.field?.extra?.typeTags
    }
  },
  data() {
    return {
      dragOverState: false,
      // inputFileData: undefined
    }
  },
  methods: {
    onFileInput(index, data) {
      if (this.many$) {
        if (index === -1) {
          if (!this.maxCount || this.input?.length < this.maxCount)
            this.input.push(data)
        } else {
          // this.input[index] = data
          this.$set(this.input, index, data)
        }
      } else {
        if (index === -1) {
          this.input = data
        } else {
          return
        }
      }
    },
    // eslint-disable-next-line no-unused-vars
    onFileClear(index, data) {
      if (this.many$) {
        if (index === -1) {
          return
        } else {
          this.input.splice(index, 1)
        }
      } else {
        if (index === -1) {
          this.input = undefined
        } else {
          return;
        }
      }
    }
  },
  created() {
    if (!this.value) {
      this.input = this.many$ ? [] : undefined
    }
    // if (this.many$)
  }
}
</script>

<style scoped>

</style>