import TransactionFeePrefWindow from "@/components/staff/widgets/prefs/TransactionFeePrefWindow.vue";
import BeneficiaryAccountPrefWindow from "@/components/staff/widgets/prefs/BeneficiaryAccountPrefWindow.vue";
import MetricsPrefWindow from "@/components/staff/widgets/prefs/MetricsPrefWindow.vue";

const CONSTS = Object.freeze({
  companyInfoLSKey: "velure_cilsk",
  companyRegDocTypes: [
    "cert_incor", "memorandum", "share_cert", "share_appointment", "other"
  ],
  companyStaffMenuCards: [
    {
      data_key: "directors_count",
      icon: "ri-group-line",
      color: "primary",
      // to: {name: 'staff-companies-list'}
    },
    {
      data_key: "ubo_count",
      icon: "ri-user-line",
      color: "blue",
      // to: {name: 'staff-persons-list'}
    },
    {
      data_key: "reg_doc_count",
      icon: "ri-file-shield-2-line",
      color: "warning",
      to: {name: 'staff-company-info-docs-page'}
    },
    {
      data_key: "uploaded_doc_count",
      icon: "ri-folder-line",
      color: "blue-grey",
      to: {name: 'staff-company-uploaded-files-page'}
    },
    {
      data_key: "transactions_count",
      // noCount: true,
      icon: "ri-share-circle-line",
      color: "primary",
      to: {name: 'staff-company-transactions-page'}
    },
    {
      data_key: "edit_count",
      // noCount: true,
      icon: "ri-history-line",
      color: "purple",
      to: {name: 'staff-company-edit-info-history-page'}
    },
    {
      data_key: "edit_info",
      noCount: true,
      icon: "ri-edit-line",
      color: "red",
      to: {name: 'staff-company-edit-info-page'}
    },
  ],
  dashCountsDataMapping: [
    {
      data_key: "companies",
      icon: "ri-group-line",
      color: "primary",
      to: {name: 'staff-companies-list'}
    },
    {
      data_key: "persons",
      icon: "ri-user-line",
      color: "blue",
      to: {name: 'staff-persons-list'}
    },
    {
      data_key: "staff_users",
      icon: "ri-user-settings-line",
      color: "warning",
      // to: {name: 'staff-users-list'}
    },
    {
      data_key: "transactions",
      icon: "ri-share-circle-line",
      color: "red",
      to: {name: 'staff-transactions-list'}
    },
  ],
  staffMenu: [
    {
      data_key: "dash",
      icon: "ri-dashboard-2-line",
      color: "yellow",
      to: {name: 'dashboard'}
    },
    {
      data_key: "app_preferences",
      icon: "ri-settings-6-line",
      color: "yellow",
      to: {name: 'app_preferences'}
    },
    {
      data_key: "income_payments",
      icon: "ri-coin-line",
      color: "yellow",
      to: {name: 'income_payments'}
    },
  ],
  staffPrefItems: [
    {
      key: "transaction_fee",
      icon: "ri-exchange-dollar-line",
      component: TransactionFeePrefWindow
    },
    {
      key: "beneficiary_account",
      icon: "ri-user-line",
      component: BeneficiaryAccountPrefWindow
    },
    {
      key: "metrics",
      icon: "ri-bar-chart-line",
      component: MetricsPrefWindow
    },
  ],
  clientMenu: [
    {
      data_key: "home",
      icon: "ri-home-line",
      color: "primary",
      to: {name: 'home'}
    },
    //group-line
    // {
    //   data_key: "send_money",
    //   icon: "ri-share-circle-line",
    //   color: "primary",
    //   to: {name: 'send_money'}
    // },
    {
      data_key: "receivers",
      icon: "ri-group-line",
      color: "primary",
      to: {name: 'receivers'}
    },
    {
      data_key: "history",
      icon: "ri-history-line",
      color: "primary",
      to: {name: 'history'}
    },
  ]
})

export {CONSTS}