import Vue from 'vue'
import moment from 'moment-timezone'
// const moment = require('moment-timezone')

moment.suppressDeprecationWarnings = true;
Vue.filter('prettyBytes', function (num) {
  num = Number(num);
  let exponent;
  let unit;
  let neg = num < 0;
  let units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  if (neg) num = -num;
  if (num < 1) return `${(neg ? '-' : '')}${num} B`;

  exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1);
  num = (num / Math.pow(1024, exponent)).toFixed(2) * 1;
  unit = units[exponent];

  return `${(neg ? '-' : '')}${num} ${unit}`;
});

Vue.filter('float2', function (value) {
  if (!value || typeof value !== 'number') return '0';
  return value.toFixed(2).toString()
});

Vue.filter('formatDate', function (value) {
  // console.log(value);
  if (value) {
    // return moment(value).format('DD.MM.YYYY')
    return moment(value).format('DD/MM/YYYY')
  } else return 'invalid_date'
});

Vue.filter('formatDateISO', function (value) {
  // console.log(value);
  if (value) {
    return moment(value).format('YYYY-MM-DD')
  } else return 'invalid_date'
});

Vue.filter('formatTime', function (value) {
  // console.log(value);
  if (value) {
    return moment(value).format('HH:mm:ss')
  } else return 'invalid_date'
});

// Vue.filter('formatDateTime', function (value) {
//   if (value) {
//     return moment(String(value)).local(true).format('DD.MM.YYYY HH:mm')
//   } else return 'invalid_date_time'
// });

Vue.filter('formatDateTime', function (value) {
  if (value) {
    return moment(String(value)).local(true).format('YYYY/MM/DD HH:mm')
  } else return 'invalid_date_time'
});

Vue.filter('formatTimeDate', function (value) {
  if (value) {
    return moment(String(value)).format('HH:mm DD.MM.YYYY')
  } else return 'invalid_date_time'
});

Vue.filter('formatFromNow', function (value) {
  if (value) {
    return moment(String(value)).fromNow()
  } else return 'invalid_date_time'
});