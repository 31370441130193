<template>
  <div class="fh d-flex align-center justify-center flex-column">
    <!--    <div style="max-height: 25vh" class="fw">-->
    <v-img
        :max-height="imgSize"
        :max-width="imgSize"
        :src="getPublicImg('ui_pic/data-not-found.svg')">

    </v-img>
    <div class="text-h3">
      {{ $t('notFound.pageNotFoundTitle') }}
    </div>
    <!--    </div>-->
  </div>
</template>

<script>
import {publicAccessMixin} from "@/mixins/common";

export default {
  name: "NotFound",
  mixins: [
    publicAccessMixin
  ],
  computed: {
    imgSize: function () {
      // return "min(200px, 25%)"
      return "200px"
    }
  }
}
</script>

<style scoped>

</style>