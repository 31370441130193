<template>
  <preference-window-wrapper v-bind="$props"
                             :loading-status="loadingStatus$"
                             @on-save="onSave">
    <generic-form
        v-if="loadingStatus$.loaded"
        :form="form"
        v-model="input"
    >

    </generic-form>
  </preference-window-wrapper>
</template>

<script>
import PreferenceWindowWrapper from "@/components/staff/widgets/prefs/PreferenceWindowWrapper.vue";
import {prefsWindowItemMixin} from "@/mixins/staffPrefsMixins";
import GenericForm from "@/components/common/forms/GenericForm.vue";
import endpoints from "@/utils/web/endpoints";
import {loadingMixin} from "@/mixins/common";
import {prepareFormData} from "@/utils/forms";
import metrics_prefs_form from "@/forms/complete/metrics_prefs_form";

export default {
  name: "MetricsPrefWindow",
  components: {GenericForm, PreferenceWindowWrapper},
  mixins: [
    prefsWindowItemMixin,
    loadingMixin
  ],
  computed: {
    form: function () {
      return {
        form: [{
          key: '_',
          form: metrics_prefs_form(this)
        }]
      }
    }
  },
  data() {
    return {
      input: {
        gtag_code: '',
        fbq_code: '',
      }
    }
  },
  methods: {
    onSave() {
      this.acceptChanges()
    },
    getData() {
      this.$http.get(
          endpoints.UI().METRICS().GET,
          {changeState: true}
      ).then(resp => {
        this.input = resp
        // console.log(resp)
      })
    },
    acceptChanges() {
      this.$http.post(
          endpoints.UI().METRICS().UPDATE,
          prepareFormData(this.form.form, this.input),
          {changeState: true}
      ).then(() => {
        this.$notify({
          group: 'success',
          text: this.$t('common.changes_saved_successfully')
        })
        // console.log('DONE')
        // console.log(resp)
      })
    }
    // onSave() {
    //   console.log('Saveeeee')
    // }
  },
  created() {
    this.getData()
  }
}
</script>

<style scoped>

</style>