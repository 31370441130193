<template>
  <!--  <page-wrapper ver-center hor-center>-->
  <center-animated-layout>
    <sign-in-view/>
  </center-animated-layout>
  <!--  </page-wrapper>-->
</template>

<script>
import SignInView from "@/components/common/forms/views/SignInView";
import CenterAnimatedLayout from "@/components/common/layouts/CenterAnimatedLayout";

export default {
  name: "SignInPage",
  components: {CenterAnimatedLayout, SignInView},
}
</script>

<style scoped>

</style>