class FieldType {
  static BOOL = 'bool'
  static TEXT = 'text'
  // static PASSWORD = 'password'
  static FLOAT = 'float'
  static PHONE = 'phone'
  static EMAIL = 'email'
  static TEXT_NUMBER = 'text_number'
  static LONGTEXT = 'longtext'
  static DATE = 'date'
  static DATETIME = 'datetime'
  static SELECT = 'select'
  static SELECT_RADIO = 'select_radio'
  static SELECT_MANY = 'select_many'
  static SELECT_MANY_CB = 'select_many_cb'
  static OBJECT_LIST = 'object_list'
  static ADDRESS = 'address'
  static COUNTRY_SELECT = 'country_select'
  static VIEW = 'view'
  static FILE = 'file'
  static OTP = 'otp'
  static CAPTCHA = 'captcha'
  static CURRENCY = 'currency'
  static HIDDEN = 'hidden'
  static FILE_BASE_64 = 'file_base_64'
}

export {
  FieldType
}