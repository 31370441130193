export const prefsWindowItemMixin = {
  props: {
    title: {
      type: String,
      default: undefined
    },
    showTitle: {
      type: Boolean,
      default: false
    },
    saveButtonText: {
      type: String,
      default: undefined
    },
    showButtons: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    onSave() {
      console.log('NOT IMPLEMENTED')
    }
  }
}